import styled from "styled-components";
import { SecondSectionDecor } from "../../../../assets/icons";
import { device } from "../../../../assets/themes/breakpoints";
import { SectionLayout } from "../../../../layouts/SectionLayout";
import image1 from "../../../../assets/images/career/s3-1.png";
import image2 from "../../../../assets/images/career/s3-2.png";
import image3 from "../../../../assets/images/career/s3-3.png";
import { useRef } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

export const ThirdSection = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const imageWrapperRef = useRef<HTMLImageElement>(null);

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
  };
  return (
    <SectionLayout
      topMargins={{
        desktop: 190,
        tablet: 100,
        mobile: 64,
      }}
    >
      <Wrapper ref={wrapperRef}>
        <BackgroundImage id={"leftSide-second-section-about-us_svg__b"} />
        <SliderWrapper>
          <Slider {...settings}>
            <ImageWrapper>
              <Image src={image1} alt="our company" />
            </ImageWrapper>
            <ImageWrapper>
              <Image src={image2} alt="our company" />
            </ImageWrapper>
            <ImageWrapper>
              <Image src={image3} alt="our company" />
            </ImageWrapper>
          </Slider>
        </SliderWrapper>
        <StaticImagesWrapper>
          <Image src={image1} alt="our company" />
          <Image src={image2} alt="our company" />
          <Image src={image3} alt="our company" />
        </StaticImagesWrapper>
      </Wrapper>
    </SectionLayout>
  );
};

const Section = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;

  @media ${device.laptop} {
    grid-template-columns: 1fr;
  }
`;

const ImageWrapper = styled.div`
  padding: 0 8px;
`;

const SliderWrapper = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  display: none;

  @media ${device.tablet} {
    display: block;
  }
`;

const StaticImagesWrapper = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;

  @media ${device.tablet} {
    display: none;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  @media ${device.tablet} {
    width: calc(100% + 80px);
    left: -40px;
  }

  @media ${device.mobileL} {
    width: calc(100% + 32px);
    left: -16px;
  }
`;

const Image = styled.img`
  width: 100%;
  @media ${device.tablet} {
    width: 320px;
    height: 320px;
    position: relative;
    border-radius: 30px;
  }
`;

const BackgroundImage = styled(SecondSectionDecor)`
  position: absolute;
  top: -66px;
  left: -157px;
`;
