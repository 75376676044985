import styled from "styled-components";
import { device } from "../../../../assets/themes/breakpoints";
import { SectionLayout } from "../../../../layouts/SectionLayout";
import { LeftSide, RightSide } from "./components";

export const SecondSection = () => {
  return (
    <SectionLayout topMargins={{ desktop: 144, tablet: 80, mobile: 67 }}>
      <Wrapper>
        <LeftSide />
        <RightSide />
      </Wrapper>
    </SectionLayout>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 24px;

  @media ${device.laptopL} {
    grid-template-columns: 1fr;
  }
`;
