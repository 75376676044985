import image1 from "../../../../../assets/images/industries/s2-1.png";
import image2 from "../../../../../assets/images/industries/s2-2.png";
import image3 from "../../../../../assets/images/industries/s2-3.png";
import image4 from "../../../../../assets/images/industries/s2-4.png";
import image5 from "../../../../../assets/images/industries/s2-5.png";
import image6 from "../../../../../assets/images/industries/s2-6.png";
import image7 from "../../../../../assets/images/industries/s2-7.png";

export const INFORMATION_BLOCK_DATA = [
  {
    title: "Education",
    text: "Learning and education are more engaging, immersive and productive with Virtual and Augmented Reality. Interactive 3D modeling allows students to connect with animated objects and systems, creating a realistic and enhanced learning environment.",
    imageSrc: image1,
  },

  {
    title: "Healthcare",
    text: "3D interactive content allows us to visually experience details within the human anatomy. Created content can be employed in pathology exploration and training, telemedicine and critical information sharing in real-time. For patients, it could be empowering to experience their treatment plan by visually stepping into their own specific medical procedure in virtual reality.",
    imageSrc: image2,
  },
  {
    title: "Culture and Art",
    text: "Access to vast collections of art and artifacts is available to everyone, thanks to the Cloyd ecosystem. Cloyd offers you masterpieces from around the world; even those that were destroyed over the course of history can now be revived with Cloyd's 3D technology.",
    imageSrc: image3,
  },
  {
    title: "Aviation and Defense",
    text: "With Cloyd the user can be on board an aircraft, ship or submarine with one click. Visually experience the architecture of a specific part, component or system on any platform. Military engineers, mechanics, medics and aviators can train to standard effectively, efficiently and intuitively.",
    imageSrc: image4,
  },
  {
    title: "Oil & Gas industry",
    text: "Digitalization of all processes - from extraction and processing of raw materials to transportation and storage. Simulation of dangerous situations. Remote training. Cloyd can be used for educational training simulations.",
    imageSrc: image5,
  },
  {
    title: "Industrial Machinery",
    text: "Explore 3D visualizations of manufacturing methods to include forging, stamping, bending, forming, and machining for individual metal parts. Increase production precision and visibility and avoid assembly errors through virtual model manipulation.",
    imageSrc: image6,
  },
  {
    title: "Automotive",
    text: "From automotive designers and engineers to dealers and customers, Cloyd’s Extended Realty capability enhances automotive production cycles, design and development, sales and marketing as well as the after-sales service process.",
    imageSrc: image7,
  },
];
