import * as React from "react";
import { SVGProps } from "react";
const SvgInstagramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.004 3.898A4.096 4.096 0 0 0 3.9 8a4.096 4.096 0 0 0 4.103 4.102A4.096 4.096 0 0 0 12.106 8a4.096 4.096 0 0 0-4.102-4.102Zm0 6.769A2.672 2.672 0 0 1 5.337 8a2.67 2.67 0 0 1 2.667-2.667A2.67 2.67 0 0 1 10.67 8c0 1.47-1.2 2.667-2.667 2.667ZM13.23 3.73a.955.955 0 0 1-.956.957.957.957 0 1 1 .957-.957Zm2.717.971c-.06-1.282-.353-2.417-1.292-3.352C13.72.413 12.585.12 11.303.056 9.98-.019 6.022-.019 4.7.056 3.423.116 2.288.41 1.35 1.346.409 2.28.12 3.415.056 4.697c-.075 1.32-.075 5.28 0 6.6.06 1.283.354 2.418 1.293 3.353.939.936 2.07 1.229 3.352 1.293 1.321.075 5.28.075 6.602 0 1.281-.06 2.416-.354 3.352-1.293.935-.935 1.228-2.07 1.292-3.352.075-1.321.075-5.277 0-6.598Zm-1.706 8.015a2.7 2.7 0 0 1-1.521 1.521c-1.053.418-3.553.322-4.716.322-1.164 0-3.667.092-4.717-.322a2.7 2.7 0 0 1-1.52-1.52C1.348 11.662 1.444 9.163 1.444 8c0-1.164-.093-3.667.321-4.716a2.7 2.7 0 0 1 1.521-1.521c1.054-.418 3.553-.322 4.717-.322 1.163 0 3.666-.092 4.716.322a2.7 2.7 0 0 1 1.52 1.52c.418 1.054.322 3.553.322 4.717 0 1.164.097 3.667-.321 4.716Z"
      fill="#fff"
    />
  </svg>
);
export default SvgInstagramIcon;
