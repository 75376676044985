import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ArrowLink } from "../../../../../assets/icons";
import { device } from "../../../../../assets/themes/breakpoints";
import { useMouseDownOutside } from "../../../../../utils/useMouseDownOutside";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { LinkWithArrow } from "../../../../../ui";

interface QuestionSectionProps {
  title: string;
  dataBlock?: {
    id: number;
    title: string;
    list: string[];
  }[];
  text?: string;
}

export const CareerSection = ({
  title,
  dataBlock,
  text,
}: QuestionSectionProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [textHeight, setTextHeight] = useState(0);
  const wrapperRef = useRef<HTMLButtonElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ScrollTrigger.refresh();
  }, [isOpen, textHeight]);

  useEffect(() => {
    const wrapper = wrapperRef.current;

    if (!wrapper) return;

    gsap.fromTo(
      wrapper,
      {
        opacity: 0,
        y: 30,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.3,
        scrollTrigger: {
          trigger: wrapper,
          start: "top 100%",
        },
      }
    );

    return () => {
      gsap.killTweensOf(wrapper);
    };
  }, []);

  const getTextHeight = () => {
    if (!textRef.current) return;
    setTextHeight(textRef.current.clientHeight);
    setIsOpen(false);
  };

  useEffect(() => {
    getTextHeight();
  }, [textRef]);

  const switchMenu = () => {
    setIsOpen((prev) => !prev);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useMouseDownOutside(wrapperRef, closeMenu);

  return (
    <Wrapper ref={wrapperRef} onClick={switchMenu} $isOpen={isOpen}>
      <TitleRow>
        <Title>{title}</Title>
        <OpenAnswerButton>
          <Arrow $isOpen={isOpen} />
        </OpenAnswerButton>
      </TitleRow>
      <InfoWrapper ref={textRef} $isOpen={isOpen} $textHeight={textHeight}>
        {dataBlock &&
          dataBlock.map((item) => (
            <DataBlock key={item.id}>
              <DataBlockTitle>{item.title}</DataBlockTitle>
              <List>
                {item.list.map((listItem) => (
                  <li key={listItem}>{listItem}</li>
                ))}
              </List>
            </DataBlock>
          ))}

        {text && <Text>{text}</Text>}

        <LinkWrapper>
          <LinkWithArrow text={"Apply"} linkType="dark" />
        </LinkWrapper>
      </InfoWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.button<{
  $isOpen: boolean;
}>`
  position: relative;
  border-radius: 20px;
  padding: ${({ $isOpen }) =>
    $isOpen ? "18px 30px 33px 29px" : "18px 30px 24px 29px"};
  background: rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-in-out;
  width: 100%;

  @media ${device.mobileL} {
    padding: ${({ $isOpen }) =>
      $isOpen ? "22px 20px 30px 20px" : "22px 20px 22px 20px"};
  }
`;

const DataBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  @media ${device.mobileL} {
    row-gap: 24px;
  }
`;

const DataBlockTitle = styled.h3`
  ${({ theme }) => theme.typography.title_4_bold};

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.title_4_bold_mobile};
  }
`;

const Text = styled.p`
  ${({ theme }) => theme.typography.paragraph_3_regular};

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.paragraph_3_regular_mobile};
  }
`;

const List = styled.ul`
  list-style: outside;
  margin-left: 30px;

  li {
    ${({ theme }) => theme.typography.paragraph_3_regular};

    @media ${device.mobileL} {
      ${({ theme }) => theme.typography.paragraph_3_regular_mobile};
    }
  }
`;
const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  text-align: left;
  margin-right: 15px;
  ${({ theme }) => theme.typography.title_3_bold};

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.title_3_bold_mobile};
  }
`;

const InfoWrapper = styled.div<{ $isOpen: boolean; $textHeight: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 40px;
  margin-top: ${({ $isOpen }) => ($isOpen ? "12px" : "0")};
  overflow: hidden;
  max-height: ${({ $isOpen, $textHeight }) =>
    $textHeight ? ($isOpen ? `${$textHeight}px` : 0) : "auto"};
  transition: all 0.3s ease-in-out;

  text-align: left;

  @media ${device.mobileL} {
    row-gap: 24px;
  }
`;

const OpenAnswerButton = styled.div`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  text-align: left;
`;

const Arrow = styled(ArrowLink)<{ $isOpen: boolean }>`
  transform: rotate(${({ $isOpen }) => ($isOpen ? "180deg" : "0deg")});
  transition: all 0.3s ease-in-out;
`;

const LinkWrapper = styled.div``;
