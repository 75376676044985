import styled from "styled-components";
import { SectionLayout } from "../../../../layouts/SectionLayout";
import { LeftSide, RightSide } from "./components";

export const SecondSection = () => {
  return (
    <SectionLayout
      topMargins={{
        desktop: 161,
        tablet: 80,
        mobile: 70,
      }}
    >
      <Section>
        <LeftSide />
        <RightSide />
      </Section>
    </SectionLayout>
  );
};

const Section = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  column-gap: 90px;
`;
