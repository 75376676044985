import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: Dm sans, sans-serif !important;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    background-color: #F3F4F6;
  }


  a {
    text-decoration: none;
  }

  button {
    border: none;
    background: none;
  }


  #main-section-model{
    --progress-bar-color: transparent;
  }

  #seven-section-model{
    --progress-bar-color: transparent;
  }

  textarea:focus, input:focus{
    outline: none;
  }


.slick-initialized .slick-slide {
  flex-shrink: 0;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  display: flex;
}

.form-control:focus {
  border: 1px solid #1D2D44;
}


`;
