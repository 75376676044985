import learning from "../../../../../assets/images/about/learning-icon.png";
import enthusiasm from "../../../../../assets/images/about/enthusiasm.png";
import performs from "../../../../../assets/images/manuals/performs-research-icon.png";
import development from "../../../../../assets/images/manuals/development-strategies-icon.png";
import brand from "../../../../../assets/images/manuals/brand-position-icon.png";
import copywriting from "../../../../../assets/images/manuals/copywriting-icon.png";

export const CARD_DATA = [
  {
    iconSrc: performs,
    title: "Performs research",
  },
  {
    iconSrc: development,
    title: "Development of marketing strategies",
  },
  {
    iconSrc: brand,
    title: "Brand positioning and corporate identity",
  },
  {
    iconSrc: learning,
    title: "Holds lectures and seminars",
  },
  {
    iconSrc: enthusiasm,
    title: "Creation of branding concepts",
  },
  {
    iconSrc: copywriting,
    title: "Copywriting",
  },
];
