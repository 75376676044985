import { createGlobalStyle } from "styled-components";

import DmSansWoff from "./dm-sans/dm-sans-v11-latin-regular.woff";
import DmSansWoff2 from "./dm-sans/dm-sans-v11-latin-regular.woff2";

import DmSansBoldWoff from "./dm-sans/dm-sans-v11-latin-700.woff";
import DmSansBoldWoff2 from "./dm-sans/dm-sans-v11-latin-700.woff2";

export default createGlobalStyle`
    @font-face {
        font-family: DM Sans;
        src: local('DM Sans'), local('DM Sans'),
        url(${DmSansWoff}) format('woff2'),
        url(${DmSansWoff2}) format('woff');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: DM Sans;
        src: local('DM Sans'), local('DM Sans'),
        url(${DmSansBoldWoff}) format('woff2'),
        url(${DmSansBoldWoff2}) format('woff');
        font-weight: 700;
        font-style: normal;
    }
`;

export const fonts = ["DM Sans"];
