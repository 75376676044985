import { ReactElement, useEffect } from "react";

type Props = {
  children: ReactElement<any, any>;
  title: string;
};

const TitledRoute = ({ children, title }: Props) => {
  useEffect(() => {
    document.title = "Cloyd | " + title;
  });

  return children;
};

export default TitledRoute;
