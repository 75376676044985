import styled from "styled-components";
import { device } from "../../../../../assets/themes/breakpoints";
import { LinkWithArrow } from "../../../../../ui";

import gsap from "gsap";
import { useEffect, useRef } from "react";
import { RoutePaths } from "../../../../../interfaces/routesPaths";

export const RightSide = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);
  const linkWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      !wrapperRef.current ||
      !titleRef.current ||
      !textRef.current ||
      !linkWrapper.current
    )
      return;

    const wrapper = wrapperRef.current;
    const title = titleRef.current;
    const text = textRef.current;
    const link = linkWrapper.current;

    gsap.fromTo(
      title,
      {
        opacity: 0,
        y: 80,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: wrapper,
          start: "top center",
          end: "bottom top",
        },
      }
    );

    gsap.fromTo(
      text,
      {
        opacity: 0,
        y: 80,
      },
      {
        y: 0,
        opacity: 1,
        delay: 0.2,
        duration: 0.8,
        scrollTrigger: {
          trigger: wrapper,
          start: "top center",
          end: "bottom top",
        },
      }
    );

    gsap.fromTo(
      link,
      {
        opacity: 0,
        y: 80,
      },
      {
        opacity: 1,
        y: 0,
        delay: 0.4,
        duration: 0.6,
        scrollTrigger: {
          trigger: wrapper,
          start: "top center",
          end: "bottom top",
        },
      }
    );

    return () => {
      gsap.killTweensOf(wrapper);
      gsap.killTweensOf(title);
      gsap.killTweensOf(text);
      gsap.killTweensOf(link);
    };
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <Title ref={titleRef}>Forget old-fashioned paper manuals!</Title>
      <Text ref={textRef}>
        Cloyd is a digital instrument that helps customers take advantage of
        interactive 3D data, manage 3D content and easily create 3D content.
      </Text>
      <div ref={linkWrapper}>
        <LinkWithArrow
          to={RoutePaths.ABOUT_CLOYD.path}
          text="Learn More"
          linkType="transparent"
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 58px;
  line-height: 65px;
  letter-spacing: -0.04em;

  color: #1d2d44;

  @media ${device.mobileL} {
    margin-top: 15px;
    font-size: 32px;
    line-height: 38px;
  }
`;

const Text = styled.p`
  margin-top: 29px;
  margin-bottom: 43px;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #1d2d44;
  opacity: 0.7;

  @media ${device.laptopL} {
    max-width: 60%;
  }

  @media ${device.tablet} {
    max-width: 484px;
  }

  @media ${device.mobileL} {
    margin-top: 15px;
    margin-bottom: 18px;
    font-size: 16px;
    line-height: 23px;
  }
`;
