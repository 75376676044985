export const QUESTIONS = [
  {
    question: "Why shall I Use 3D instructions?",
    answer:
      "Because in 3D you can see all, that you couldn't identify in 2D. CLOYD app guides with voice, text and 3D animated images for better customer experience in assembly, installation, set-up, repair or maintenance. Moreover, you get up-to-date on instructions, always available, no need for searching paper manuals.",
  },
  {
    question: "Who are Cloyd users?",
    answer:
      "It can be absolutely any person and any company. Our partners deal with various industries: education, healthcare, culture and art, aviation and defense, oil and gas industry, automotive, industrial machinery. More about industries you can read here.",
  },
  {
    question: "Why Cloyd 3D instructions called interactive?",
    answer:
      "Because you can assemble and disassemble devices, zoom in and out, investigate in X-Ray mode, rotate images 360⁰ and even view them in VR/AR environment.",
  },
  {
    question: "Where can I find Cloyd instructions?",
    answer: "The Cloyd app can be downloaded from the ",
    link1: {
      text: "App Store",
      url: "https://apps.apple.com/ru/app/cloydapp/id1579857957",
    },
    text2: " or ",
    link2: {
      text: "Google Play",
      url: "https://play.google.com/store/apps/details?id=com.cloyd.cloydapp",
    },
    text3: " on mobile devices.",
  },
  {
    question: "Can I download Cloyd to my computer?",
    answer:
      "There is no option to download to a desktop computer. Only web Cloyd is available on a desktop computer.",
  },
  {
    question: "Can I Use Cloyd app for free?",
    answer:
      "Yes, the Cloyd app is free to users, both DIYers and professionals. Premium and business features are open to all users on",
    link1: {
      text: " a subscription basis",
      url: "https://cloydxr.com/pricing",
    },
    text2: ".",
  },
  {
    question: "How Much Storage Space Do I Need for Cloyd app?",
    answer:
      "The Cloyd app file size is now 145 MB for iOS mobile apps and 50 MB for Android mobile apps. And that's it! We use a cloud hub for storing and processing all big IXR files needed for Cloyd app. So, no worries, Cloyd app takes average space on a device.",
  },
  {
    question: "What are IXR files?",
    answer:
      "IXR format is an asset bundle analog, which includes different 3D models, text information, sounds and scripts. This format supports the creation of complex models and scenes, including various game mechanics and animations.",
  },
  {
    question: "Does Cloyd require a sign-in?",
    answer:
      "You can use Cloyd IXR Hub without any registration. IXR Hub contains manuals, without 3D interaction. If you want to get access to Cloyd 3D interactive manuals, then a sign in with mail or social media account is required. Once you are signed in you can store and maintain your personal 3D manuals database.",
  },
  {
    question: "How do I know if a product has Cloyd instructions?",
    answer:
      "Brands that have chosen Cloyd instructions usually inform customers by featuring Cloyd download information on their instruction manual. Many also put the Cloyd logo on their packaging and websites. Users can also search by brand or product name directly in Cloyd app.",
  },
  {
    question: "How many languages is Cloyd in?",
    answer: "For now the Cloyd app interface is available in English language.",
  },
  {
    question: "How can I get support assistance?",
    answer: "If you need help assistance please email: ",
    link1: {
      text: "support@cloydxr.com",
      url: "mailto:support@cloydxr.com",
    },
    text2: " to reach our service team.",
  },
  {
    question: "Is it possible to make my own instructions?",
    answer: "Yes, for this purpose we",
  },
  {
    question:
      "How much time does it take to create 3D interactive instruction?",
    answer:
      "It depends on the task complexity and collaboration efficacy between partner representatives and Cloyd developer's team. We intend to spend 2-3 weeks per one 3D interactive manual.",
  },
  {
    question: "How to become Cloyd distributor?",
    answer: "If you are interested in Cloyd app promotion, contact us: ",
    link1: {
      text: "support@cloydxr.com",
      url: "mailto:support@cloydxr.com",
    },
  },
  {
    question: "How can I give my feedback?",
    answer:
      "Customer experience is our greatest source of learning and improvement, we would really appreciate your feedback, contact us: ",
    link1: {
      text: "support@cloydxr.com",
      url: "mailto:support@cloydxr.com",
    },
  },
];
