import { iconTypes } from "../components/Card";

export const CARD_DATA = [
  {
    icon: "money" as iconTypes,
    title1: "Reduce cost",
    highlightText: "and",
    title2: "increase productivity",
    text: "3D manuals provide structured information that can be easily accessed and retained, creating engagement among users in a digital environment. By adopting this solution companies save money, used for printed product manuals, and contribute to environmental protection.",
  },
  {
    icon: "mobile" as iconTypes,
    title1: "Customize content",
    highlightText: "in real time",
    title2: "",
    text: "This technology allows companies to create a visual 3D mockup or animation of the product process in terms of product features such as assembly, disassembly of components, maintenance, tooling and service.",
  },
  {
    icon: "skyrocket" as iconTypes,
    title1: "Enhancing communication",
    highlightText: "and",
    title2: "collaboration",
    text: "It enables effective collaborative design and parallel engineering, which accelerates the pace of development, reducing development cycle time and decreasing the number of errors in the production process.",
  },
  {
    icon: "lockClose" as iconTypes,
    title1: "Enhance safety",
    highlightText: "in the learning environment",
    title2: "",
    text: "Our solution eliminates the monotony of classroom training and reduces human error and accidents by offering 3D animations and product operation visualization. Say goodbye to typical, conventional training sessions.",
  },
  {
    icon: "storage" as iconTypes,
    title1: "Instant access to the most up-to-date material",
    highlightText: "without physical presence",
    title2: "",
    text: "Increased training efficiency by standardizing training modules, providing maintenance or repair information on updated units without physical presence.",
  },
  {
    icon: "people" as iconTypes,
    title1: "Increased safety",
    highlightText: "in the learning environment",
    title2: "",
    text: "This holds the attention of trainees for long periods of time and is clearly better a way to train than browsing through multiple volumes of manuals and handbooks.",
  },
];
