import styled from "styled-components";
import Marquee from "react-fast-marquee";

interface MarqueeProps {
  className?: string;
}

export const MainMarquee = ({ className }: MarqueeProps) => {
  return (
    <Wrapper className={className}>
      <BlurSquare />
      <Marquee speed={10} gradientWidth={0}>
        <ImageWrapper>
          <AviatorImg src="/resources/img/aviator-logo.png" alt="scroll" />
          <FltechImg src="/resources/img/fltechnics-logo.png" alt="scroll" />
          <ZagaImg src="/resources/img/zaha-logo.png" alt="scroll" />
          <AviatorImg src="/resources/img/aviator-logo.png" alt="scroll" />
          <FltechImg src="/resources/img/fltechnics-logo.png" alt="scroll" />
          <ZagaImg src="/resources/img/zaha-logo.png" alt="scroll" />
          <AviatorImg src="/resources/img/aviator-logo.png" alt="scroll" />
          <FltechImg src="/resources/img/fltechnics-logo.png" alt="scroll" />
          <ZagaImg src="/resources/img/zaha-logo.png" alt="scroll" />
        </ImageWrapper>
      </Marquee>
      <BlurSquareRight />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const BlurSquare = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 100%;
  background: linear-gradient(90deg, #e0eaec 0%, rgba(236, 241, 243, 0) 100%);
  z-index: 2;
`;

const BlurSquareRight = styled(BlurSquare)`
  left: unset;
  right: 0;
  transform: rotate(-180deg);

  background: linear-gradient(90deg, #edeff3 0%, rgba(215, 227, 234, 0) 100%);
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  height: 35.62px;
`;

const AviatorImg = styled.img`
  margin-top: 9.94px;
  margin-left: 21.54px;
  height: 21.54px;
`;

const FltechImg = styled.img`
  margin-left: 14.08px;

  height: 34.79px;
`;

const ZagaImg = styled.img`
  margin-top: 9.94px;
  margin-left: 27.33px;
  height: 25.68px;
`;
