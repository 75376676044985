import teamwork from "../../../../../assets/images/about/teamwork-icon.png";
import honesty from "../../../../../assets/images/about/honesty-iocn.png";
import learning from "../../../../../assets/images/about/learning-icon.png";
import respect from "../../../../../assets/images/about/respect-icon.png";
import enthusiasm from "../../../../../assets/images/about/enthusiasm.png";
import flexibility from "../../../../../assets/images/about/flexibility.png";

export const CARD_DATA = [
  {
    iconSrc: teamwork,
    title: "Teamwork",
    text: "When we work together, we create something greater than ourselves as individuals.",
  },
  {
    iconSrc: honesty,
    title: "Honesty",
    text: "It's not just the best policy. It's our core business practice and we act in a transparent, trustworthy manner that earns the respect of our colleagues, customers and the public",
  },
  {
    iconSrc: learning,
    title: "Continuous learning",
    text: "No one has all the answers. It's about learning from our experiences and seeing failure as a path to success",
  },
  {
    iconSrc: respect,
    title: "Respect",
    text: "We value differences and we allow others to express themselves in order to work together towards common goals and make our company stronger",
  },
  {
    iconSrc: enthusiasm,
    title: "Enthusiasm",
    text: "Having a joy not just for the work itself but also the people around us, so that everyone can be bold, innovative, and creative",
  },
  {
    iconSrc: flexibility,
    title: "Flexibility",
    text: "We are ready for brave ideas and challenging tasks, always flexible in a workflow for obtaining the best results for our partners",
  },
];
