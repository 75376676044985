import styled from "styled-components";
import { device } from "../../../../assets/themes/breakpoints";
import { SectionLayout } from "../../../../layouts/SectionLayout";

export const SecondSection = () => {
  return (
    <SectionLayout
      topMargins={{
        desktop: 144,
        tablet: 100,
        mobile: 64,
      }}
    >
      <Section>
        <Text>
          This privacy policy explains how CLOYD uses and protects any personal
          data we collect from you when you use this Site.
          <br />
          <br />
          CLOYD is committed to ensuring that your privacy is protected. Should
          we ask you to provide certain information by which you can be
          identified when using this Site, then you can be assured that it will
          only be used in accordance with this Privacy Policy.
        </Text>
        <Title>What data do we collect?</Title>
        <Text>Our company may collect the following data:</Text>
        <br />
        <br />
        <Ul>
          <Li>
            • personal identification information including name, company, email
            address and phone number
          </Li>
          <Li>
            • other information relevant to customer feedback surveys, support
            requests and requests for product information.
          </Li>
        </Ul>
        <br />
        <br />
        <Text>Cloyd VR do not collect any user data</Text>
        <Title>How do we collect your data?</Title>
        <Text>
          You directly provide CLOYD with the data we collect. We receive and
          process data when you:
        </Text>
        <br />
        <br />
        <Ul>
          <Li>- register and download CLOYD application</Li>
          <Li>- request information about any of our products and services</Li>
          <Li>- request us to contact you via our ‘Contact’ page</Li>
          <Li>- use or view our Site via your browser’s cookies.</Li>
        </Ul>
        <Title>How will we use your data?</Title>
        <Text>We collect your data so that we can:</Text>
        <br />
        <br />
        <Ul>
          <Li>
            - respond to requests for information about any of our products and
            services
          </Li>
          <Li>- respond to requests for support</Li>
        </Ul>
        <br />
        <br />
        <Text>
          From time to time, we may also use your information to contact you for
          market research purposes and to send promotional emails about software
          updates, new products and solutions or other information which we
          think you may find interesting.
        </Text>
        <Title>Cookie policy</Title>
        <Text>
          The Site is using cookies. Cookies are small text files that are
          placed on your computer by websites that you visit. They are widely
          used in order to make websites work, or work more efficiently, as well
          as to provide information to the owners of the site. Cookies are
          typically stored on your computer's hard drive. Information collected
          from cookies is used by us to evaluate the effectiveness of our Site
          and analyze trends. The information collected from cookies allows us
          to determine such things as which parts of the Site are most visited
          and difficulties our visitors may experience in accessing the Site.
          With this knowledge, we can improve the quality of your experience on
          the Site by recognizing and delivering more of the most desired
          features and information, as well as by resolving access difficulties.
          Your use of the Site with a browser that is configured to accept
          cookies constitutes acceptance of our cookies. Disabling cookies might
          make it impossible for you to use certain features of the Site.
        </Text>
        <Title>Keeping your information secure</Title>
        <Text>
          We have appropriate security measures in place to prevent personal
          information from being accidentally lost, or used or accessed in an
          unauthorized way. We limit access to your personal information to
          those who have a genuine business need to know it. Those processing
          your information will do so only in an authorized manner and are
          subject to a duty of confidentiality.
        </Text>
      </Section>
    </SectionLayout>
  );
};

const Section = styled.div`
  max-width: 951px;
  margin: 0 auto;
`;

const Text = styled.p`
  ${({ theme }) => theme.typography.paragraph_1_regular};

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.paragraph_1_regular_mobile};
  }
`;

const Ul = styled.ul`
  ${({ theme }) => theme.typography.paragraph_1_regular};

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.paragraph_1_regular_mobile};
  }
`;

const Li = styled.li``;

const Title = styled.h2`
  margin-top: 44px;
  margin-bottom: 26px;
  ${({ theme }) => theme.typography.paragraph_2_regular};

  @media ${device.tablet} {
    ${({ theme }) => theme.typography.paragraph_2_regular_tablet};
  }

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.paragraph_2_regular_mobile};
  }
`;
