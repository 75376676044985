import styled from "styled-components";

import { MainInfo } from "./components/MainInfo";
import { ModelWrapper } from "./components/components";

import { SectionLayout } from "../../../../layouts/SectionLayout";
import { device } from "../../../../assets/themes/breakpoints";
import { MainMarquee } from "./components/Marquee";

export const MainSection = () => {
  return (
    <>
      <SectionLayout>
        <Section>
          <MainInfo />
          <ModelWrapper />
          <StyledMarquee />
        </Section>
      </SectionLayout>
    </>
  );
};

const Section = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  column-gap: 12px;
  width: 100%;
  overflow: visible;

  @media ${device.laptopL} {
    flex-direction: column;
  }
`;

const StyledMarquee = styled(MainMarquee)`
  display: none !important;
  @media ${device.tablet} {
    display: flex !important;
    max-width: none;
    width: calc(100% + 80px);
    transform: translateX(-40px);
    margin-top: 54px;
  }

  @media ${device.mobileL} {
    width: calc(100% + 32px);
    transform: translateX(-16px);
  }
`;
