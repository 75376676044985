import React from "react";
import styled from "styled-components";
import {
  FacebookFIcon,
  InstagramIcon,
  LinkedInIcon,
  YoutubeIcon,
} from "../../assets/icons";

type LinkType = "LinkedIn" | "YouTube" | "Facebook" | "Instagram";

const links = {
  LinkedIn: "https://www.linkedin.com/company/cloyd-ixr/",
  YouTube: "https://www.youtube.com/@cloydixr",
  Facebook: "https://www.facebook.com/Cloyd-IXR-101240756084717",
  Instagram: "https://www.instagram.com/cloydixr/",
};

const Logo = ({ type }: { type: LinkType }) => {
  switch (type) {
    case "LinkedIn":
      return <LinkedInIcon />;
    case "YouTube":
      return <YoutubeIcon />;
    case "Facebook":
      return <FacebookFIcon />;
    case "Instagram":
      return <InstagramIcon />;
    default:
      return <LinkedInIcon />;
  }
};

interface CircleLinkProps {
  type: LinkType;
}

export const CircleLink = ({ type }: CircleLinkProps) => {
  return (
    <Link href={links[type]} target="_blank">
      <Logo type={type} />
    </Link>
  );
};

const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
    transform: scale(1.07);
  }
`;
