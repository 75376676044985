import styled from "styled-components";
import { device } from "../../../../../assets/themes/breakpoints";
import { CircleLink } from "../../../../../ui";
import { Tag } from "../../../../../ui/Tag/Tag";

import gsap from "gsap";
import { useEffect, useRef } from "react";

export const LeftSide = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (!wrapper) return;

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: wrapper,
        start: "top center",
        end: "bottom top",
      },
    });

    tl.fromTo(
      [wrapper],
      {
        x: -30,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.5,
        stagger: 0.2,
        ease: "none",
      }
    );
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <Tag tagName="Email" />
      <Email href="mailto:mail@cloydixr.com ">mail@cloydixr.com</Email>
      <Tag tagName="Address" />
      <Address>Perkūnkiemio g. 7, LT-12131 Vilnius</Address>
      <a
        href="https://maps.app.goo.gl/Easem2LaP2zcw4eZ7"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="/resources/img/map.png"
          alt="Cloyd"
          style={{ width: "100%", borderRadius: "10px" }}
        />
      </a>

      <CircleLinksWrapper>
        <CircleLink type="LinkedIn" />
        <CircleLink type="YouTube" />
        <CircleLink type="Facebook" />
        <CircleLink type="Instagram" />
      </CircleLinksWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 53px 57px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 30px;

  @media ${device.tablet} {
    padding: 30px;
  }
`;

const Email = styled.a`
  ${({ theme }) => theme.typography.paragraph_2_regular};
  margin-top: 16px;
  margin-bottom: 42px;

  @media ${device.tablet} {
    ${({ theme }) => theme.typography.paragraph_2_regular_tablet};
  }

  @media ${device.mobileL} {
    margin-top: 11px;
    margin-bottom: 24px;
    ${({ theme }) => theme.typography.paragraph_2_regular_mobile};
  }
`;

const Address = styled.p`
  margin-top: 16px;
  margin-bottom: 42px;
  ${({ theme }) => theme.typography.paragraph_2_regular};

  @media ${device.tablet} {
    ${({ theme }) => theme.typography.paragraph_2_regular_tablet};
  }

  @media ${device.mobileL} {
    margin-top: 11px;
    margin-bottom: 24px;
    ${({ theme }) => theme.typography.paragraph_2_regular_mobile};
  }
`;

// const Map = styled.iframe`
//   width: 100%;
//   height: 100%;
//   border: 1px solid #e1e2e2;
//   border-radius: 20px;
//   height: 371px;

//   @media ${device.laptopL} {
//     height: 333px;
//   }

//   @media ${device.tablet} {
//     height: 295px;
//   }
// `;

const CircleLinksWrapper = styled.div`
  display: flex;
  column-gap: 14px;
  margin-top: 58px;
`;
