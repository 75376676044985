import styled, { keyframes } from "styled-components";
import { SectionLayout } from "../../../../layouts/SectionLayout";
import { LinkToDownload } from "../../../../ui";

import { device } from "../../../../assets/themes/breakpoints";
import { useEffect, useRef } from "react";

import backgroundImage from "../../../../assets/images/s5-background.jpg";
import websiteImage from "../../../../assets/images/s5-website.png";
import controllerImage from "../../../../assets/images/s5-controllers.png";

import Tilt from "react-parallax-tilt";

import gsap from "gsap";

export const FifthSection = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);
  const linkWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      !sectionRef.current ||
      !imageRef.current ||
      !titleRef.current ||
      !textRef.current ||
      !linkWrapperRef.current
    )
      return;
    const wrapper = sectionRef.current;
    const image = imageRef.current;
    const title = titleRef.current;
    const text = textRef.current;
    const link = linkWrapperRef.current;

    gsap.fromTo(
      title,
      {
        x: 100,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 1,
        ease: "none",
        scrollTrigger: {
          trigger: wrapper,
          start: "top center",
          end: "bottom top",
        },
      }
    );

    gsap.fromTo(
      text,
      {
        x: 100,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        delay: 0.2,
        duration: 0.8,
        ease: "none",
        scrollTrigger: {
          trigger: wrapper,
          start: "top center",
          end: "bottom top",
        },
      }
    );

    gsap.fromTo(
      link,
      {
        x: 100,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        delay: 0.4,
        duration: 0.6,
        ease: "none",
        scrollTrigger: {
          trigger: wrapper,
          start: "top center",
          end: "bottom top",
        },
      }
    );

    gsap.fromTo(
      image,
      {
        x: -100,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        delay: 0.6,
        duration: 0.8,
        ease: "none",
        markers: true,
        scrollTrigger: {
          trigger: wrapper,
          start: "top center",
          end: "bottom top",
        },
      }
    );

    return () => {
      gsap.killTweensOf(title);
      gsap.killTweensOf(text);
      gsap.killTweensOf(link);
      gsap.killTweensOf(image);
    };
  }, []);

  return (
    <BackgroundWrapper>
      <SectionLayout>
        <Section ref={sectionRef}>
          <ImageWrapper ref={imageRef}>
            <TiltStyled
              className="parallax-effect"
              perspective={1000}
              glareEnable={false}
            >
              <Image src={backgroundImage} alt="section5" />
              <WebSiteImage src={websiteImage} alt="section5" />
              <ControllersImage src={controllerImage} alt="section5" />
            </TiltStyled>
          </ImageWrapper>
          <InfoWrapper>
            <Title ref={titleRef}>Impart knowledge using VR/AR.</Title>
            <Text ref={textRef}>
              Go beyond the 2D world and create a new evolution of your
              experience.
            </Text>
            <div ref={linkWrapperRef}>
              <LingLargeScreen marketPlaceName="Oculus" />
            </div>
          </InfoWrapper>

          <LinkSmallScreen marketPlaceName="Oculus" />
        </Section>
      </SectionLayout>
    </BackgroundWrapper>
  );
};
const BackgroundWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
`;

const Section = styled.div`
  position: relative;
  padding: 56px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 30px;

  display: flex;
  align-items: center;

  justify-content: space-between;
  column-gap: 63px;

  @media ${device.laptop} {
    padding: 56px 40px;
    flex-direction: column;
    align-items: flex-start;
  }

  @media ${device.mobileL} {
    padding: 28px 36px;
  }
`;

const Image = styled.img`
  border-radius: 17px;
  min-width: 0;
  width: 100%;

  @media ${device.laptop} {
    order: 1;
  }
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 58px;
  line-height: 65px;
  letter-spacing: -0.04em;

  color: #1d2d44;

  @media ${device.mobileL} {
    font-size: 32px;
    line-height: 38px;
  }
`;
const Text = styled.p`
  margin-top: 28px;
  margin-bottom: 44px;
  ${({ theme }) => theme.typography.paragraph_1_regular};

  @media ${device.mobileL} {
    margin-top: 15px;
    margin-bottom: 28px;

    ${({ theme }) => theme.typography.paragraph_1_mobile};
  }
`;

const InfoWrapper = styled.div`
  width: 488px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${device.laptop} {
    width: auto;
  }
`;

const LinkSmallScreen = styled(LinkToDownload)`
  display: none;
  order: 2;
  margin-top: 46px;

  @media ${device.laptop} {
    display: flex;
  }
`;

const LingLargeScreen = styled(LinkToDownload)`
  display: flex;
  @media ${device.laptop} {
    display: none;
  }
`;

const TiltStyled = styled(Tilt)`
  transform-style: preserve-3d;
`;

const WebSiteImage = styled.img`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: 0.9;

  transform: translateZ(60px);
  scale: 0.95;
`;

const ControllersImage = styled.img`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;

  transform: translateZ(200px);
  scale: 0.95;
`;

const ImageWrapper = styled.div`
  position: relative;
  z-index: 2;
  @media ${device.laptop} {
    order: 2;
  }
`;
