import { useEffect, useRef } from "react";
import styled from "styled-components";
import { device } from "../../../../../assets/themes/breakpoints";

import gsap from "gsap";

export const RightSide = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const title = titleRef.current;
    const text = textRef.current;

    if (!wrapper || !title || !text) return;

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: wrapper,
        start: "top center",
        end: "bottom top",
      },
    });

    tl.fromTo(
      [title, text],
      {
        y: 30,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
        delay: 0.7,
        stagger: 0.2,
        ease: "none",
      }
    );
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <Title ref={titleRef}>
        <Highlight>What is</Highlight> immersive collaboration??
      </Title>
      <Text ref={textRef}>
        Immersive collaboration combines state-of-the-art technology with
        real-time collaboration practices, bringing teams together online,
        allowing them to communicate, work together and build relationships
        effectively regardless of physical location.
        <br />
        <br />
        Instead of just keeping chats active, you can get creative, from
        collaborating on 3D manuals that are updated in real time, to developing
        entire that allow your team members to visually collaborate on common
        work.
      </Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h2`
  max-width: 503px;
  ${({ theme }) => theme.typography.title_2_bold};

  @media ${device.laptop} {
    max-width: none;
  }

  @media ${device.tablet} {
    ${({ theme }) => theme.typography.title_2_bold_tablet};
  }

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.title_2_bold_mobile};
  }
`;

const Highlight = styled.span`
  opacity: 0.5;
`;

const Text = styled.p`
  max-width: 586px;
  margin-top: 28px;

  ${({ theme }) => theme.typography.paragraph_1_regular};

  @media ${device.laptop} {
    max-width: none;
  }

  @media ${device.mobileL} {
    margin-top: 15px;
    ${({ theme }) => theme.typography.paragraph_1_regular_mobile};
  }
`;
