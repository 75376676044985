import styled, { keyframes } from "styled-components";
import "@google/model-viewer";
import { device } from "../../../../../assets/themes/breakpoints";

export const ModelWrapper = () => {
  return (
    <Wrapper>
      <Floating>
        <model-viewer
          id="main-section-model"
          className="model-viewer"
          style={{ width: "100%", height: "100%" }}
          rotation-per-second="15deg"
          auto-rotate
          disable-zoom
          interaction-prompt
          disable-pan
          camera-controls
          src="resources/models/abstract-shape.glb"
          alt="company video"
          ar-status="not-presenting"
        ></model-viewer>
      </Floating>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  max-height: 950px;

  @media ${device.laptopL} {
    margin-top: 50px;
    height: 700px;
  }

  @media ${device.tablet} {
    margin-top: 0;
  }

  @media ${device.mobileL} {
    height: 355px;
  }
`;

const floating = keyframes`
  from {
    transform: translateY(3%);
  }

  to {
    transform: translateY(calc(6% + 50px));
  }
`;

const Floating = styled.div`
  width: 100%;
  height: 100%;
  animation: ${floating} 5s alternate ease-in-out infinite;
  animation-delay: 0.5s;
`;
