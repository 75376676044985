import { useEffect, useRef } from "react";
import styled from "styled-components";
import { SecondSectionDecor } from "../../../../../assets/icons";
import image1 from "../../../../../assets/images/immersive-collaboration/s6-1.png";
import image2 from "../../../../../assets/images/immersive-collaboration/s6-2.png";
import { device } from "../../../../../assets/themes/breakpoints";

import gsap from "gsap";

export const LeftSide = () => {
  const imageWrapperRef = useRef<HTMLImageElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const image1Ref = useRef<HTMLImageElement>(null);
  const image2Ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!imageWrapperRef.current || !wrapperRef.current) return;
    const element = imageWrapperRef.current;

    const image1 = image1Ref.current;
    const image2 = image2Ref.current;

    if (!image1 || !image2) return;

    gsap.fromTo(
      element,
      {
        rotateX: 12,
        rotateY: -12,
        rotateZ: 12,
      },
      {
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0,
        duration: 1,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "top center",
          end: "bottom top",
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      image1,
      {
        blur: 0,
        scale: 1,
      },
      {
        blur: 20,
        scale: 0.8,
        duration: 1,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "+40% center",
          end: "+60% center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      image2,
      {
        right: "-100%",
      },
      {
        right: 0,
        duration: 1,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "+40% center",
          end: "+60% center",
          scrub: true,
        },
      }
    );

    return () => {
      gsap.killTweensOf(element);
    };
  }, [imageWrapperRef, wrapperRef]);

  return (
    <Wrapper ref={wrapperRef}>
      <StickyBlock>
        <BackgroundImage id={"leftSide-second-section-decor_svg__b"} />
        <PerspectiveWrapper>
          <ImageWrapper ref={imageWrapperRef}>
            <Image ref={image1Ref} src={image1} $isStatic={true} />
            <Image ref={image2Ref} src={image2} />
          </ImageWrapper>
        </PerspectiveWrapper>
      </StickyBlock>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  @media ${device.laptopL} {
    max-width: 550px;
  }

  @media ${device.laptop} {
    display: none;
  }
`;

const StickyBlock = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 30vh;
`;

const BackgroundImage = styled(SecondSectionDecor)`
  position: absolute;
  top: 50%;
  left: -116px;
  transform: translateY(-50%);
`;

const Image = styled.img<{ $isStatic?: boolean }>`
  position: ${({ $isStatic }) => ($isStatic ? "static" : "absolute")};
  top: 0;
  width: 100%;
  max-width: 700px;
  height: 100%;

  transform-style: preserve-3d;
`;

const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 28px;
  max-width: 100%;
  will-change: transform;
  transform-style: preserve-3d;
  height: calc(100% / 3);
  overflow: hidden;
  background-color: #e1e6ea;
  box-shadow: 10px 54px 84px rgba(0, 0, 0, 0.12);
  line-height: 0;
`;

const PerspectiveWrapper = styled.div`
  will-change: transform;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(6.99776deg)
    rotateY(-6.99776deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
`;
