import * as React from "react";
import { SVGProps } from "react";
const SvgPlayVideoTriangle = ({ fill, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width={23}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.55 10.9 3.677.335C2.224-.525 0 .308 0 2.43V23.56c0 1.904 2.067 3.052 3.676 2.097l17.875-10.562c1.594-.94 1.6-3.255 0-4.194Z"
      fill={fill || "#1D2D44"}
    />
  </svg>
);
export default SvgPlayVideoTriangle;
