import styled from "styled-components";
import { FooterSection } from "../../components/Footer";
import { FirstSection, SecondSection } from "../../components/pages/Industries";

export const Industries = () => {
  return (
    <Wrapper>
      <FirstSection />
      <SecondSection />
      <FooterSection />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;
