import styled, { keyframes } from "styled-components";
import { device } from "../../../../../assets/themes/breakpoints";

export const LeftSide = () => {
  return (
    <Wrapper>
      <Floating>
        <model-viewer
          id="seven-section-model"
          className="model-viewer"
          style={{ width: "100%", height: "100%" }}
          rotation-per-second="15deg"
          auto-rotate
          disable-zoom
          interaction-prompt
          disable-pan
          camera-controls
          src="resources/models/abstract-shape.glb"
          alt="company video"
          ar-status="not-presenting"
        ></model-viewer>
      </Floating>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 749px;

  @media ${device.tabletL} {
    height: 500px;
  }

  @media ${device.mobileL} {
    height: 357px;
  }
`;

const floating = keyframes`
  from {
    transform: translateY(-5%);
  }

  to {
    transform: translateY(calc(-5% + 50px));
  }
`;

const Floating = styled.div`
  width: 100%;
  height: 100%;
  animation: ${floating} 5s alternate ease-in-out infinite;
  animation-delay: 0.5s;
`;
