import * as React from "react";
import { SVGProps } from "react";
const SvgArrowLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={12}
    height={7}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M11 1 6 5.857 1 1" stroke="#1D2D44" strokeLinecap="round" />
  </svg>
);
export default SvgArrowLink;
