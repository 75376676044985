import styled from "styled-components";
import { device } from "../../../../assets/themes/breakpoints";
import { LeftSide, RightSide } from "./components";

export const FourthSection = () => {
  return (
    <Layout>
      <Content>
        <Section>
          <LeftSide />
          <RightSide />
        </Section>
      </Content>
    </Layout>
  );
};

const Layout = styled.section`
  overflow: hidden;
  width: 100%;
`;

const Content = styled.div`
  z-index: 2;
  position: relative;
  margin: 0 auto;
  max-width: 1620px;
  padding: 0 90px;
  width: 100%;

  @media ${device.laptopL} {
    padding: 0 40px;
  }

  @media ${device.mobileL} {
    padding: 0 16px;
  }
`;

const Section = styled.div`
  position: relative;
  padding-top: 220px;
  padding-bottom: 220px;
  display: grid;
  align-items: center;
  grid-template-columns: 464px 1fr;
  grid-column-gap: 83px;
  max-width: 100%;

  @media ${device.laptop} {
    grid-template-columns: 1fr;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @media ${device.tablet} {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media ${device.mobileL} {
    padding-top: 63px;
    padding-bottom: 63px;
  }
`;
