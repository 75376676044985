import styled from "styled-components";
import { SectionLayout } from "../../../../layouts/SectionLayout";
import { Card } from "./components/Card";
import { CARD_DATA } from "./constants";

import backgroundS3L from "../../../../assets/images/bg-s3-l.png";
import backgroundS3R from "../../../../assets/images/bg-s3-r.png";
import { device } from "../../../../assets/themes/breakpoints";
import { useRef } from "react";

export const ThirdSection = () => {
  const cardWrapperRef = useRef<HTMLDivElement>(null);
  return (
    <BackgroundWrapper>
      <BackgroundDecorations>
        <BackgroundLeft src={backgroundS3L} />
        <BackgroundRight src={backgroundS3R} />
      </BackgroundDecorations>
      <SectionLayout>
        <Section ref={cardWrapperRef}>
          {CARD_DATA.map((card, index) => (
            <Card
              key={index}
              {...card}
              cardIndex={index}
              cardWrapperRef={cardWrapperRef}
            />
          ))}
        </Section>
      </SectionLayout>
    </BackgroundWrapper>
  );
};

const Section = styled.div`
  margin-top: 270px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;

  @media ${device.laptopL} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.tabletL} {
    grid-template-columns: 1fr;
    margin-top: 100px;
  }

  @media ${device.mobileL} {
    margin-top: 63px;
    grid-gap: 16px;
  }
`;

const BackgroundWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
`;

const BackgroundDecorations = styled.div`
  position: absolute;
  top: -2500px;
  left: 0;
  width: 100%;
  height: 5000px;
  overflow: hidden;
  display: none;
`;

const BackgroundImage = styled.img`
  position: absolute;
  overflow: visible;
`;

const BackgroundLeft = styled(BackgroundImage)`
  top: 3%;
  left: -150%;
`;

const BackgroundRight = styled(BackgroundImage)`
  top: 50%;
  right: -80%;
  opacity: 0.6;
`;
