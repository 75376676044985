import styled from "styled-components";

interface TagProps {
  tagName: string;
}

export const Tag = ({ tagName }: TagProps) => {
  return <Wrapper>{tagName}</Wrapper>;
};

const Wrapper = styled.div`
  padding: 10px 15px;
  background: rgba(0, 0, 0, 0.04);

  border-radius: 10px;

  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.04em;

  color: #1d2d44;

  user-select: none;
`;
