import styled from "styled-components";
import { FirstSection } from "../../components/pages/Download";

export const Download = () => {
  return (
    <Wrapper>
      <FirstSection />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;
