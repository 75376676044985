import image1 from "../../../../../assets/images/message-icon.png";
import image2 from "../../../../../assets/images/s6-tablet.png";
import image3 from "../../../../../assets/images/s6-geometry.png";
import image4 from "../../../../../assets/images/s3-mobile.png";
import image5 from "../../../../../assets/images/s6-call-center.png";
import image6 from "../../../../../assets/images/copywriting-icon.png";
import image7 from "../../../../../assets/images/s3-people.png";
import image8 from "../../../../../assets/images/s6-cloud.png";

export const CARD_DATA = [
  {
    iconSrc: image1,
    title: "Online text chat rooms",
  },
  {
    iconSrc: image2,
    title: "Shared notes lists",
  },
  {
    iconSrc: image3,
    title: "3D collaborating spaces",
  },
  {
    iconSrc: image4,
    title: "Screen capture",
  },
  {
    iconSrc: image5,
    title: "Voice conferencing chat",
  },
  {
    iconSrc: image6,
    title: "3D Documents and manuals that update in real-time",
  },
  {
    iconSrc: image7,
    title: "Multiplayer support",
  },
  {
    iconSrc: image8,
    title: "Share option",
  },
];
