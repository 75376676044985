import styled from "styled-components";
import { SecondSectionDecor } from "../../../../../assets/icons";
import previewBase from "../../../../../assets/images/s4-previewBase.png";
import objectsImage from "../../../../../assets/images/s4-objects.png";
import { device } from "../../../../../assets/themes/breakpoints";
import { LinkWithArrow } from "../../../../../ui";

import Tilt from "react-parallax-tilt";
import { useEffect, useRef } from "react";

import gsap from "gsap";
import { RoutePaths } from "../../../../../interfaces/routesPaths";

export const RightSide = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const imageWrapperRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!imageWrapperRef.current || !wrapperRef.current) return;
    const wrapper = wrapperRef.current;
    const element = imageWrapperRef.current;

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: wrapper,
        start: "top center",
        end: "bottom top",
      },
    });

    tl.fromTo(
      element,
      {
        x: 100,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 1,
        delay: 0.5,
        ease: "none",
      }
    );
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <BackgroundImageWrapper>
        <BackgroundImage id="RightSideBackgroundImage" />
      </BackgroundImageWrapper>

      <div ref={imageWrapperRef}>
        <TiltStyled
          className="parallax-effect"
          perspective={1200}
          glareEnable={true}
          tiltAngleXInitial={10}
          tiltAngleYInitial={15}
        >
          <Image src={previewBase} />
          <ObjectsImage src={objectsImage} />
        </TiltStyled>
      </div>

      <ArrowLink
        href={RoutePaths.DOWNLOAD.path}
        text="Try Now!"
        linkType="dark"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const BackgroundImage = styled(SecondSectionDecor)`
  position: absolute;
  top: 50%;
  right: -90px;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  position: relative;
  max-width: 100%;
  object-fit: cover;
  margin-right: 40px;
  box-shadow: 10px 54px 84px rgba(0, 0, 0, 0.12);
  border-radius: 15px;

  @media ${device.laptopL} {
    margin-right: 0;
  }
`;

const ObjectsImage = styled.img`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;

  transform: translateZ(60px);
  scale: 0.95;
`;

const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: -20%;
  right: -90px;
  width: 100%;
  height: 140%;
  z-index: -1;
  overflow: hidden;

  @media ${device.laptopL} {
    right: -40px;
  }

  @media ${device.mobileL} {
    right: -16px;
  }
`;

const ArrowLink = styled(LinkWithArrow)`
  display: none;
  margin-top: 34.5px;
  @media ${device.laptop} {
    display: block;
  }
`;

const TiltStyled = styled(Tilt)`
  transform-style: preserve-3d;
`;
