import { Reset } from "styled-reset";
import GlobalFonts from "./assets/fonts/fonts";
import { Theme } from "./providers/Themes";
import { AppRouter } from "./routes";
import { ToastContainer } from "react-toastify";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { registerBlurPlugin, useWindowLoading } from "./utils";
import { Loader } from "./components";
import "react-toastify/dist/ReactToastify.css";

gsap.registerPlugin(ScrollTrigger);
registerBlurPlugin();

function App() {
  const isLoading = useWindowLoading();
  return (
    <div className="App">
      <Reset />
      <GlobalFonts />
      <ToastContainer />
      <Theme>
        {isLoading && <Loader />}
        <AppRouter />
        <div id="modal-root" />
      </Theme>
    </div>
  );
}

export default App;
