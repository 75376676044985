import styled from "styled-components";
import { device } from "../../../../assets/themes/breakpoints";
import { SectionLayout } from "../../../../layouts/SectionLayout";
import { LeftSide, RightSide } from "./components";

export const ThirdSection = () => {
  return (
    <SectionLayout
      topMargins={{
        desktop: 161,
        tablet: 100,
        mobile: 63,
      }}
    >
      <Section>
        <LeftSide />
        <RightSide />
      </Section>
    </SectionLayout>
  );
};

const Section = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  width: 100%;
  justify-content: center;

  @media ${device.laptop} {
    flex-direction: column;
  }

  @media ${device.mobileL} {
    gap: 30px;
  }
`;
