import { useRef } from "react";
import styled from "styled-components";
import { device } from "../../../../assets/themes/breakpoints";
import { RoutePaths } from "../../../../interfaces/routesPaths";

import { SectionLayout } from "../../../../layouts/SectionLayout";
import { LinkWithArrow } from "../../../../ui";

import { useAnimation } from "./animations";

export const FirstSection = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);
  const linkRef = useRef<HTMLDivElement>(null);

  useAnimation({
    sectionRef,
    titleRef,
    textRef,
    linkRef,
  });

  return (
    <SectionLayout>
      <Section ref={sectionRef}>
        <Title ref={titleRef}>About Cloyd</Title>
        <Text ref={textRef}>
          Cloyd is a global Extended Reality company providing Virtual and
          Augmented Reality solutions to diverse industries for over 20 years.
        </Text>
        <div ref={linkRef}>
          <LinkWithArrow
            href={RoutePaths.CAREERS.path}
            linkType="dark"
            text={"Join our Team"}
            to={RoutePaths.CAREERS.path}
            hideArrow
          />
        </div>
      </Section>
    </SectionLayout>
  );
};

const Section = styled.div`
  margin-top: 187px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    margin-top: 120px;
    align-items: flex-start;
  }

  @media ${device.mobileL} {
    margin-top: 83px;
    align-items: flex-start;
  }
`;

const Title = styled.h1`
  text-align: center;
  ${({ theme }) => theme.typography.title_1_bold};

  @media ${device.tablet} {
    text-align: left;
    ${({ theme }) => theme.typography.title_1_bold_tablet};
  }

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.title_1_bold_mobile};
  }
`;

const Text = styled.p`
  text-align: center;
  margin-top: 23px;
  margin-bottom: 23px;
  max-width: 710px;

  ${({ theme }) => theme.typography.paragraph_1_regular};

  @media ${device.tablet} {
    text-align: left;
  }

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.paragraph_1_regular_mobile};
  }
`;
