import styled from "styled-components";
import { device } from "../../../../../assets/themes/breakpoints";

import image1 from "../../../../../assets/images/s2-1.png";
import image2 from "../../../../../assets/images/s2-2.png";
import image3 from "../../../../../assets/images/s2-3.png";
import { InformationBlock } from "../../../../../ui/InformationBlock";

const INFORMATION_BLOCK_DATA = [
  {
    tag: "3D Manuals",
    title: "Immersive training",
    highlightedText: " with manuals",
    text: "The Cloyd Manuals offers you an interactive way to explore, to interact, and investigate different objects and how they work with simple-to-use controls for helping you achieve more with a limited amount of time.",
    imageSrc: image1,
  },
  {
    tag: "Meetings",
    title: "Meeting",
    highlightedText: "environment",
    text: "Cloyd offers you the remote ability to create and join interactive meeting environments with friends and colleagues, giving you the maximum opportunity to interact, manage and implement",
    imageSrc: image2,
  },
  {
    tag: "Mixed Reality",
    title: "3D/AR/VR",
    highlightedText: "Model Viewer",
    text: "With Cloyd you can display the model in your preferred view and switch between 3D, AR and VR environments.",
    imageSrc: image3,
  },
];

export const RightSide = () => {
  return (
    <Wrapper>
      {INFORMATION_BLOCK_DATA.map((data, index) => (
        <InformationBlock
          key={index}
          {...data}
          isSecondVariant={index % 2 !== 0}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 270px;
  z-index: 2;

  @media ${device.laptop} {
    padding-bottom: 0;
    row-gap: 80px;
  }

  @media ${device.mobileL} {
    row-gap: 60px;
  }
`;
