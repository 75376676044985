import { useRef } from "react";
import styled from "styled-components";
import { device } from "../../../../assets/themes/breakpoints";

import { SectionLayout } from "../../../../layouts/SectionLayout";
import { useAnimation } from "./animations";

export const FirstSection = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);

  useAnimation({
    sectionRef,
    titleRef,
    textRef,
  });

  return (
    <SectionLayout>
      <Section ref={sectionRef}>
        <Title ref={titleRef}>Careers</Title>
        <Text ref={textRef}>
          We're seeking passionate individuals to improve daily life and
          business processes with 3D, augmented, and virtual reality
          technologies.
        </Text>
      </Section>
    </SectionLayout>
  );
};

const Section = styled.div`
  margin-top: 187px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    margin-top: 120px;
    align-items: flex-start;
  }

  @media ${device.mobileL} {
    margin-top: 83px;
    align-items: flex-start;
  }
`;

const Title = styled.h1`
  text-align: center;
  ${({ theme }) => theme.typography.title_1_bold};

  @media ${device.tablet} {
    text-align: left;
    ${({ theme }) => theme.typography.title_1_bold_tablet};
  }

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.title_1_bold_mobile};
  }
`;

const Text = styled.p`
  text-align: center;
  margin-top: 23px;
  max-width: 650px;

  ${({ theme }) => theme.typography.paragraph_1_regular};

  @media ${device.tablet} {
    text-align: left;
  }

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.paragraph_1_regular_mobile};
  }
`;
