import { useEffect } from "react";
import gsap from "gsap";

interface UseAnimationProps {
  sectionRef: React.RefObject<HTMLDivElement>;
  titleRef: React.RefObject<HTMLHeadingElement>;
  textRef: React.RefObject<HTMLParagraphElement>;
  linkRef: React.RefObject<HTMLDivElement>;
}

export const useAnimation = ({
  sectionRef,
  titleRef,
  textRef,
  linkRef,
}: UseAnimationProps) => {
  useEffect(() => {
    const section = sectionRef.current;
    const title = titleRef.current;
    const text = textRef.current;
    const link = linkRef.current;

    if (!title || !text || !link || !section) return;

    const animation = gsap.fromTo(
      [title, text, link],
      {
        y: 30,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        delay: 0.5,
        duration: 0.7,
        stagger: 0.2,
      }
    );

    return () => {
      animation.progress(0).kill();
    };
  }, []);
};
