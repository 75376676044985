import { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import { LinkWithArrow } from "../../ui";
import { Links } from "./components/Links";

import backgroundHeaderT from "../../assets/images/bg-s5-l.png";
import backgroundHeaderB from "../../assets/images/bg-s1-r.png";
import { device } from "../../assets/themes/breakpoints";
import gsap from "gsap";
import { RoutePaths } from "../../interfaces/routesPaths";

export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const arrowLinkRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!arrowLinkRef) return;
    const animation = gsap.fromTo(
      arrowLinkRef.current,
      {
        x: 30,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        delay: 1.2,
        duration: 0.7,
      }
    );

    return () => {
      animation.progress(0).kill();
    };
  }, [arrowLinkRef]);

  const handleSwitchMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const smoothScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    smoothScrollToTop();
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isMenuOpen]);

  return (
    <Wrapper $isMenuOpen={isMenuOpen}>
      <BackgroundDecorations $isMenuOpen={isMenuOpen}>
        <BackgroundHeaderTop />
        <BackgroundHeaderBottom />
      </BackgroundDecorations>
      <Content>
        <LogoWrapper href={RoutePaths.BASE_PATH.path}>
          <CloydLogo />
          <CloydLogoSmall />
        </LogoWrapper>
        <Links isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
        <div ref={arrowLinkRef}>
          <ArrowLink
            to={RoutePaths.DOWNLOAD.path}
            text="Download App!"
            linkType="transparent with border"
          />
        </div>
        <Burger onClick={handleSwitchMenu} $isMenuOpen={isMenuOpen} />
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.header<{ $isMenuOpen: boolean }>`
  position: fixed;
  top: 0;
  z-index: 99;
  background-color: #f0f3f6;

  width: 100%;
  max-width: 1620px;
  padding: 23px 90px;
  transition: background-color 0.3s ease-in-out;

  @media ${device.laptopL} {
    position: ${({ $isMenuOpen }) => ($isMenuOpen ? "fixed" : "absolute")};
    background-color: ${({ $isMenuOpen }) =>
      $isMenuOpen ? "#fff" : "transparent"};
    padding: 23px 40px;
  }

  @media ${device.mobileL} {
    padding: 16px;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoWrapper = styled.a``;

const CloydLogo = styled.img.attrs({
  src: "/resources/img/cloyd-logo.png",
  alt: "Cloyd Logo",
})`
  width: 127.88px;
  height: 38px;
  object-fit: cover;

  @media ${device.laptopL} {
    display: none;
  }
`;

const CloydLogoSmall = styled.img.attrs({
  src: "/resources/img/cloyd-logo-small.png",
  alt: "Cloyd Logo",
})`
  display: none;
  width: 38px;
  height: 38px;
  @media ${device.laptopL} {
    position: relative;
    z-index: 2;
    display: block;
  }

  @media ${device.mobileL} {
    width: 31px;
    height: 30px;
  }
`;

const ArrowLink = styled(LinkWithArrow)`
  position: relative;
  z-index: 2;
  @media ${device.laptopL} {
    display: none;
  }
`;

const Burger = styled.button<{ $isMenuOpen: boolean }>`
  display: none;
  z-index: 99;
  @media ${device.laptopL} {
    display: block;
  }

  position: relative;
  width: 32px;
  height: 32px;
  will-change: transform;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    background: black;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    transition: ${({ $isMenuOpen }) =>
      $isMenuOpen
        ? "margin 0.2s linear 0s, transform 0.2s linear 0.3s"
        : "margin 0.2s linear 0.3s, transform 0.2s linear 0s"};
  }

  &::before {
    margin-top: ${({ $isMenuOpen }) => ($isMenuOpen ? "-1px" : "-6px")};
    transform: ${({ $isMenuOpen }) => ($isMenuOpen ? "rotate(45deg)" : "none")};
  }

  &::after {
    margin-top: ${({ $isMenuOpen }) => ($isMenuOpen ? "-1px" : "4px")};
    transform: ${({ $isMenuOpen }) =>
      $isMenuOpen ? "rotate(-45deg)" : "none"};
  }
`;

const BackgroundDecorations = styled.div<{
  $isMenuOpen: boolean;
}>`
  display: none;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: -100vh;
  overflow: hidden;
  z-index: 1;
  transition: opacity 0.3s ease-in-out;
  transition-delay: ${({ $isMenuOpen }) => ($isMenuOpen ? "0.3s" : "0s")};

  @media ${device.laptopL} {
    display: none;
    opacity: ${({ $isMenuOpen }) => ($isMenuOpen ? "1" : "0")};
    left: ${({ $isMenuOpen }) => ($isMenuOpen ? "0" : "-100vh")};
  }
`;

const BackgroundHeaderTop = styled.img.attrs({
  src: backgroundHeaderT,
  alt: "Background Header Left",
})`
  position: absolute;
  top: -800px;
  left: -500px;
`;

const BackgroundHeaderBottom = styled.img.attrs({
  src: backgroundHeaderB,
  alt: "Background Header Right",
})`
  position: absolute;
  bottom: -1900px;
  right: -1300px;
`;
