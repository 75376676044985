import * as React from "react";
import { SVGProps } from "react";
const SvgArrowRight = ({ color, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={14}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 6h12m0 0L8.143 1M13 6l-4.857 5"
        stroke={color || "#1D2D44"}
        strokeLinecap="round"
      />
    </svg>
  );
};
export default SvgArrowRight;
