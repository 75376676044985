import styled from "styled-components";
import { SecondSectionDecor } from "../../../../../assets/icons";
import { device } from "../../../../../assets/themes/breakpoints";

import Tilt from "react-parallax-tilt";

import gsap from "gsap";
import { useEffect, useRef } from "react";

export const LeftSide = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const imageWrapperRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!imageWrapperRef.current || !wrapperRef.current) return;
    const wrapper = wrapperRef.current;
    const element = imageWrapperRef.current;

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: wrapper,
        start: "top center",
        end: "bottom top",
      },
    });

    tl.fromTo(
      element,
      {
        x: -100,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.7,
        ease: "none",
      }
    );
  }, []);
  return (
    <Wrapper ref={wrapperRef}>
      <BackgroundImage id={"leftSide-second-section-about-us_svg__b"} />
      <Tilt className="parallax-effect" perspective={1200} glareEnable={true}>
        <div ref={imageWrapperRef}>
          <Image src="/resources/img/about-s2.png" alt="our company" />
        </div>
      </Tilt>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;

  @media ${device.laptopL} {
    max-width: 600px;
  }
`;

const Image = styled.img`
  width: 100%;
  position: relative;
  border-radius: 30px;
  box-shadow: 10px 84px 84px rgba(0, 0, 0, 0.12);
`;

const BackgroundImage = styled(SecondSectionDecor)`
  position: absolute;
  top: -66px;
  left: -157px;
`;
