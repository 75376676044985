import { useEffect, useRef } from "react";
import styled from "styled-components";
import { SectionLayout } from "../../../../layouts/SectionLayout";

import gsap from "gsap";
import { CheckCard } from "../../../../ui/Cards";
import { CARD_DATA } from "./constants/card-data";
import { device } from "../../../../assets/themes/breakpoints";

export const FourthSection = () => {
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const section = sectionRef.current;

    if (!section) return;

    gsap.fromTo(
      section,
      {
        opacity: 0,
        y: -25,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: section,
          start: "top center",
        },
      }
    );

    return () => {
      gsap.killTweensOf(section);
    };
  }, []);

  return (
    <SectionLayout
      topMargins={{
        desktop: 190,
        tablet: 100,
        mobile: 70,
      }}
    >
      <Section>
        <TitleWrapper>
          <Title>
            With Cloyd IXR Hubs{" "}
            <HighlightTitle>it&#39;s possible to:</HighlightTitle>
          </Title>
        </TitleWrapper>
        {CARD_DATA.map((card, index) => (
          <CheckCard title={card.title} key={index} />
        ))}
      </Section>
    </SectionLayout>
  );
};

const Section = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;

  @media ${device.laptopL} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }

  @media ${device.mobileL} {
    margin-top: 43px;
    grid-gap: 16px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  @media ${device.mobileL} {
    margin-bottom: 6px;
  }
`;

const Title = styled.h2`
  ${({ theme }) => theme.typography.title_2_bold};

  @media ${device.laptop} {
    max-width: none;
  }

  @media ${device.tablet} {
    ${({ theme }) => theme.typography.title_2_bold_tablet};
  }

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.title_2_bold_mobile};
  }
`;

const HighlightTitle = styled.span`
  opacity: 0.5;
`;
