import styled from "styled-components";
import { device } from "../assets/themes/breakpoints";

interface TopMargins {
  desktop: number;
  tablet: number;
  mobile: number;
}
interface SectionLayoutProps {
  children: React.ReactNode;
  topMargins?: TopMargins;
  className?: string;
}

export const SectionLayout = ({
  children,
  topMargins = { desktop: 0, tablet: 0, mobile: 0 },
  className,
}: SectionLayoutProps) => {
  return (
    <Layout $topMargins={topMargins} className={className}>
      {children}
    </Layout>
  );
};

const Layout = styled.section<{ $topMargins?: TopMargins }>`
  z-index: 3;
  position: relative;
  margin: ${({ $topMargins }) => `${$topMargins?.desktop}px auto 0 auto`};
  max-width: 1620px;
  padding: 0 90px;
  width: 100%;

  @media ${device.laptopL} {
    padding: 0 40px;
  }

  @media ${device.tablet} {
    margin: ${({ $topMargins }) => `${$topMargins?.tablet}px auto 0 auto`};
  }

  @media ${device.mobileL} {
    padding: 0 16px;
    margin: ${({ $topMargins }) => `${$topMargins?.mobile}px auto 0 auto`};
  }
`;
