import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

interface ModalWindowProps {
  children: React.ReactNode;
  className?: string;
  contentStyle?: React.CSSProperties;
  isOpen: boolean;
  onClose: () => void;
}

export const ModalWindow = ({
  children,
  className,
  contentStyle,
  isOpen,
  onClose,
}: ModalWindowProps) => {
  const banScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const allowScroll = () => {
    document.body.style.overflow = "visible";
  };

  useEffect(() => {
    isOpen && banScroll();
    return () => {
      allowScroll();
    };
  }, [isOpen]);

  const renderElement = document.getElementById("modal-root");

  return renderElement && isOpen
    ? ReactDOM.createPortal(
        <Wrapper className={className} onClick={onClose}>
          <Content style={contentStyle}>{children}</Content>
        </Wrapper>,
        renderElement
      )
    : null;
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const Content = styled.div`
  background-color: #fff;
  padding: 30px;
  overflow: hidden;
`;
