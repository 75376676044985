import image1 from "../../../../assets/images/s6-cloud.png";
import image2 from "../../../../assets/images/s3-storage.png";
import image3 from "../../../../assets/images/s3-skyrocket.png";

export const CARD_DATA = [
  {
    iconSrc: image1,
    highlightTitle: "What is",
    title: "IXR Hubs",
    text: "IXR Hubs is a cloud platform for storing and sharing 3D and IXR models. Models can be easily embedded and launched from the platform, and Hubs work with the CloydXR app for remote viewing.",
  },
  {
    iconSrc: image2,
    highlightTitle: "What is",
    title: "IXR file",
    text: "The IXR format includes 3D models, text, sounds, and scripts. It supports complex models and scenes, game mechanics, and animations. The file contains precise location and camera information, and scripts control playback order and object behavior scenarios.",
  },
  {
    iconSrc: image3,
    highlightTitle: "What is the",
    title: "advantage of IXR Hubs",
    text: "Hubs only transfer the necessary file version for a platform, such as iOS or VR. Retrieval is fast, taking seconds to find a needed model.",
  },
];
