import styled from "styled-components";
import { FooterSection } from "../../components/Footer";
import {
  FirstSection,
  SecondSection,
  ThirdSection,
  FourthSection,
  FifthSection,
} from "../../components/pages/Careers";

export const Careers = () => {
  return (
    <Wrapper>
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
      <FooterSection />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;
