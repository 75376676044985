import styled from "styled-components";
import { SecondSectionDecor } from "../../../../../assets/icons";
import image from "../../../../../assets/images/manuals/s3-1.png";
import Tilt from "react-parallax-tilt";
import { LinkWithArrow } from "../../../../../ui";
import { device } from "../../../../../assets/themes/breakpoints";
import { useEffect, useRef } from "react";

import gsap from "gsap";
import { RoutePaths } from "../../../../../interfaces/routesPaths";

export const RightSide = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const wrapper = wrapperRef.current;

    if (!wrapper) return;

    gsap.fromTo(
      wrapper,
      {
        opacity: 0,
        x: 45,
      },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        delay: 1,
        scrollTrigger: {
          trigger: wrapper,
          start: "top center",
        },
      }
    );
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <BackgroundImage id={"manuals-rightSide-second-section-decor_svg__b"} />

      <Image src={image} />
      <LinkWithArrowStyled
        text={"Try Now!"}
        linkType="dark"
        href={RoutePaths.DOWNLOAD.path}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
`;

const BackgroundImage = styled(SecondSectionDecor)`
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
`;

const Image = styled.img`
  position: relative;
  width: 100%;
  max-width: 740px;
  margin-right: 50px;
`;

const TiltStyled = styled(Tilt)`
  transform-style: preserve-3d;
`;

const LinkWithArrowStyled = styled(LinkWithArrow)`
  display: none;
  @media ${device.laptop} {
    display: flex;
    margin-top: 15px;
  }
`;
