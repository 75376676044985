import styled from "styled-components";
import { device } from "../../../../assets/themes/breakpoints";
import { SectionLayout } from "../../../../layouts/SectionLayout";
import { CardWithImage } from "./components";
import { CARD_DATA } from "./constants";

import gsap from "gsap";
import { useEffect, useRef } from "react";

export const FifthSection = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const section = sectionRef.current;
    const title = titleRef.current;

    if (!section || !title) return;

    gsap.fromTo(
      title,
      {
        opacity: 0,
        y: 5,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: section,

          start: "top center",
        },
      }
    );

    return () => {
      gsap.killTweensOf(section);
    };
  }, []);

  return (
    <SectionLayout
      topMargins={{
        desktop: 161,
        tablet: 100,
        mobile: 63,
      }}
    >
      <Section ref={sectionRef}>
        <Title ref={titleRef}>How can it be used?</Title>
        <CardsWrapper>
          {CARD_DATA.map((card, index) => (
            <CardWithImage key={index} image={card.image} title={card.title} />
          ))}
        </CardsWrapper>
      </Section>
    </SectionLayout>
  );
};

const Section = styled.div``;

const Title = styled.h2`
  ${({ theme }) => theme.typography.title_2_bold};

  @media ${device.tablet} {
    ${({ theme }) => theme.typography.title_2_bold_tablet};
  }

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.title_2_bold_mobile};
  }
`;

const CardsWrapper = styled.div`
  margin-top: 34px;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, 1fr);

  @media ${device.laptop} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
