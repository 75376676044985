import styled from "styled-components";
import { SectionLayout } from "../../../../layouts/SectionLayout";
import { LeftSide, RightSide } from "./components";

import { device } from "../../../../assets/themes/breakpoints";

export const SeventhSection = () => {
  return (
    <BackgroundWrapper>
      <SectionLayout>
        <Section>
          <LeftSide />
          <RightSide />
        </Section>
      </SectionLayout>
    </BackgroundWrapper>
  );
};

const Section = styled.div`
  position: relative;
  margin-top: 181px;
  display: grid;
  grid-template-columns: 1fr 468px;
  grid-column-gap: 187px;
  align-items: center;

  @media ${device.laptopL} {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }
`;

const BackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
`;
