import * as React from "react";
import { SVGProps } from "react";
const SvgFacebookFIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={9}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m7.507 8.438.417-2.715H5.319V3.96c0-.742.364-1.466 1.53-1.466h1.185V.183S6.959 0 5.932 0C3.787 0 2.384 1.3 2.384 3.654v2.069H0v2.715h2.384V15H5.32V8.437h2.188Z"
      fill="#fff"
    />
  </svg>
);
export default SvgFacebookFIcon;
