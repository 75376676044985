import * as React from "react";
import { SVGProps } from "react";
const SvgOculusLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={39}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 39 23"
    {...props}
  >
    <path
      d="M34.635 2.61A11.885 11.885 0 0 0 29.972.387a14.819 14.819 0 0 0-2.967-.368c-.738-.03-1.492-.015-2.23-.015H14.21c-.738 0-1.492 0-2.23.015-1.004.03-1.978.138-2.967.368A11.82 11.82 0 0 0 4.365 2.61C1.6 4.77 0 8.036 0 11.5c0 3.464 1.601 6.73 4.365 8.89a11.887 11.887 0 0 0 4.663 2.223c.973.23 1.962.337 2.967.368.738.03 1.492.015 2.23.015H24.79c.738 0 1.492 0 2.23-.015a14.959 14.959 0 0 0 2.967-.368 11.822 11.822 0 0 0 4.647-2.223C37.4 18.23 39 14.964 39 11.5c0-3.464-1.601-6.73-4.365-8.89ZM29.47 14.596a4.098 4.098 0 0 1-1.711.66c-.613.091-1.24.076-1.853.076H13.11c-.628 0-1.24.015-1.853-.077a4.018 4.018 0 0 1-1.711-.659c-1.036-.705-1.664-1.854-1.664-3.096s.628-2.391 1.664-3.096a4.098 4.098 0 0 1 1.711-.66c.613-.091 1.24-.076 1.853-.076h12.796c.628 0 1.24-.015 1.853.077a4.019 4.019 0 0 1 1.71.659c1.037.705 1.665 1.854 1.665 3.096.016 1.242-.612 2.391-1.664 3.096Z"
      fill="#fff"
    />
  </svg>
);
export default SvgOculusLogo;
