import styled from "styled-components";
import { device } from "../../../../assets/themes/breakpoints";
import { SectionLayout } from "../../../../layouts/SectionLayout";

export const SecondSection = () => {
  return (
    <SectionLayout
      topMargins={{
        desktop: 144,
        tablet: 100,
        mobile: 64,
      }}
    >
      <Section>
        <TextWithoutOpacity>
          <TextOpacity>
            Please scroll down and read the following Terms and Conditions
            carefully. This is a legal agreement (the{" "}
          </TextOpacity>
          <Bold>&#39;Agreement&#39;</Bold>
          <TextOpacity>
            {" "}
            or &#39;these Terms&#39;) between you and CLOYD, which provides the
            terms of your use of our mobile application and the connected cloud
            backend services (
          </TextOpacity>
          <Bold>&#39;Software&#39;</Bold>)
          <TextOpacity>
            {" "}
            and our website (the &#39;Site&#39;). “You” and “your” refers to the
            individual or employee of a company who has the right to enter into
            this Agreement on behalf of the company who wishes to use the
            Software or Site. By using the Site or installing and/or using the
            Software, you are agreeing to all the terms and conditions stated in
            this Agreement.{" "}
          </TextOpacity>
          <Bold>
            If you do not agree to these Terms, please do not use the Sites or
            Software.
          </Bold>
        </TextWithoutOpacity>
        <Title>User Accounts</Title>
        <Text>
          When You create an account with Us, You must provide Us information
          that is accurate, complete, and current at all times. Failure to do so
          constitutes a breach of the Terms, which may result in immediate
          termination of Your account on Our Service.
          <br />
          <br />
          You are responsible for safeguarding the password that You use to
          access the Service and for any activities or actions under Your
          password, whether Your password is with Our Service or a Third-Party
          Social Media Service.
          <br />
          <br />
          You agree not to disclose Your password to any third party. You must
          notify Us immediately upon becoming aware of any breach of security or
          unauthorized use of Your account.
          <br />
          <br />
          You may not use as a username the name of another person or entity or
          that is not lawfully available for use, a name or trademark that is
          subject to any rights of another person or entity other than You
          without appropriate authorization, or a name that is otherwise
          offensive, vulgar or obscene.
        </Text>
        <Title>License:</Title>
        <Text>
          Our Site and Software contain material which is owned by or licensed
          to us. Any reproduction is prohibited. You can run the Software and
          display the Content on your mobile device solely for your personal,
          non-commercial use.
        </Text>
        <Title>Use of the Site or Software:</Title>
        <Text>
          The Software allows you to access visual instructions and other
          content (&#39; Content&#39; ) from CLOYD and to store information
          about services or products you have used and/or installed. Use of the
          Site or Software and features therein are at your own risk. The Site,
          Software and Content do not replace product provider instructions,
          disclaimers or warnings. Please review all product documentation and
          packaging information carefully before use.
          <br />
          <br />
          You assume all risks associated with the use of the Site, Software and
          Content, including without limitation risks relating to quality,
          availability, safety, performance, data loss and utility in a
          production environment. You confirm that: you will not use the Site,
          Software and Content for any purposes prohibited by US and EU law.
        </Text>
        <Title>Intellectual Property:</Title>
        <Text>
          CLOYD and its licensors retain all ownership and intellectual property
          rights in the Site, the Software and Content. You may not:
        </Text>
        <br />
        <br />
        <Ul>
          <Li>
            1) remove or modify any marks or proprietary notices of CLOYD;
          </Li>
          <Li>
            2) provide or make the Site, the Software or Content available to
            any third party;
          </Li>
          <Li>
            3) use the Site, the Software or Content to provide third party
            training for CLOYD products;
          </Li>
          <Li>
            4) transfer your access to the Site, the Software or Content, or any
            interest in them, to another individual or entity;
          </Li>
          <Li>
            5) create derivative works based on the Site, Software or Content;
          </Li>
          <Li>
            6) use any CLOYD or product provider&#39;s name, trademark or logo.
          </Li>
        </Ul>
        <Title>External Website Links:</Title>
        <Text>
          From time to time this Site may also include links to other websites.
          These links are provided for your convenience to provide further
          information. They do not signify that we endorse the website(s). We
          have no responsibility for the content of the linked website(s).
        </Text>
        <Title>Term and Termination:</Title>
        <Text>
          You may terminate this Agreement by destroying all copies of the
          Software on your mobile or smart device. In case of termination or
          expiration of this Agreement, you must destroy all copies of the
          Software on your mobile device.
        </Text>
        <Title>Privacy:</Title>
        <Text>
          Your privacy is important. Please read our Privacy Policy to learn
          about our privacy practices and how we use the information we collect.
        </Text>
      </Section>
    </SectionLayout>
  );
};

const Section = styled.div`
  max-width: 951px;
  margin: 0 auto;
`;

const Text = styled.p`
  ${({ theme }) => theme.typography.paragraph_1_regular};

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.paragraph_1_regular_mobile};
  }
`;

const TextWithoutOpacity = styled(Text)`
  opacity: 1;
`;

const TextOpacity = styled.span`
  opacity: 0.7;
`;

const Bold = styled.b`
  font-weight: 700;
  opacity: 1;
`;

const Ul = styled.ul`
  ${({ theme }) => theme.typography.paragraph_1_regular};

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.paragraph_1_regular_mobile};
  }
`;

const Li = styled.li``;

const Title = styled.h2`
  margin-top: 44px;
  margin-bottom: 26px;
  ${({ theme }) => theme.typography.paragraph_2_regular};

  @media ${device.tablet} {
    ${({ theme }) => theme.typography.paragraph_2_regular_tablet};
  }

  @media ${device.mobileL} {
    margin-top: 36px;
    margin-bottom: 17px;
    ${({ theme }) => theme.typography.paragraph_2_regular_mobile};
  }
`;
