import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ArrowLink } from "../../../assets/icons";
import { device } from "../../../assets/themes/breakpoints";
import { useMouseDownOutside } from "../../../utils/useMouseDownOutside";
import { RoutePaths } from "../../../interfaces/routesPaths";

interface LinksProps {
  title: string;
  links: {
    title: string;
    link: string;
  }[];
}

export const Links = ({ links, title }: LinksProps) => {
  const [initialLinksHeight, setInitialLinksHeight] = useState(0);
  const [isOpen, setIsOpen] = useState(true);

  const ref = useRef<HTMLDivElement>(null);

  const linksRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (linksRef.current) {
      setInitialLinksHeight(linksRef.current.clientHeight);
    }
  }, []);

  useEffect(() => {
    if (initialLinksHeight) {
      setIsOpen(false);
    }
  }, [initialLinksHeight]);

  const switchMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useMouseDownOutside(ref, closeMenu);

  return (
    <LinkGroupWrapper ref={ref}>
      <LinkTitleWrapper onClick={switchMenu}>
        <LinksTitle>{title}</LinksTitle>
        <Arrow $isOpen={isOpen} />
      </LinkTitleWrapper>
      <LinksWrapper
        ref={linksRef}
        $isOpen={isOpen}
        $initialLinksHeight={initialLinksHeight}
      >
        {links.map((link, index) =>
          link.link !== RoutePaths.EMPTY.path ? (
            <Link key={index} href={link.link}>
              {link.title}
            </Link>
          ) : (
            <DisabledLink key={index}>{link.title}</DisabledLink>
          )
        )}
      </LinksWrapper>
      <Underline />
    </LinkGroupWrapper>
  );
};

const LinkGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-right: 69px;

  @media ${device.tabletL} {
    margin-top: 13px;
    margin-right: 0;
    row-gap: 0;
  }
`;

const LinksTitle = styled.h3`
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  user-select: none;

  color: #1d2d44;

  @media ${device.mobileL} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const Link = styled.a`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #1d2d44;

  &:hover {
    font-weight: 700;
    letter-spacing: -0.35px;
  }

  @media ${device.tabletL} {
    margin-bottom: 12px;
  }

  @media ${device.mobileL} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const DisabledLink = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #1d2d44;
  cursor: not-allowed;
  user-select: none;

  @media ${device.tabletL} {
    margin-bottom: 12px;
  }

  @media ${device.mobileL} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const Underline = styled.div`
  display: none;
  height: 1px;
  background-color: black;
  opacity: 0.1;

  @media ${device.tabletL} {
    display: block;
  }
`;

const LinkTitleWrapper = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const Arrow = styled(ArrowLink)<{ $isOpen: boolean }>`
  display: none;
  margin-left: 8px;
  transform: rotate(${({ $isOpen }) => ($isOpen ? "180deg" : "0deg")});
  transition: all 0.3s ease-in-out;

  @media ${device.tabletL} {
    display: block;
  }
`;

const LinksWrapper = styled.div<{
  $isOpen: boolean;
  $initialLinksHeight: number;
}>`
  display: flex;

  overflow: hidden;
  transition: all 0.3s ease-in-out;
  flex-direction: column;
  row-gap: 12px;

  @media ${device.tabletL} {
    height: ${({ $isOpen, $initialLinksHeight }) =>
      $isOpen ? ($initialLinksHeight ? $initialLinksHeight : "auto") : 0}px;

    row-gap: 0;
  }
`;
