import styled from "styled-components";
import { device } from "../../../../assets/themes/breakpoints";
import { SectionLayout } from "../../../../layouts/SectionLayout";
import { LeftSide, RightSide } from "./components";

export const SecondSection = () => {
  return (
    <SectionLayout>
      <Section>
        <LeftSide />
        <RightSide />
      </Section>
    </SectionLayout>
  );
};

const Section = styled.div`
  margin-top: 160px;
  display: flex;
  gap: 146px;

  @media ${device.laptopL} {
    gap: 80px;
  }

  @media ${device.laptop} {
    column-gap: 60px;
    flex-direction: column;
    align-items: center;
    gap: 80px;
  }

  @media ${device.mobileL} {
    margin-top: 70px;
    gap: 40px;
  }
`;
