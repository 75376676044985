import { useEffect } from "react";

import gsap from "gsap";

interface UseAnimationsProps {
  wrapperRef: React.RefObject<HTMLDivElement>;
  imageWrapperRef: React.RefObject<HTMLDivElement>;
  image1Ref: React.RefObject<HTMLDivElement>;
  image2Ref: React.RefObject<HTMLDivElement>;
  image3Ref: React.RefObject<HTMLDivElement>;
  image4Ref: React.RefObject<HTMLDivElement>;
  image5Ref: React.RefObject<HTMLDivElement>;
  image6Ref: React.RefObject<HTMLDivElement>;
  image7Ref: React.RefObject<HTMLDivElement>;
}

export const useAnimations = ({
  wrapperRef,
  imageWrapperRef,
  image1Ref,
  image2Ref,
  image3Ref,
  image4Ref,
  image5Ref,
  image6Ref,
  image7Ref,
}: UseAnimationsProps) => {
  useEffect(() => {
    if (!imageWrapperRef.current || !wrapperRef.current) return;
    const element = imageWrapperRef.current;

    const image1 = image1Ref.current;
    const image2 = image2Ref.current;
    const image3 = image3Ref.current;
    const image4 = image4Ref.current;
    const image5 = image5Ref.current;
    const image6 = image6Ref.current;
    const image7 = image7Ref.current;

    if (
      !image1 ||
      !image2 ||
      !image3 ||
      !image4 ||
      !image5 ||
      !image6 ||
      !image7
    )
      return;

    gsap.fromTo(
      element,
      {
        rotateX: 12,
        rotateY: -12,
        rotateZ: 12,
      },
      {
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0,
        duration: 1,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "top center",
          end: "bottom top",
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      image1,
      {
        blur: 0,
        scale: 1,
      },
      {
        blur: 20,
        scale: 0.8,
        duration: 1,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "+12% center",
          end: "+18% center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      image2,
      {
        right: "-100%",
      },
      {
        right: 0,
        duration: 1,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "+12% center",
          end: "+18% center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      image2,
      {
        blur: 0,
        scale: 1,
      },
      {
        blur: 20,
        scale: 0.8,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "+26% center",
          end: "+32% center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      image3,
      {
        right: "-100%",
      },
      {
        right: 0,
        duration: 1,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "+26% center",
          end: "+32% center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      image3,
      {
        blur: 0,
        scale: 1,
      },
      {
        blur: 20,
        scale: 0.8,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "+40% center",
          end: "+46% center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      image4,
      {
        right: "-100%",
      },
      {
        right: 0,
        duration: 1,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "+40% center",
          end: "+46% center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      image4,
      {
        blur: 0,
        scale: 1,
      },
      {
        blur: 20,
        scale: 0.8,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "+54% center",
          end: "+60% center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      image5,
      {
        right: "-100%",
      },
      {
        right: 0,
        duration: 1,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "+54% center",
          end: "+60% center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      image5,
      {
        blur: 0,
        scale: 1,
      },
      {
        blur: 20,
        scale: 0.8,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "+68% center",
          end: "+74% center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      image6,
      {
        right: "-100%",
      },
      {
        right: 0,
        duration: 1,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "+68% center",
          end: "+74% center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      image6,
      {
        blur: 0,
        scale: 1,
      },
      {
        blur: 20,
        scale: 0.8,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "+82% center",
          end: "+86% center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      image7,
      {
        right: "-100%",
      },
      {
        right: 0,
        duration: 1,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "+82% center",
          end: "+86% center",
          scrub: true,
        },
      }
    );
  }, [imageWrapperRef, wrapperRef]);
};
