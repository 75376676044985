import styled from "styled-components";
import { device } from "../../assets/themes/breakpoints";
import { Tag } from "../Tag/Tag";
import { SecondSectionDecor } from "../../assets/icons";
import { useEffect, useRef } from "react";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

interface InformationBlockProps {
  tag?: string;
  title: string;
  highlightedText?: string;
  text: string;
  text2?: string;
  imageSrc: string;
  isSecondVariant?: boolean;
  banImageRotateOnMobile?: boolean;
}

export const InformationBlock = ({
  highlightedText,
  tag,
  text,
  text2,
  title,
  imageSrc,
  isSecondVariant,
  banImageRotateOnMobile = false,
}: InformationBlockProps) => {
  const refWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!refWrapper.current) return;

    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 1000);

    const element = refWrapper.current;

    ScrollTrigger.create({
      once: true,
      trigger: element,
      onEnter: () => {
        gsap.fromTo(
          element,
          {
            opacity: 0,
            y: 50,
          },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            delay: 0.5,
          }
        );
      },
    });

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, [refWrapper]);

  return (
    <Wrapper ref={refWrapper}>
      <ImageWrapper>
        <BackgroundImageWrapper>
          <BackgroundImage $isSecondVariant={isSecondVariant} />
        </BackgroundImageWrapper>
        <Image $banRotate={banImageRotateOnMobile} src={imageSrc} />
      </ImageWrapper>
      <InfoWrapper>
        {tag && <Tag tagName={tag} />}
        <Title>
          {title} <HighlightedText>{highlightedText}</HighlightedText>
        </Title>
        <Text>{text}</Text>
        {text2 && (
          <>
            <Text>{text2}</Text>
          </>
        )}
      </InfoWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 586px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${device.laptop} {
    max-width: none;
  }
`;

const Title = styled.h3`
  margin-top: 15px;
  font-weight: 700;
  font-size: 58px;
  line-height: 65px;

  letter-spacing: -0.04em;

  color: #1d2d44;

  @media ${device.mobileL} {
    margin-top: 36px;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
  }
`;

const HighlightedText = styled.span`
  opacity: 0.5;
`;

const Text = styled.p`
  margin-top: 28px;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: ${({ theme }) => theme.colors.primary};
  opacity: 0.7;

  @media ${device.mobileL} {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
  }
`;

const Image = styled.img<{ $banRotate?: boolean }>`
  filter: drop-shadow(0px 14px 44px rgba(0, 0, 0, 0.12));
  border-radius: 15px;
  transform: ${({ $banRotate }) => ($banRotate ? "none" : "rotate(-4deg)")};

  width: 100%;
  height: 100%;
  max-width: 700px;
`;

const ImageWrapper = styled.div`
  position: relative;
  margin-bottom: 50px;
  display: none;
  width: 100%;

  @media ${device.laptop} {
    display: block;
    margin-bottom: 50px;
  }

  @media ${device.mobileL} {
    margin-bottom: 36px;
  }
`;

const BackgroundImage = styled(SecondSectionDecor)<{
  $isSecondVariant?: boolean;
}>`
  position: absolute;
  top: 50%;
  left: ${({ $isSecondVariant }) => ($isSecondVariant ? "auto" : "-116px")};
  right: ${({ $isSecondVariant }) => ($isSecondVariant ? "-116px" : "auto")};
  transform: translateY(-50%)
    ${({ $isSecondVariant }) => ($isSecondVariant ? "rotate(120deg)" : "")};
  max-width: 85%;
`;

const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: -50%;

  padding-top: 100%;
  overflow: hidden;

  left: -90px;
  right: -90px;

  @media ${device.laptopL} {
    left: -40px;
    right: -40px;
  }

  @media ${device.mobileL} {
    left: -16px;
    right: -16px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 2;
`;
