import { useEffect, useRef } from "react";
import styled from "styled-components";
import { device } from "../../../../../assets/themes/breakpoints";

import gsap from "gsap";

export const RightSide = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const title = titleRef.current;
    const text = textRef.current;

    if (!wrapper || !title || !text) return;

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: wrapper,
        start: "top center",
        end: "bottom top",
      },
    });

    tl.fromTo(
      [title, text],
      {
        y: 30,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
        delay: 0.7,
        stagger: 0.2,
        ease: "none",
      }
    );
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <Title ref={titleRef}>
        <Highlight>What is</Highlight> immersive training?
      </Title>
      <Text ref={textRef}>
        Immersive training uses 3D, AR, and VR to simulate real-world scenarios
        and provide a safe and engaging learning environment for employees. In
        higher education, these technologies can enhance learning experiences,
        especially for abstract concepts and technical education.
        <br />
        <br />
        The COVID-19 pandemic has highlighted the need for remote training
        tools, and AR/VR can provide an immersive and flexible option. According
        to Edgar Dale's Cone of Experience, hands-on learning is more effective
        than reading or hearing information. AR/VR can simulate "what-if"
        situations for trainees to prepare them for real-life scenarios.
      </Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h2`
  max-width: 503px;
  ${({ theme }) => theme.typography.title_2_bold};

  @media ${device.laptop} {
    max-width: none;
  }

  @media ${device.tablet} {
    ${({ theme }) => theme.typography.title_2_bold_tablet};
  }

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.title_2_bold_mobile};
  }
`;

const Highlight = styled.span`
  opacity: 0.5;
`;

const Text = styled.p`
  max-width: 586px;
  margin-top: 28px;

  ${({ theme }) => theme.typography.paragraph_1_regular};

  @media ${device.laptop} {
    max-width: none;
  }

  @media ${device.mobileL} {
    margin-top: 15px;
    ${({ theme }) => theme.typography.paragraph_1_regular_mobile};
  }
`;
