export const colors = {
  primary: `#1D2D44`,
  white: `#FFFFFF`,
};

export const fontSizes = {
  mainTitle: `80px`,
  text1: `18px`,
  text2: `16px`,
};
