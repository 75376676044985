import { useEffect } from "react";
import gsap from "gsap";

interface UseAnimationProps {
  sectionRef: React.RefObject<HTMLDivElement>;
  titleRef: React.RefObject<HTMLHeadingElement>;
}

export const useAnimation = ({ sectionRef, titleRef }: UseAnimationProps) => {
  useEffect(() => {
    const section = sectionRef.current;
    const title = titleRef.current;

    if (!title || !section) return;

    const animation = gsap.fromTo(
      [title],
      {
        y: 30,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        delay: 0.5,
        duration: 0.7,
        stagger: 0.2,
      }
    );

    return () => {
      animation.progress(0).kill();
    };
  }, []);
};
