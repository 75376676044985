import { useEffect, useRef } from "react";
import styled from "styled-components";
import { SecondSectionDecor } from "../../../../../assets/icons";
import image1 from "../../../../../assets/images/industries/s2-1.png";
import image2 from "../../../../../assets/images/industries/s2-2.png";
import image3 from "../../../../../assets/images/industries/s2-3.png";
import image4 from "../../../../../assets/images/industries/s2-4.png";
import image5 from "../../../../../assets/images/industries/s2-5.png";
import image6 from "../../../../../assets/images/industries/s2-6.png";
import image7 from "../../../../../assets/images/industries/s2-7.png";
import { device } from "../../../../../assets/themes/breakpoints";

import { useAnimations } from "../hooks";

export const LeftSide = () => {
  const imageWrapperRef = useRef<HTMLImageElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const image1Ref = useRef<HTMLImageElement>(null);
  const image2Ref = useRef<HTMLImageElement>(null);
  const image3Ref = useRef<HTMLImageElement>(null);
  const image4Ref = useRef<HTMLImageElement>(null);
  const image5Ref = useRef<HTMLImageElement>(null);
  const image6Ref = useRef<HTMLImageElement>(null);
  const image7Ref = useRef<HTMLImageElement>(null);

  useAnimations({
    wrapperRef,
    imageWrapperRef,
    image1Ref,
    image2Ref,
    image3Ref,
    image4Ref,
    image5Ref,
    image6Ref,
    image7Ref,
  });

  return (
    <Wrapper ref={wrapperRef}>
      <StickyBlock>
        <BackgroundImage id={"leftSide-second-section-decor_svg__b"} />
        <PerspectiveWrapper>
          <ImageWrapper ref={imageWrapperRef}>
            <Image ref={image1Ref} src={image1} $isStatic={true} />
            <Image ref={image2Ref} src={image2} />
            <Image ref={image3Ref} src={image3} />
            <Image ref={image4Ref} src={image4} />
            <Image ref={image5Ref} src={image5} />
            <Image ref={image6Ref} src={image6} />
            <Image ref={image7Ref} src={image7} />
          </ImageWrapper>
        </PerspectiveWrapper>
      </StickyBlock>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 586px;
  @media ${device.laptopL} {
    max-width: 550px;
  }

  @media ${device.laptop} {
    display: none;
  }
`;

const StickyBlock = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 10vh;
`;

const BackgroundImage = styled(SecondSectionDecor)`
  position: absolute;
  top: 50%;
  left: -116px;
  transform: translateY(-50%);
`;

const Image = styled.img<{ $isStatic?: boolean }>`
  position: ${({ $isStatic }) => ($isStatic ? "static" : "absolute")};

  width: 100%;
  max-width: 700px;
  height: 100%;

  transform-style: preserve-3d;
`;

const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 28px;
  max-width: 100%;
  will-change: transform;
  transform-style: preserve-3d;
  height: calc(100% / 3);
  overflow: hidden;
  background-color: #e1e6ea;
  box-shadow: 10px 54px 84px rgba(0, 0, 0, 0.12);
  line-height: 0;
`;

const PerspectiveWrapper = styled.div`
  will-change: transform;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(6.99776deg)
    rotateY(-6.99776deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
`;
