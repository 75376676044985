import { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import { device } from "../../../../../assets/themes/breakpoints";
import { LinkWithArrow } from "../../../../../ui";
import { CircleLink } from "../../../../../ui/CircleLink/CircleLink";

import gsap from "gsap";
import { RoutePaths } from "../../../../../interfaces/routesPaths";

const getFromToAnimation = (delay: number) => {
  return {
    from: {
      y: 50,
      opacity: 0,
    },
    to: {
      y: 0,
      opacity: 1,
      delay,
      duration: 0.7,
    },
  };
};

export const MainInfo = () => {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const captureRef = useRef<HTMLDivElement>(null);
  const linkWrapperRef = useRef<HTMLDivElement>(null);
  const circleLinksWrapperRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!titleRef) return;

    const timeStep = 0.25;

    const tl = gsap.timeline();

    tl.fromTo(
      titleRef.current,
      getFromToAnimation(1).from,
      getFromToAnimation(1).to
    );

    tl.fromTo(
      captureRef.current,
      getFromToAnimation(timeStep).from,
      getFromToAnimation(timeStep).to,
      "<"
    );

    tl.fromTo(
      linkWrapperRef.current,
      getFromToAnimation(timeStep).from,
      getFromToAnimation(timeStep).to,
      "<"
    );

    tl.fromTo(
      circleLinksWrapperRef.current,
      getFromToAnimation(timeStep).from,
      getFromToAnimation(timeStep).to,
      "<"
    );

    return () => {
      tl.kill();
    };
  }, [titleRef]);
  return (
    <Container>
      <Title ref={titleRef}>Coming soon... It’s in development</Title>
      <Capture ref={captureRef}>
        This page doesn’t exist yet. It’s under development process and will be
        coming soon.
      </Capture>
      <LinkWrapper ref={linkWrapperRef}>
        <LinkWithArrow
          text={"Go back to home"}
          linkType="dark"
          href={RoutePaths.BASE_PATH.path}
        />
      </LinkWrapper>
      <CircleLinksWrapper ref={circleLinksWrapperRef}>
        <CircleLink type="LinkedIn" />
        <CircleLink type="YouTube" />
        <CircleLink type="Facebook" />
        <CircleLink type="Instagram" />
      </CircleLinksWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 709px;
  margin-top: 260px;

  @media ${device.laptopL} {
    margin-top: 150px;
    max-width: none;
  }

  @media ${device.mobileL} {
    margin-top: calc(78px + 21px);
  }
`;

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.mainTitle};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;
  line-height: 90px;
  letter-spacing: -0.04em;

  @media ${device.laptopL} {
    max-width: 900px;
  }

  @media ${device.mobileL} {
    font-size: 40px;
    line-height: 48px;
  }
`;

const Highlight = styled.span`
  opacity: 0.5;
`;

const Capture = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.text1};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;
  margin-top: 23px;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: rgba(29, 45, 68, 0.7);

  max-width: 464px;

  @media ${device.laptopL} {
    max-width: 60%;
    margin-top: 48px;
  }

  @media ${device.tablet} {
    max-width: 464px;
  }

  @media ${device.mobileL} {
    max-width: none;
    margin-top: 17px;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 48px;
  flex-wrap: wrap;

  @media ${device.mobileL} {
    margin-top: 26px;
  }
`;

const CircleLinksWrapper = styled.div`
  display: flex;
  column-gap: 14px;
  margin-top: 48px;

  @media ${device.mobileL} {
    margin-top: 26px;
  }
`;
