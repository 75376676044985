import { useRef, useState } from "react";
import styled from "styled-components";
import { device } from "../../assets/themes/breakpoints";

import { PauseVideoIcon, PlayVideoTriangle } from "../../assets/icons";

interface VideoPlayerProps {
  src: string;
  poster?: string;
}

export const VideoPlayer = ({ src, poster }: VideoPlayerProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);

  const videoRef = useRef<HTMLVideoElement>(null);

  const togglePlay = () => {
    if (!videoRef.current) {
      return;
    }
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleProgress = () => {
    if (!videoRef.current) return;

    const duration = videoRef.current.duration;

    const currentTime = videoRef.current.currentTime;
    const progress = (currentTime / duration) * 100;
    setProgress(progress);
  };

  return (
    <VideoWrapper onClick={togglePlay}>
      <Video
        onTimeUpdate={handleProgress}
        ref={videoRef}
        poster={poster}
        controls={isPlaying}
      >
        <source src={src} type="video/mp4" />
      </Video>

      {!isPlaying && (
        <ControlWrapper $isPlaying={isPlaying}>
          <CompanyVideoButtonWrapper>
            <CompanyVideoButton $isPlaying={isPlaying}>
              {isPlaying ? (
                <PauseIcon fill="white" />
              ) : (
                <PlayIcon fill="white" />
              )}
            </CompanyVideoButton>
          </CompanyVideoButtonWrapper>
        </ControlWrapper>
      )}
    </VideoWrapper>
  );
};

const Video = styled.video`
  width: 100%;
  height: 100%;

  object-fit: cover;
`;

const Progress = styled.progress`
  position: absolute;
  opacity: 0;
  height: 3px;
  bottom: 25px;
  width: calc(100% - 50px);
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);

  border-radius: 10px;
  overflow: hidden;

  background-color: #000;

  &::-webkit-progress-bar {
    background-color: #000;
  }

  &::-webkit-progress-value {
    background-color: #fff;
  }

  &::-moz-progress-bar {
    background-color: #fff;
  }

  &::-ms-fill {
    background-color: #fff;
  }

  &::-ms-fill-lower {
    background-color: #000;
  }

  &::-ms-fill-upper {
    background-color: #fff;
  }
`;

const CompanyVideoButton = styled.div<{ $isPlaying: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  border: 1px solid #ffffff;
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: ${({ $isPlaying }) => ($isPlaying ? 0 : 1)};

  color: #ffffff;

  @media ${device.laptop} {
    width: 64px;
    height: 64px;
  }

  @media ${device.mobileL} {
    width: 48px;
    height: 48px;
    font-size: 14px;
    line-height: 18px;
  }
`;

const PlayIcon = styled(PlayVideoTriangle)`
  transform: translateX(3px);

  @media ${device.laptop} {
    transform: scale(0.8) translateX(3px);
  }

  @media ${device.mobileL} {
    transform: scale(0.6) translateX(3px);
  }
`;

const PauseIcon = styled(PauseVideoIcon)`
  @media ${device.laptop} {
    transform: scale(0.8);
  }

  @media ${device.mobileL} {
    transform: scale(0.6);
  }
`;

const CompanyVideoButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;

  cursor: pointer;
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    transform: scale(1.07);
    & > ${CompanyVideoButton} {
      @media (pointer: fine) {
        border-width: 1px;
        box-shadow: 0px 6px 15px rgba(255, 255, 255, 0.1);
      }
    }
  }
`;

const VideoWrapper = styled.button`
  position: relative;
  overflow: hidden;
  border-radius: 30px;
  width: 100%;
  height: 100%;
  line-height: 0;
`;

const ControlWrapper = styled.div<{ $isPlaying: boolean }>`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);

  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);

    & > ${Progress} {
      opacity: ${({ $isPlaying }) => ($isPlaying ? 1 : 0)};
    }

    & > ${CompanyVideoButtonWrapper} {
      transform: scale(1.07);
      & > ${CompanyVideoButton} {
        border-width: 1px;
        box-shadow: 0px 6px 15px rgba(255, 255, 255, 0.1);
        opacity: 1;

        & > ${PlayIcon} {
          transform: scale(1.2) translateX(3px);

          @media ${device.laptop} {
            transform: scale(1) translateX(3px);
          }

          @media ${device.mobileL} {
            transform: scale(0.8) translateX(3px);
          }
        }

        & > ${PauseIcon} {
          transform: scale(1.2);

          @media ${device.laptop} {
            transform: scale(1);
          }

          @media ${device.mobileL} {
            transform: scale(0.8);
          }
        }
      }
    }
  }
`;
