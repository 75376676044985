import styled from "styled-components";
import AppleLogo from "../../assets/icons/AppleLogo";
import GooglePlayLogo from "../../assets/icons/GooglePlayLogo";
import OculusLogo from "../../assets/icons/OculusLogo";
import { device } from "../../assets/themes/breakpoints";

type LinkType = "App Store" | "Google Play" | "Oculus";

const Logo = ({ type }: { type: LinkType }) => {
  switch (type) {
    case "App Store":
      return <AppleLogo />;
    case "Google Play":
      return <GooglePlayLogo />;
    case "Oculus":
      return <OculusLogo />;
    default:
      return <AppleLogo />;
  }
};

const Links = [
  {
    marketPlaceName: "App Store" as LinkType,
    href: "https://apps.apple.com/lt/app/cloyd-ixr/id6443696242",
  },
  {
    marketPlaceName: "Google Play" as LinkType,
    href: "https://play.google.com/store/apps/details?id=com.cloydxr.cloyd",
  },
  {
    marketPlaceName: "Oculus" as LinkType,
    href: "https://www.oculus.com/experiences/quest/8159897057384080/",
  },
];

interface LinkToDownloadProps {
  marketPlaceName: LinkType;
  className?: string;
}

export const LinkToDownload = ({
  marketPlaceName,
  className,
}: LinkToDownloadProps) => {
  return (
    <Link
      href={
        Links.find((link) => link.marketPlaceName === marketPlaceName)?.href ||
        ""
      }
      target="_blank"
      className={className}
    >
      <LogoContainer>
        <Logo type={marketPlaceName} />
      </LogoContainer>
      <TextContainer>
        <Text>Download on the</Text>
        <MarketPlaceName>{marketPlaceName}</MarketPlaceName>
      </TextContainer>
    </Link>
  );
};

const Link = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 16px 10px 65px;
  background: #1d2d44;
  border-radius: 10px;
  cursor: pointer;
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
    transform: scale(1.07);
  }

  @media ${device.mobileL} {
    padding: 8px 12px 9px 50px;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 16px;
  line-height: 0;

  & svg {
    max-width: 100%;
  }

  @media ${device.mobileL} {
    left: 9px;
    max-width: 34px;
  }
`;

const TextContainer = styled.div``;

const Text = styled.p`
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizes.text2};
  line-height: 21px;
  letter-spacing: -0.02em;

  color: white;

  @media ${device.mobileL} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const MarketPlaceName = styled(Text)`
  font-weight: 700;
`;
