import fonts from "../fonts/fonts";
import { colors, fontSizes } from "./constants";
import { typography } from "./typography";

export const theme = {
  colors: colors,
  fonts: fonts,
  fontSizes: fontSizes,
  typography: typography,
};

export { GlobalStyle } from "./globalStyles";
