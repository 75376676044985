import { useEffect, useRef } from "react";
import styled from "styled-components";
import { device } from "../../../../../assets/themes/breakpoints";
import { Form } from "./Form";

import gsap from "gsap";

export const RightSide = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (!wrapper) return;

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: wrapper,
        start: "top center",
        end: "bottom top",
      },
    });

    tl.fromTo(
      [wrapper],
      {
        x: 30,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.5,
        stagger: 0.2,
        ease: "none",
      }
    );
  }, []);
  return (
    <Wrapper ref={wrapperRef}>
      <Title>
        We will do our best to give you the answers{" "}
        <Highlight>you're looking for.</Highlight>
      </Title>
      <Text>Get in touch for prompt, helpful support. Thank you!</Text>
      <Form />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 53px 57px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 30px;

  @media ${device.tablet} {
    padding: 30px;
  }
`;

const Title = styled.h2`
  ${({ theme }) => theme.typography.title_2_bold};

  @media ${device.tablet} {
    ${({ theme }) => theme.typography.title_2_bold_tablet};
  }

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.title_2_bold_mobile};
  }
`;

const Highlight = styled.span`
  opacity: 0.5;
`;

const Text = styled.p`
  ${({ theme }) => theme.typography.paragraph_3_regular};
  margin-top: 28px;

  @media ${device.mobileL} {
    margin-top: 19px;
    ${({ theme }) => theme.typography.paragraph_3_regular_mobile};
  }
`;
