import styled from "styled-components";
import { device } from "../../../../assets/themes/breakpoints";

import { SectionLayout } from "../../../../layouts/SectionLayout";
import { QuestionSection } from "./components/QuestionSection";
import { QUESTIONS } from "./constants/questions";

export const SecondSection = () => {
  return (
    <SectionLayout
      topMargins={{
        desktop: 119,
        tablet: 100,
        mobile: 64,
      }}
    >
      <Section>
        {QUESTIONS.map((question, index) => (
          <QuestionSection key={index} {...question} />
        ))}
      </Section>
    </SectionLayout>
  );
};

const Section = styled.div`
  margin-top: 187px;
  display: flex;
  row-gap: 24px;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    row-gap: 20px;
    margin-top: 120px;
  }

  @media ${device.mobileL} {
    row-gap: 16px;
    margin-top: 83px;
  }
`;
