import styled from "styled-components";
import { device } from "../../../../../assets/themes/breakpoints";

import gsap from "gsap";
import { useEffect, useRef } from "react";

interface CardWithImageProps {
  image: string;
  title: string;
}

export const CardWithImage = ({ image, title }: CardWithImageProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLParagraphElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const title = titleRef.current;
    const image = imageRef.current;

    gsap.fromTo(
      wrapper,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.5,
        scrollTrigger: {
          trigger: wrapper,
          start: "top 60%",
        },
      }
    );

    gsap.fromTo(
      title,
      {
        opacity: 0,
      },
      {
        opacity: 1,

        duration: 0.5,
        scrollTrigger: {
          trigger: title,
          start: "top 60%",
        },
      }
    );

    gsap.fromTo(
      image,
      {
        opacity: 0,
        x: 20,
      },
      {
        opacity: 1,
        x: 0,
        duration: 0.5,
        scrollTrigger: {
          trigger: image,
          start: "top 60%",
        },
      }
    );

    return () => {
      gsap.killTweensOf(wrapper);
      gsap.killTweensOf(title);
      gsap.killTweensOf(image);
    };
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <Title ref={titleRef}>{title}</Title>
      <ImageWrapper ref={imageRef}>
        <Image src={image} />
      </ImageWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 37px;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.04);
  gap: 42px;
  align-items: center;

  user-select: none;

  @media ${device.tablet} {
    padding: 30px;
    gap: 30px;
  }

  @media ${device.mobileL} {
    padding: 17px;
    gap: 16px;
  }
`;

const Title = styled.p`
  ${({ theme }) => theme.typography.paragraph_2_regular};

  @media ${device.tablet} {
    ${({ theme }) => theme.typography.paragraph_2_regular_tablet};
    font-size: 24px;
    line-height: 29px;
  }

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.paragraph_2_regular_mobile};
    font-size: 18px;
    line-height: 20px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  padding-top: 100%;
  width: 100%;

  display: flex;
  justify-content: flex-end;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  border-radius: 22px;
  overflow: hidden;
`;
