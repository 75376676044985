import { ThemeProvider } from "styled-components";
import { GlobalStyle, theme } from "../assets/themes";

interface ThemeProps {
  children: React.ReactNode;
}

export const Theme = ({ children }: ThemeProps) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
);
