import image1 from "../../../../assets/images/s3-money.png";
import image2 from "../../../../assets/images/s3-storage.png";
import image3 from "../../../../assets/images/s3-lock-close.png";

export const CARD_DATA = [
  {
    iconSrc: image1,
    title: " Less cost",
    text: "When training workers want to act in crisis situations such as a pandemic, hiring actors and multiple trainers and finding a room to create realistic scenarios can be expensive - much more expensive than AR/VR equipment.",
  },
  {
    iconSrc: image2,
    title: "Improved data collection",
    text: "Managers and other training instructors can observe those who are successful in team leadership roles, or those who struggle to find the right language to use in tense situations. With AR/VR, the decisions made for these workers during training are based on AR/VR data, as noted in the previous example.",
  },
  {
    iconSrc: image3,
    title: "Improved safety and security",
    text: "Virtual training can prevent workplace fatalities and teach employees to handle risky situations. Simulating hazards provides a safe, realistic learning experience in challenging locations. The International Labor Organization reports 1 million workplace deaths yearly.",
  },
];
