import { colors } from "./constants";

export const typography = {
  title_1_bold: {
    fontWeight: 700,
    fontSize: "80px",
    lineHeight: "90px",
    letterSpacing: "-0.04em",
    color: colors.primary,
  },
  title_1_bold_tablet: {
    fontWeight: 700,
    fontSize: "60px",
    lineHeight: "70px",
    letterSpacing: "-0.04em",
    color: colors.primary,
  },
  title_1_bold_mobile: {
    fontWeight: 700,
    fontSize: "40px",
    lineHeight: "48px",
    letterSpacing: "-0.01em",
    color: colors.primary,
  },

  title_2_bold: {
    fontWeight: 700,
    fontSize: "58px",
    lineHeight: "65px",
    letterSpacing: "-0.04em",
    color: colors.primary,
  },

  title_2_bold_tablet: {
    fontWeight: 700,
    fontSize: "48px",
    lineHeight: "55px",
    letterSpacing: "-0.04em",
    color: colors.primary,
  },

  title_2_bold_mobile: {
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "38px",
    letterSpacing: "-0.04em",
    color: colors.primary,
  },

  title_3_bold: {
    fontWeight: 700,
    fontSize: "22px",
    lineHeight: "29px",
    letterSpacing: "-0.04em",
    color: colors.primary,
  },

  title_3_bold_mobile: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "29px",
    letterSpacing: "-0.04em",
    color: colors.primary,
  },

  title_4_bold: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "21px",
    letterSpacing: "-0.04em",
    color: colors.primary,
  },

  title_4_bold_mobile: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "29px",
    letterSpacing: "-0.04em",
    color: colors.primary,
  },

  paragraph_1_regular: {
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "27px",
    opacity: 0.7,
    color: colors.primary,
  },

  paragraph_1_regular_mobile: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "23px",
    opacity: 0.7,
    color: colors.primary,
  },

  paragraph_2_regular: {
    fontWeight: 700,
    fontSize: "31px",
    lineHeight: "35px",
    letterSpacing: "-0.04em",
    color: colors.primary,
  },

  paragraph_2_regular_tablet: {
    fontWeight: 700,
    fontSize: "28px",
    lineHeight: "32px",
    letterSpacing: "-0.04em",
    color: colors.primary,
  },

  paragraph_2_regular_mobile: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "29px",
    letterSpacing: "-0.04em",
    color: colors.primary,
  },

  paragraph_3_regular: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "21px",
    opacity: 0.7,
    color: colors.primary,
  },

  paragraph_3_regular_mobile: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18px",
    opacity: 0.7,
    color: colors.primary,
  },
};
