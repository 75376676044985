import * as React from "react";
import { SVGProps } from "react";
const SvgPauseVideoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={12} width={5} height={26} rx={2.5} fill="#fff" />
    <rect width={5} height={26} rx={2.5} fill="#fff" />
  </svg>
);
export default SvgPauseVideoIcon;
