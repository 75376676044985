import * as React from "react";
import { SVGProps } from "react";
const SvgGooglePlayLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={29}
    height={33}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.54 16.281.117 30.344a3.64 3.64 0 0 0 5.331 2.16l15.104-8.601-7.012-7.622Z"
      fill="#EA4335"
    />
    <path
      d="m27.117 13.89-6.531-3.742-7.352 6.452 7.382 7.281 6.481-3.7a3.55 3.55 0 0 0 0-6.292h.02Z"
      fill="#FBBC04"
    />
    <path
      d="M.12 3.656c-.081.3-.121.61-.12.92v24.846c0 .31.041.62.12.92L14.003 16.64.12 3.656Z"
      fill="#4285F4"
    />
    <path
      d="m13.64 17 6.942-6.852L5.498 1.506a3.72 3.72 0 0 0-1.87-.51 3.64 3.64 0 0 0-3.51 2.65L13.64 17Z"
      fill="#34A853"
    />
  </svg>
);
export default SvgGooglePlayLogo;
