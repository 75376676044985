export const RoutePaths = {
  BASE_PATH: {
    title: "Main",
    path: "/",
  },
  ABOUT_CLOYD: {
    title: "About",
    path: "/about-cloyd",
  },
  CONTACT_US: {
    title: "Contact us",
    path: "/contact-us",
  },
  INDUSTRIES: {
    title: "Industries",
    path: "/industries",
  },
  MANUALS: {
    title: "Manuals",
    path: "/manuals",
  },
  IMMERSIVE_TRAINING: {
    title: "Immersive training",
    path: "/immersive-training",
  },
  IMMERSIVE_COLLABORATION: {
    title: "Immersive collaboration",
    path: "/immersive-collaboration",
  },
  CLOYD_IXR: {
    title: "Cloyd IXR",
    path: "/cloyd-ixr",
  },
  IXR_HUBS: {
    title: "IXR Hubs",
    path: "/ixr-hubs",
  },
  PRIVACY_POLICY: {
    title: "Privacy Policy",
    path: "/privacy-policy",
  },
  TERMS_AND_CONDITIONS: {
    title: "Terms & Conditions",
    path: "/terms-and-conditions",
  },
  FAQ: {
    title: "FAQ",
    path: "/faq",
  },
  CAREERS: {
    title: "Careers",
    path: "/careers",
  },
  DOWNLOAD: {
    title: "Download",
    path: "/download",
  },
  COMING_SOON: {
    title: "Coming soon",
    path: "/coming-soon",
  },
  EMPTY: {
    title: "Empty",
    path: "/empty",
  },
};
