import styled from "styled-components";
import { device } from "../../../../../assets/themes/breakpoints";

import image1 from "../../../../../assets/images/immersive-collaboration/s6-1.png";
import image2 from "../../../../../assets/images/immersive-collaboration/s6-2.png";
import { InformationBlock } from "../../../../../ui/InformationBlock";

const INFORMATION_BLOCK_DATA = [
  {
    title: "Integrating diverse perspectives",
    highlightedText: "into the project",
    text: "Creative projects require collaboration. Teams that can look at a problem from different perspectives and are more likely to find effective solutions.",
    text2:
      "By applying immersive collaboration techniques on a regular basis, you can benefit from these other opinions. For example, you can meet in small groups to learn how different departments might be affected by the decisions you make, or you can ask individual team members to hear their ideas about a problem.",
    imageSrc: image1,
  },
  {
    title: "Enhancing a sense of community",
    text: "Some companies complain about the lack of a strong sense of community in the workplace, such as among employees. Without a sense of community, employees do not feel attached to a particular work environment. Therefore, instead of moving up the career ladder, they are more likely to take advantage of various opportunities when they arise.",
    text2:
      "Immersive workplaces show employees that they are not working in a vacuum. Rather, they have the opportunity to make those crucial friendships that have been proven to increase career satisfaction.",
    imageSrc: image2,
  },
];

export const RightSide = () => {
  return (
    <Wrapper>
      {INFORMATION_BLOCK_DATA.map((data, index) => (
        <InformationBlock
          key={index}
          {...data}
          isSecondVariant={index % 2 !== 0}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 270px;
  z-index: 2;

  @media ${device.mobileL} {
    row-gap: 60px;
  }
`;
