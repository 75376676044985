import image1 from "../../../../../assets/images/manuals/s5-1.png";
import image2 from "../../../../../assets/images/manuals/s5-2.png";
import image3 from "../../../../../assets/images/manuals/s5-3.png";
import image4 from "../../../../../assets/images/manuals/s5-4.png";

export const CARD_DATA = [
  {
    title: "Training solution",
    image: image1,
  },
  {
    title: "Production and design for delivery to the suppliers",
    image: image2,
  },
  {
    title:
      "Marketing as the delivery of life-saving product information to customers",
    image: image3,
  },
  {
    title: "3D manuals can be enhanced with VR/AR technologies",
    image: image4,
  },
];
