import styled from "styled-components";
import { device } from "../../../../assets/themes/breakpoints";
import { LinkToDownload } from "../../../../ui";
import { Tag } from "../../../../ui/Tag/Tag";

import gsap from "gsap";
import { useEffect, useRef } from "react";
import { SectionLayout } from "../../../../layouts/SectionLayout";

export const FooterSection = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const title1Ref = useRef<HTMLSpanElement>(null);
  const title2Ref = useRef<HTMLSpanElement>(null);
  const title3Ref = useRef<HTMLSpanElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);
  const Link1Ref = useRef<HTMLDivElement>(null);
  const Link2Ref = useRef<HTMLDivElement>(null);
  const Link3Ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      !wrapperRef.current ||
      !title1Ref.current ||
      !title2Ref.current ||
      !title3Ref.current ||
      !textRef.current ||
      !Link1Ref.current ||
      !Link2Ref.current ||
      !Link3Ref.current
    )
      return;

    const wrapper = wrapperRef.current;
    const title1 = title1Ref.current;
    const title2 = title2Ref.current;
    const title3 = title3Ref.current;
    const text = textRef.current;
    const link1 = Link1Ref.current;
    const link2 = Link2Ref.current;
    const link3 = Link3Ref.current;

    gsap.fromTo(
      title1,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.5,
        delay: 0,
        scrollTrigger: {
          trigger: wrapper,
          start: "top center",
          end: "bottom top",
        },
      }
    );

    gsap.fromTo(
      title2,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
        delay: 0.4,
        scrollTrigger: {
          trigger: wrapper,
          start: "top center",
          end: "bottom top",
        },
      }
    );

    gsap.fromTo(
      title3,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
        delay: 0.8,
        scrollTrigger: {
          trigger: wrapper,
          start: "top center",
          end: "bottom top",
        },
      }
    );

    gsap.fromTo(
      text,
      {
        opacity: 0,
        y: 30,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.6,
        delay: 1,
        scrollTrigger: {
          trigger: wrapper,
          start: "top center",
          end: "bottom top",
        },
      }
    );

    gsap.fromTo(
      link1,
      {
        opacity: 0,
        y: 30,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.6,
        delay: 1.2,
        scrollTrigger: {
          trigger: wrapper,
          start: "top center",
          end: "bottom top",
        },
      }
    );

    gsap.fromTo(
      link2,
      {
        opacity: 0,
        y: 30,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.6,
        delay: 1.4,
        scrollTrigger: {
          trigger: wrapper,
          start: "top center",
          end: "bottom top",
        },
      }
    );

    gsap.fromTo(
      link3,
      {
        opacity: 0,
        y: 30,
      },
      {
        y: 0,
        opacity: 1,
        duration: 1,
        delay: 1.6,
        scrollTrigger: {
          trigger: wrapper,
          start: "top center",
          end: "bottom top",
        },
      }
    );
  }, []);
  return (
    <SectionLayout>
      <Section ref={wrapperRef}>
        <TextWrapper>
          <Title>
            <TitleWord ref={title1Ref}>Moving</TitleWord>{" "}
            <TitleWord ref={title2Ref}>Reality</TitleWord>{" "}
            <TitleWord ref={title3Ref}>Forward</TitleWord>
          </Title>
          <Text ref={textRef}>
            Available for Android, iOS, browser and Oculus
          </Text>
        </TextWrapper>
        <LinksWrapper>
          <Link ref={Link1Ref}>
            <Tag tagName="for iOS" />
            <LinkToDownload marketPlaceName="App Store" />
          </Link>
          <Link ref={Link2Ref}>
            <Tag tagName="for Android" />
            <LinkToDownload marketPlaceName="Google Play" />
          </Link>
          <Link ref={Link3Ref}>
            <Tag tagName="for Oculus" />
            <LinkToDownload marketPlaceName="Oculus" />
          </Link>
        </LinksWrapper>
      </Section>
    </SectionLayout>
  );
};

const Section = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 153px;
  max-width: 1250px;

  @media ${device.laptopL} {
    flex-direction: column;
    align-items: flex-start;
  }

  @media ${device.tablet} {
    margin-top: 100px;
  }

  @media ${device.mobileL} {
    margin-top: 61px;
    margin-bottom: 68px;
  }
`;

const TextWrapper = styled.div`
  max-width: 468px;

  @media ${device.laptopL} {
    max-width: none;
  }
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 58px;
  line-height: 65px;
  letter-spacing: -0.04em;

  color: #1d2d44;

  @media ${device.mobileL} {
    font-size: 32px;
    line-height: 38px;
  }
`;

const TitleWord = styled.span`
  display: inline-block;
`;

const Text = styled.p`
  margin-top: 28px;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  opacity: 0.7;

  color: #1d2d44;

  @media ${device.mobileL} {
    margin-top: 15px;
    font-size: 16px;
    line-height: 23px;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media ${device.laptopL} {
    margin-top: 35px;
  }

  @media ${device.tablet} {
    gap: 16px;
  }
`;

const Link = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 17px;
`;
