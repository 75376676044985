import styled from "styled-components";
import { device } from "../../../../assets/themes/breakpoints";
import { SectionLayout } from "../../../../layouts/SectionLayout";
import { LeftSide, RightSide } from "./components";

export const SecondSection = () => {
  return (
    <SectionLayout
      topMargins={{
        desktop: 144,
        tablet: 100,
        mobile: 64,
      }}
    >
      <Section>
        <LeftSide />
        <RightSide />
      </Section>
    </SectionLayout>
  );
};

const Section = styled.div`
  display: flex;
  gap: 24px;

  @media ${device.laptop} {
    flex-direction: column;
    gap: 16px;
  }
`;
