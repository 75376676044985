import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { SectionLayout } from "../../../../layouts/SectionLayout";
import posterImage from "../../../../assets/images/manuals/manuals-video-poster.png";

import gsap from "gsap";
import { VideoPlayer } from "../../../../ui";

export const SecondSection = () => {
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const section = sectionRef.current;

    if (!section) return;

    gsap.fromTo(
      section,
      {
        opacity: 0,
        y: -25,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: section,
          start: "top center",
        },
      }
    );

    return () => {
      gsap.killTweensOf(section);
    };
  }, []);

  return (
    <SectionLayout
      topMargins={{
        desktop: 144,
        tablet: 100,
        mobile: 70,
      }}
    >
      <Section ref={sectionRef}>
        <VideoPlayer
          src="/resources/video/3d-manuals.mp4"
          poster={posterImage}
        />
      </Section>
    </SectionLayout>
  );
};

const Section = styled.div``;
