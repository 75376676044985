import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { ArrowLink } from "../../../assets/icons";
import { device } from "../../../assets/themes/breakpoints";
import { useMouseDownOutside } from "../../../utils/useMouseDownOutside";

interface SubMenuProps {
  title: string;
  menu: {
    title: string;
    link: string;
  }[];
  animationDelay: number;
  closeHeaderMenu: () => void;
}

export const SubMenu = ({
  title,
  menu,
  animationDelay,
  closeHeaderMenu,
}: SubMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [initialHeight, setInitialHeight] = useState(0);

  const ref = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const getInitialHeight = () => {
    if (menuRef.current) {
      const height = menuRef.current.clientHeight;
      setInitialHeight(height);
    }
  };

  useEffect(() => {
    if (initialHeight) {
      setIsOpen(false);
    }
    getInitialHeight();
  }, [menuRef]);

  const switchMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    if (!initialHeight) return;
    setIsOpen(false);
  };

  const onLinkClick = () => {
    closeMenu();
    closeHeaderMenu();
  };

  useMouseDownOutside(ref, closeMenu);

  return (
    <WrapperButton ref={ref}>
      <Button
        $animationDelay={animationDelay}
        onClick={switchMenu}
        $isOpen={isOpen}
      >
        {title} <Arrow $isOpen={isOpen} />
      </Button>
      <Menu ref={menuRef} $isOpen={isOpen} $initialHeight={initialHeight}>
        {menu.map((subItem, index) => (
          <InternalLink key={index} to={subItem.link} onClick={onLinkClick}>
            {subItem.title}
          </InternalLink>
        ))}
      </Menu>
    </WrapperButton>
  );
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const InternalLink = styled(Link)`
  font-size: 16px;
  line-height: 21px;
  user-select: none;

  cursor: pointer;

  &:hover {
    letter-spacing: -0.4px;
    font-weight: bold;
  }

  @media ${device.laptopL} {
    font-size: 20px;
    line-height: 26px;
  }

  color: #1d2d44;
`;

const WrapperButton = styled.div`
  position: relative;
  z-index: 2;
`;

const Menu = styled.div<{ $isOpen: boolean; $initialHeight: number }>`
  position: absolute;
  left: 0px;
  top: calc(100% + 9px);
  padding: 18px 23px 20px 18px;
  background: #ffffff;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 240px;
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  z-index: 1;
  opacity: ${({ $isOpen }) => ($isOpen ? "1" : "0")};
  visibility: ${({ $isOpen }) => ($isOpen ? "visible" : "hidden")};
  transition: all 0.3s ease-in-out;
  transform: translateY(${({ $isOpen }) => ($isOpen ? "0" : "-10px")});

  @media ${device.laptopL} {
    position: relative;
    top: 0;
    padding: 0;
    transform: none;
    opacity: 1;
    box-shadow: none;
    background: none;
    margin: ${({ $isOpen }) => ($isOpen ? "34px 0 10px 14px;" : "0 0 0 14px")};
    height: ${({ $isOpen, $initialHeight }) =>
      $initialHeight ? ($isOpen ? `${$initialHeight}px` : "0") : "auto"};
    overflow: hidden;
  }
`;

const Button = styled.button<{ $isOpen: boolean; $animationDelay: number }>`
  display: flex;
  align-items: center;
  font-weight: ${({ $isOpen }) => ($isOpen ? "700" : "400")};
  letter-spacing: ${({ $isOpen }) => ($isOpen ? "-0.4px" : "0")};
  font-size: 16px;
  line-height: 21px;
  animation: ${fadeIn} 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    ${({ $animationDelay }) => $animationDelay}s both;
  color: #1d2d44;

  user-select: none;
  cursor: pointer;

  @media ${device.laptopL} {
    font-size: 20px;
    line-height: 26px;
    font-weight: ${({ $isOpen }) => ($isOpen ? "700" : "400")};
  }

  &:hover {
    font-weight: bold;
    letter-spacing: -0.4px;
    @media ${device.laptopL} {
      text-decoration: none;
    }
  }
`;

const Arrow = styled(ArrowLink)<{ $isOpen: boolean }>`
  margin-left: 8px;
  transform: rotate(${({ $isOpen }) => ($isOpen ? "180deg" : "0deg")});
  transition: all 0.3s ease-in-out;
`;
