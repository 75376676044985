import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { device } from "../../../../../assets/themes/breakpoints";

const TEXTS = ["Turning", "Pushing"];

const DELAY_BEFORE_START = 2700;

const TYPE_SPEED = 200;

export const TypingAnimation = () => {
  const [isStarted, setIsStarted] = useState(false);
  const [currentText, setCurrentText] = useState("Turning");
  const [currentWorldId, setCurrentWorldId] = useState(0);
  const [direction, setDirection] = useState<"forward" | "backward">("forward");

  const typeText = () => {
    const text = TEXTS[currentWorldId];
    const textLength = text.length;

    if (currentText.length < textLength && direction === "forward") {
      setCurrentText((prev) => prev + text[currentText.length]);
    } else {
      if (currentText.length === 1) {
        setCurrentText("");
        setDirection("forward");
        setCurrentWorldId((prev) => (prev + 1) % TEXTS.length);
      } else {
        setCurrentText((prev) => prev.slice(0, -1));
        setDirection("backward");
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => setIsStarted(true), DELAY_BEFORE_START);

    let typingTimer: NodeJS.Timeout | null = null;

    if (isStarted) {
      clearTimeout(timer);
      typingTimer = setTimeout(typeText, TYPE_SPEED);
    }

    return () => {
      typingTimer && clearTimeout(typingTimer);
    };
  }, [currentText, isStarted]);

  return (
    <TextWrapper>
      <Text $isLongWord={currentWorldId === 1}>
        {currentText}
        {isStarted && <Cursor />}
      </Text>
    </TextWrapper>
  );
};

const TextWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 287px;
  vertical-align: bottom;

  @media ${device.mobileL} {
    width: 150px;
  }
`;

const Text = styled.span<{ $isLongWord: boolean }>`
  display: inline-block;
  position: relative;
  height: 90px;
  vertical-align: bottom;
  letter-spacing: ${({ $isLongWord }) =>
    $isLongWord ? "-0.28rem;" : "inherit"};

  @media ${device.mobileL} {
    height: 48px;
    letter-spacing: inherit;
  }
`;

const Cursor = styled.span`
  position: absolute;
  top: 0;
  right: -10px;
  width: 3px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
`;
