import { RoutePaths } from "../../../interfaces/routesPaths";

export const LINKS_DATA = [
  {
    title: "COMPANY",
    links: [
      {
        title: "About",
        link: RoutePaths.ABOUT_CLOYD.path,
      },
      {
        title: "Industries",
        link: RoutePaths.INDUSTRIES.path,
      },
      {
        title: "Download",
        link: RoutePaths.DOWNLOAD.path,
      },
      {
        title: "Careers",
        link: RoutePaths.CAREERS.path,
      },
      {
        title: "Contact",
        link: RoutePaths.CONTACT_US.path,
      },
    ],
  },
  {
    title: "SOLUTIONS",
    links: [
      {
        title: "3D Manuals",
        link: RoutePaths.MANUALS.path,
      },
      {
        title: "3D Immersive training",
        link: RoutePaths.IMMERSIVE_TRAINING.path,
      },
      {
        title: "3D Immersive collaboration",
        link: RoutePaths.IMMERSIVE_COLLABORATION.path,
      },
    ],
  },
  {
    title: "PRODUCTS",
    links: [
      {
        title: "Cloyd IXR",
        link: RoutePaths.CLOYD_IXR.path,
      },
      {
        title: "IXR Hubs",
        link: RoutePaths.IXR_HUBS.path,
      },
    ],
  },
  {
    title: "RESOURCES",
    links: [
      {
        title: "Support",
        link: RoutePaths.COMING_SOON.path,
      },
      {
        title: "Blog",
        link: RoutePaths.COMING_SOON.path,
      },
      {
        title: "FAQ",
        link: RoutePaths.FAQ.path,
      },
      {
        title: "Terms and conditions",
        link: RoutePaths.TERMS_AND_CONDITIONS.path,
      },
      {
        title: "Privacy policy",
        link: RoutePaths.PRIVACY_POLICY.path,
      },
    ],
  },
];
