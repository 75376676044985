import { Outlet } from "react-router-dom";
import { Footer, Header } from "../components";

import styled from "styled-components";
import { device } from "../assets/themes/breakpoints";
import { useEffect, useState } from "react";
import { ScrollArrowUp } from "../assets/icons";
import { MainBackground } from "./components/MainBackground";

export const MainLayout = () => {
  const [isScrollToTopButtonVisible, setIsScrollToTopButtonVisible] =
    useState(false);
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const hideGoToTopButton = () => {
    if (window.pageYOffset > 200) {
      setIsScrollToTopButtonVisible(true);
    } else {
      setIsScrollToTopButtonVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", hideGoToTopButton);

    return () => {
      window.removeEventListener("scroll", hideGoToTopButton);
    };
  }, []);

  return (
    <Layout>
      <MainBackground />
      <GoToTopButton
        onClick={handleScrollToTop}
        $isScrollToTopButtonVisible={isScrollToTopButtonVisible}
      >
        <ScrollArrowUp />
      </GoToTopButton>
      <Header />
      <Outlet />
      <Footer />
    </Layout>
  );
};

const Layout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;

  @media ${device.laptop} {
    overflow: hidden;
  }
`;

const GoToTopButton = styled.button<{ $isScrollToTopButtonVisible: boolean }>`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 20px;
  right: 20px;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  border-color: ${({ theme }) => theme.colors.primary};
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 100;

  ${({ $isScrollToTopButtonVisible }) =>
    $isScrollToTopButtonVisible
      ? `
    opacity: 0.5;
    visibility: visible;
  `
      : `
    opacity: 0;
    visibility: hidden;
  `}

  @media ${device.laptopL} {
    width: 35px;
    height: 35px;
    bottom: 15px;
    right: 5px;
  }

  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }
`;
