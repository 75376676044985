import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { device } from "../../../../../assets/themes/breakpoints";
import { LinkWithArrow, ModalWindow } from "../../../../../ui";

import gsap from "gsap";
import { RoutePaths } from "../../../../../interfaces/routesPaths";

const MANUAL_LINK =
  "https://prod.cloyd.inlu.net/headless-viewer/fcbe8ed9-8f5e-4d96-b915-3ca04d543818";

export const LeftSide = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);
  const arrowLinkRef = useRef<HTMLDivElement>(null);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!wrapperRef.current) return;
    const wrapperElement = wrapperRef.current;

    const title = titleRef.current;
    const text = textRef.current;
    const arrowLink = arrowLinkRef.current;

    if (!title || !text || !arrowLink) return;

    gsap.fromTo(
      title,
      {
        opacity: 0,
        y: 80,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.8,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperElement,
          start: "top center",
          end: "bottom top",
        },
      }
    );

    gsap.fromTo(
      text,
      {
        opacity: 0,
        y: 80,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.6,
        delay: 0.2,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperElement,
          start: "top center",
          end: "bottom top",
        },
      }
    );

    gsap.fromTo(
      arrowLink,
      {
        opacity: 0,
        y: 80,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.4,
        delay: 0.4,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperElement,
          start: "top center",
          end: "bottom top",
        },
      }
    );
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <Title ref={titleRef}>
        Cloyd works on <br />
        <HighlightText> any web browser</HighlightText>
      </Title>
      <Text ref={textRef}>
        Simplify training creation, lower costs, and improve learning through
        interactivity and innovation.
      </Text>
      <div ref={arrowLinkRef}>
        <LinkWithArrow
          text={"Try Now!"}
          linkType="dark"
          href={RoutePaths.DOWNLOAD.path}
          onClick={handleOpenModal}
        />
      </div>

      <ModalWindowStyled
        contentStyle={{
          padding: 0,
          borderRadius: 15,
          overflow: "hidden",
          position: "relative",
        }}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      >
        <ModelWrapper>
          <FakeCloseButton onClick={handleCloseModal} />
          <iframe
            title="3D Tutorial"
            src={MANUAL_LINK}
            width="100%"
            height="100%"
            allow="autoplay"
          />
        </ModelWrapper>
      </ModalWindowStyled>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 58px;
  line-height: 65px;

  letter-spacing: -0.04em;
  color: #1d2d44;

  @media ${device.mobileL} {
    font-size: 32px;
    line-height: 38px;
  }
`;

const HighlightText = styled.span`
  opacity: 0.5;
`;

const ModalWindowStyled = styled(ModalWindow)`
  padding: 0;
`;

const ModelWrapper = styled.div`
  max-width: 100%;
  max-height: 100%;
  width: 900px;
  height: 449px;
  border-radius: 15px;
  overflow: hidden;
`;

const FakeCloseButton = styled.button`
  position: absolute;
  top: 40px;
  left: 40px;
  width: 50px;
  height: 50px;
`;

const Text = styled.p`
  margin-top: 28px;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  opacity: 0.7;
  margin-bottom: 44px;

  @media ${device.mobileL} {
    margin-top: 15px;
    font-size: 16px;
    line-height: 23px;
  }
`;

const ArrowLink = styled(LinkWithArrow)`
  @media ${device.laptop} {
    display: none;
  }
`;
