import * as React from "react";
import { SVGProps } from "react";
const SvgErrorInputIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.5 10a9.5 9.5 0 1 1-19 0 9.5 9.5 0 0 1 19 0Z"
      stroke="#DF564D"
    />
    <path
      d="M9.979 14.859a.917.917 0 0 0 .666-.27 1 1 0 0 0 .233-.643.93.93 0 0 0-.267-.642.918.918 0 0 0-1.264 0 .93.93 0 0 0-.2.992c.046.11.114.21.2.292a.787.787 0 0 0 .632.27Z"
      fill="#DF564D"
      stroke="#DF564D"
      strokeWidth={0.4}
    />
    <path
      d="M9.979 11.798c.335 0 .604-.22.604-.694v-4.79c0-.474-.269-.709-.604-.709-.336 0-.604.235-.604.709v4.79c0 .473.268.694.604.694Z"
      fill="#DF564D"
    />
  </svg>
);
export default SvgErrorInputIcon;
