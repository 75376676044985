import styled, { keyframes } from "styled-components";
import { PlayVideoTriangle } from "../../../../../assets/icons";
import "@google/model-viewer";
import { device } from "../../../../../assets/themes/breakpoints";
import { YouTubePlayer } from "../../../../../ui";
import { useState } from "react";

export const ModelWrapper = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const handleVideoOpen = () => {
    setIsVideoOpen(true);
  };

  const handleVideoClose = () => {
    setIsVideoOpen(false);
  };

  return (
    <Wrapper>
      <Floating>
        <model-viewer
          id="main-section-model"
          className="model-viewer"
          style={{ width: "100%", height: "100%" }}
          rotation-per-second="15deg"
          auto-rotate
          disable-zoom
          interaction-prompt
          disable-pan
          camera-controls
          src="resources/models/abstract-shape.glb"
          alt="company video"
          ar-status="not-presenting"
        ></model-viewer>
      </Floating>
      <CompanyVideoButtonWrapper
        // href=""https://www.youtube.com/embed/1Q8fG0TtVAY""
        // target="_blank"
        onClick={handleVideoOpen}
      >
        <CompanyVideoButton>
          <PlayIcon />
        </CompanyVideoButton>
        <CompanyButtonText>Play video</CompanyButtonText>
      </CompanyVideoButtonWrapper>
      <YouTubePlayer
        isOpen={isVideoOpen}
        onClose={handleVideoClose}
        src="https://www.youtube.com/embed/vf9YVEeT9dM?autoplay=1"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  max-height: 950px;

  @media ${device.laptopL} {
    margin-top: 50px;
    height: 700px;
  }

  @media ${device.tablet} {
    margin-top: 0;
  }

  @media ${device.mobileL} {
    height: 355px;
  }
`;

const CompanyVideoButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  border: 1px solid #1d2d44;
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);

  color: #1d2d44;

  @media ${device.laptop} {
    width: 64px;
    height: 64px;
  }

  @media ${device.mobileL} {
    width: 48px;
    height: 48px;
    font-size: 14px;
    line-height: 18px;
  }
`;

const CompanyButtonText = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.primary};

  @media ${device.mobileL} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const PlayIcon = styled(PlayVideoTriangle)`
  transform: translateX(3px);

  @media ${device.laptop} {
    transform: scale(0.8) translateX(3px);
  }

  @media ${device.mobileL} {
    transform: scale(0.6) translateX(3px);
  }
`;

const CompanyVideoButtonWrapper = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 0;
  right: 29px;
  column-gap: 20px;

  cursor: pointer;
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    transform: scale(1.07);
    & > ${CompanyVideoButton} {
      border-width: 1px;
      box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
    }
  }

  @media ${device.laptopL} {
    bottom: -30px;
  }
`;

const floating = keyframes`
  from {
    transform: translateY(-5%);
  }

  to {
    transform: translateY(calc(-5% + 50px));
  }
`;

const Floating = styled.div`
  width: 100%;
  height: 100%;
  animation: ${floating} 5s alternate ease-in-out infinite;
  animation-delay: 0.5s;
`;
