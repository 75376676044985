import styled from "styled-components";
import { ModalWindow } from "../ModalWindow";

interface VideoPlayerProps {
  isOpen: boolean;
  onClose: () => void;
  src: string;
}

export const YouTubePlayer = ({ isOpen, onClose, src }: VideoPlayerProps) => {
  return (
    <ModalWindowStyled
      contentStyle={{ padding: 0 }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <VideoWrapper>
        <Video
          src={src}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </VideoWrapper>
    </ModalWindowStyled>
  );
};

const VideoWrapper = styled.div`
  max-width: 100%;
  max-height: 100%;
  width: 693px;
  height: 390px;
`;

const Video = styled.iframe`
  width: 100%;
  height: 100%;
`;

const ModalWindowStyled = styled(ModalWindow)`
  padding: 0;
`;
