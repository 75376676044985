import styled from "styled-components";
import { device } from "../../../../../assets/themes/breakpoints";

export const LeftSide = () => {
  return (
    <Wrapper>
      {/* <Title>
        <Highlight>Here is</Highlight> what we miss in our team:
      </Title>
      <Text>Maybe we could start working together?</Text> */}
      <Title>Apply or contact us despite no open positions at the moment</Title>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Title = styled.h2`
  max-width: 708px;
  ${({ theme }) => theme.typography.title_2_bold};

  @media ${device.laptop} {
    max-width: none;
    margin-bottom: 24px;
  }

  @media ${device.tablet} {
    ${({ theme }) => theme.typography.title_2_bold_tablet};
  }

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.title_2_bold_mobile};
  }
`;

const Highlight = styled.span`
  opacity: 0.5;
`;

const Text = styled.p`
  margin-top: 33px;

  ${({ theme }) => theme.typography.paragraph_1_regular};

  @media ${device.tablet} {
    text-align: left;
  }

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.paragraph_1_regular_mobile};
  }
`;
