import styled from "styled-components";
import { Spinner } from "../../ui";

export const Loader = () => {
  return (
    <Wrapper>
      <Spinner scale={2} color="#0E7C7B" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #dfe7ed;
`;
