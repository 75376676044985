import styled from "styled-components";

import { MainInfo } from "./components/MainInfo";
import { ModelWrapper } from "./components/components";

import { SectionLayout } from "../../../../layouts/SectionLayout";
import { device } from "../../../../assets/themes/breakpoints";

export const FirstSection = () => {
  return (
    <>
      <SectionLayout>
        <Section>
          <MainInfo />
          <ModelWrapper />
        </Section>
      </SectionLayout>
    </>
  );
};

const Section = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  column-gap: 12px;
  width: 100%;
  overflow: visible;

  @media ${device.laptopL} {
    flex-direction: column;
  }
`;
