import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ArrowLink } from "../../../../../assets/icons";
import { device } from "../../../../../assets/themes/breakpoints";
import { useMouseDownOutside } from "../../../../../utils/useMouseDownOutside";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

type LinkType = {
  text: string;
  url: string;
};

interface QuestionSectionProps {
  question: string;
  link1?: LinkType;
  text2?: string;
  link2?: LinkType;
  text3?: string;
  answer: string;
}

export const QuestionSection = ({
  question,
  answer,
  link1,
  link2,
  text2,
  text3,
}: QuestionSectionProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [textHeight, setTextHeight] = useState(0);
  const wrapperRef = useRef<HTMLButtonElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    ScrollTrigger.refresh();
  }, [isOpen, textHeight]);

  useEffect(() => {
    const wrapper = wrapperRef.current;

    if (!wrapper) return;

    gsap.fromTo(
      wrapper,
      {
        opacity: 0,
        y: 30,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.3,
        scrollTrigger: {
          trigger: wrapper,
          start: "top 100%",
        },
      }
    );

    return () => {
      gsap.killTweensOf(wrapper);
    };
  }, []);

  const getTextHeight = () => {
    if (!textRef.current) return;
    setTextHeight(textRef.current.clientHeight);
    setIsOpen(false);
  };

  useEffect(() => {
    getTextHeight();
  }, [textRef]);

  const switchMenu = () => {
    setIsOpen((prev) => !prev);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useMouseDownOutside(wrapperRef, closeMenu);

  return (
    <Wrapper ref={wrapperRef} onClick={switchMenu} $isOpen={isOpen}>
      <TitleRow>
        <Title>{question}</Title>
        <OpenAnswerButton>
          <Arrow $isOpen={isOpen} />
        </OpenAnswerButton>
      </TitleRow>
      <Text ref={textRef} $isOpen={isOpen} $textHeight={textHeight}>
        {answer}
        {link1 && (
          <Link href={link1.url} target="_blank">
            {link1.text}
          </Link>
        )}
        {text2 && <span>{text2}</span>}
        {link2 && (
          <Link href={link2.url} target="_blank">
            {link2.text}
          </Link>
        )}
        {text3 && <span>{text3}</span>}
      </Text>
    </Wrapper>
  );
};

const Wrapper = styled.button<{
  $isOpen: boolean;
}>`
  position: relative;
  border-radius: 20px;
  padding: ${({ $isOpen }) =>
    $isOpen ? "18px 30px 33px 29px" : "18px 30px 24px 29px"};
  background: rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-in-out;
  width: 100%;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary};

  font-weight: 700;

  &:hover {
    text-decoration: underline;
  }
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  text-align: left;
  margin-right: 15px;
  ${({ theme }) => theme.typography.title_3_bold};

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.title_3_bold_mobile};
  }
`;

const Text = styled.p<{ $isOpen: boolean; $textHeight: number }>`
  margin-top: ${({ $isOpen }) => ($isOpen ? "12px" : "0")};
  overflow: hidden;
  max-height: ${({ $isOpen, $textHeight }) =>
    $textHeight ? ($isOpen ? `${$textHeight}px` : 0) : "auto"};
  transition: all 0.3s ease-in-out;
  ${({ theme }) => theme.typography.paragraph_1_regular};

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.paragraph_1_regular_mobile};
    font-size: 14px;
  }
  text-align: left;
`;

const OpenAnswerButton = styled.div`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  text-align: left;
`;

const Arrow = styled(ArrowLink)<{ $isOpen: boolean }>`
  transform: rotate(${({ $isOpen }) => ($isOpen ? "180deg" : "0deg")});
  transition: all 0.3s ease-in-out;
`;
