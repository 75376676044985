import { useEffect } from "react";

import gsap from "gsap";

interface UseAnimationsProps {
  wrapperRef: React.RefObject<HTMLDivElement>;
  imageWrapperRef: React.RefObject<HTMLDivElement>;
  image1Ref: React.RefObject<HTMLDivElement>;
  image2Ref: React.RefObject<HTMLDivElement>;
  image3Ref: React.RefObject<HTMLDivElement>;
  image4Ref: React.RefObject<HTMLDivElement>;
  image5Ref: React.RefObject<HTMLDivElement>;
}

export const useAnimations = ({
  wrapperRef,
  imageWrapperRef,
  image1Ref,
  image2Ref,
  image3Ref,
  image4Ref,
  image5Ref,
}: UseAnimationsProps) => {
  useEffect(() => {
    if (!imageWrapperRef.current || !wrapperRef.current) return;
    const element = imageWrapperRef.current;

    const image1 = image1Ref.current;
    const image2 = image2Ref.current;
    const image3 = image3Ref.current;
    const image4 = image4Ref.current;
    const image5 = image5Ref.current;

    if (!image1 || !image2 || !image3 || !image4 || !image5) return;

    gsap.fromTo(
      element,
      {
        rotateX: 12,
        rotateY: -12,
        rotateZ: 12,
      },
      {
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0,
        duration: 1,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "top center",
          end: "bottom top",
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      image1,
      {
        blur: 0,
        scale: 1,
        opacity: 1,
      },
      {
        blur: 20,
        scale: 0.8,
        opacity: 0,
        duration: 1,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "+20% center",
          end: "+30% center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      image2,
      {
        right: "-100%",
        x: "50%",
      },
      {
        right: "50%",
        x: "50%",
        duration: 1,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "+20% center",
          end: "+30% center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      image2,
      {
        blur: 0,
        scale: 1,
        opacity: 1,
      },
      {
        blur: 20,
        scale: 0.8,
        opacity: 0,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "+35% center",
          end: "+45% center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      image3,
      {
        right: "-100%",
        x: "50%",
      },
      {
        right: "50%",
        x: "50%",
        duration: 1,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "+35% center",
          end: "+45% center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      image3,
      {
        blur: 0,
        scale: 1,
        opacity: 1,
      },
      {
        blur: 20,
        scale: 0.8,
        opacity: 0,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "+65% center",
          end: "+75% center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      image4,
      {
        right: "-100%",
        x: "50%",
      },
      {
        right: "50%",
        x: "50%",
        duration: 1,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "+65% center",
          end: "+75% center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      image4,
      {
        blur: 0,
        scale: 1,
        opacity: 1,
      },
      {
        blur: 20,
        scale: 0.8,
        opacity: 0,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "80% center",
          end: "90% center",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      image5,
      {
        right: "-100%",
        x: "50%",
      },
      {
        right: "50%",
        x: "50%",
        duration: 1,
        ease: "none",
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "80% center",
          end: "90% center",
          scrub: true,
        },
      }
    );
  }, [imageWrapperRef, wrapperRef]);
};
