import { useEffect, useRef } from "react";
import styled from "styled-components";
import { device } from "../../../../../assets/themes/breakpoints";

import gsap from "gsap";

export const RightSide = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const title = titleRef.current;
    const text = textRef.current;

    if (!wrapper || !title || !text) return;

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: wrapper,
        start: "top center",
        end: "bottom top",
      },
    });

    tl.fromTo(
      [title, text],
      {
        y: 30,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
        delay: 0.7,
        stagger: 0.2,
        ease: "none",
      }
    );
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <Title ref={titleRef}>What you need to know about our company?</Title>
      <Text ref={textRef}>
        Cloyd is a global force of process visionaries and tech disruptors. With
        a multinational team spanning three continents and decades of combined
        experience, the Cloyd team is positioned to take on any challenge within
        the Extended Reality space.
        <br />
        <br />
        Integrating mobile Virtual and Augmented Reality solutions, our team has
        been providing effective proprietary solutions to the aviation, medical,
        engineering, academic, industrial, defense and telecommunication
        industries for over two decades.
        <br />
        <br />
        We pride ourselves on working side by side with our clients and
        developing ideas as a team for the long term.
      </Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Title = styled.h2`
  max-width: 503px;
  ${({ theme }) => theme.typography.title_2_bold};

  @media ${device.laptop} {
    max-width: none;
  }

  @media ${device.tablet} {
    ${({ theme }) => theme.typography.title_2_bold_tablet};
  }

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.title_2_bold_mobile};
  }
`;

const Text = styled.p`
  max-width: 586px;
  margin-top: 28px;

  ${({ theme }) => theme.typography.paragraph_1_regular};

  @media ${device.laptop} {
    max-width: none;
  }

  @media ${device.mobileL} {
    margin-top: 15px;
    ${({ theme }) => theme.typography.paragraph_1_regular_mobile};
  }
`;
