export const CARD_DATA = [
  {
    title: "Follow and share hubs",
  },
  {
    title: "Collaborate and communicate",
  },
  {
    title: "View models in AR/VR environment",
  },
  {
    title: "Create private hubs",
  },
  {
    title: "Access files anytime 24/7",
  },
];
