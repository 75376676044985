import * as React from "react";
import { SVGProps } from "react";
const SvgCheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 23"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.652 11.336c-1.94-2.037-5.044 1.067-3.007 3.007 2.522 2.619 5.044 5.335 7.663 7.857 1.164 1.067 2.037 1.067 3.104 0L30.939 3.77c1.94-2.038-1.067-5.142-3.104-3.105L10.957 17.544l-6.305-6.208Z"
      fill="#25A770"
    />
  </svg>
);
export default SvgCheckIcon;
