import * as React from "react";
import { SVGProps } from "react";
const SvgYoutubeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.666 2.057A2.01 2.01 0 0 0 14.25.633C13.003.297 8 .297 8 .297s-5.003 0-6.251.336A2.01 2.01 0 0 0 .334 2.057C0 3.313 0 5.933 0 5.933s0 2.62.334 3.876A1.98 1.98 0 0 0 1.75 11.21c1.248.337 6.251.337 6.251.337s5.003 0 6.251-.337a1.98 1.98 0 0 0 1.415-1.4C16 8.552 16 5.932 16 5.932s0-2.62-.334-3.876ZM6.364 8.312V3.554l4.181 2.38-4.181 2.378Z"
      fill="#fff"
    />
  </svg>
);
export default SvgYoutubeIcon;
