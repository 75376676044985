import { SetStateAction, useRef, useState } from "react";
import styled from "styled-components";
import { device } from "../../../../../assets/themes/breakpoints";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { AttachFile, ErrorInputIcon } from "../../../../../assets/icons";
import { toast } from "react-toastify";
import { Spinner } from "../../../../../ui";

const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const convertBase64 = (file?: any) => {
  if (!file) return;
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      if (typeof fileReader.result === "string") {
        resolve(fileReader.result?.split(",")[1]);
      } else {
        reject("Error");
      }
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const Form = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [attachment, setAttachment] = useState<File | null>();
  const [loading, setLoading] = useState(false);

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [messageError, setMessageError] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  const relative = window.location.host;
  const query = new URL("submit-feedback/", `https://${relative}`).href;

  const validate = () => {
    let valid = true;
    if (name === "") {
      setNameError("Name is required");
      valid = false;
    } else {
      setNameError("");
    }

    if (email === "") {
      setEmailError("Email is required");
      valid = false;
    } else if (!isValidEmail.test(email)) {
      setEmailError("That’s not a valid email. Try a different email address.");
      valid = false;
    } else {
      setEmailError("");
    }

    if (message === "") {
      setMessageError("Message is required");
      valid = false;
    } else {
      setMessageError("");
    }

    if (phoneNumber === "") {
      setPhoneNumberError("Phone number is required");
      valid = false;
    } else {
      setPhoneNumberError("");
    }

    return valid;
  };

  const resetFileInput = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleFileChange = (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    setAttachment(fileObj);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    validate();

    if (!validate()) {
      return;
    }

    try {
      setLoading(true);
      const data = {
        title: "Careers",
        name,
        email,
        text: message,
        phone: phoneNumber,
        fileBody: await convertBase64(attachment),
        fileName: attachment?.name,
      };

      await fetch(query, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      resetFileInput();
      setName("");
      setEmail("");
      setMessage("");
      setPhoneNumber("");
      setAttachment(null);

      toast.success("Form sent successfully");
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleRemoveAttachment = () => {
    setAttachment(null);
    resetFileInput();
  };

  return (
    <Wrapper onSubmit={onSubmit}>
      <InputWrapper>
        <Input
          $hasError={Boolean(nameError)}
          type="text"
          placeholder="Your Name"
          value={name}
          onChange={(e: { target: { value: SetStateAction<string> } }) => {
            setName(e.target.value);
            Boolean(nameError) && validate();
          }}
        />
        {Boolean(nameError.length) && (
          <Error>
            <ErrorInputIcon />
            {nameError}
          </Error>
        )}
      </InputWrapper>

      <InputWrapper>
        <Input
          $hasError={Boolean(emailError)}
          type="email"
          placeholder="Your Email"
          value={email}
          rows={17}
          onChange={(e: { target: { value: SetStateAction<string> } }) => {
            setEmail(e.target.value);
            Boolean(emailError) && validate();
          }}
        />
        {Boolean(emailError.length) && (
          <Error>
            <ErrorInputIcon />
            {emailError}
          </Error>
        )}
      </InputWrapper>

      <InputWrapper $zIndex={10}>
        <PhoneInput
          inputStyle={{
            padding: "18px 18px 19px 55px",
            width: "100%",
            borderRadius: "16px",
            overflow: "hidden",
            height: "auto",
            fontFamily: "inherit",
            fontSize: "inherit",
            backgroundColor: "#F9FBFB",
          }}
          containerStyle={{ zIndex: 2 }}
          buttonStyle={{
            borderRadius: "16px",
            border: "none",
            background: "none",
            left: "15px",
            top: "2px",
            height: "calc(100% - 4px)",
          }}
          dropdownStyle={{ height: "250px", opacity: 1 }}
          isValid={(value, country) => {
            if (value.match(/12345/)) {
              //@ts-ignore
              return "Invalid value: " + value + ", " + country.name;
            } else if (value.match(/1234/)) {
              return false;
            } else {
              return true;
            }
          }}
          country={"us"}
          value={phoneNumber}
          onChange={(phone) => setPhoneNumber(phone)}
        />
        {Boolean(phoneNumberError.length) && (
          <Error>
            <ErrorInputIcon />
            {phoneNumberError}
          </Error>
        )}
      </InputWrapper>

      <InputWrapper>
        <TextArea
          $hasError={Boolean(messageError)}
          placeholder="Tell us how we can help"
          value={message}
          onChange={(e: { target: { value: SetStateAction<string> } }) => {
            setMessage(e.target.value);
            Boolean(messageError) && validate();
          }}
        />
        {Boolean(messageError.length) && (
          <Error>
            <ErrorInputIcon />
            {messageError}
          </Error>
        )}
      </InputWrapper>

      <AttachWrapper>
        <Button onClick={handleClick} type="button">
          {attachment ? (
            <span>{attachment.name}</span>
          ) : (
            <>
              Attach resume
              <AttachFileStyled />
            </>
          )}
        </Button>
        {attachment && <CloseIcon onClick={handleRemoveAttachment} />}

        <input
          ref={inputRef}
          type="file"
          accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
      </AttachWrapper>
      <Submit
        $isLoading={loading}
        type="submit"
        value="Submit"
        disabled={Boolean(
          nameError || emailError || messageError || phoneNumberError || loading
        )}
      >
        {loading && <Spinner scale={0.6} />}
        Submit
      </Submit>
    </Wrapper>
  );
};

const Wrapper = styled.form`
  margin-top: 41px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 16px;
  width: 100%;
`;

const Button = styled.button`
  ${({ theme }) => theme.typography.paragraph_3_regular};

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.paragraph_3_regular_mobile};
  }

  opacity: 1;
`;

const Input = styled.input<{ $hasError: boolean }>`
  padding: 20px 28px 19px 20px;
  background: #f9fbfb;
  border: 1px solid ${({ $hasError }) => ($hasError ? "#F2BBB8" : "#e1e2e2")};
  border-radius: 16px;

  &:focus {
    border: 1px solid ${({ $hasError }) => ($hasError ? "#F2BBB8" : "#1D2D44")};
  }

  ${({ theme }) => theme.typography.paragraph_3_regular};

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.paragraph_3_regular_mobile};
  }
  opacity: 1;
`;

const TextArea = styled.textarea<{ $hasError: boolean }>`
  padding: 20px 28px 19px 20px;
  background: #f9fbfb;
  border: 1px solid ${({ $hasError }) => ($hasError ? "#F2BBB8" : "#e1e2e2")};
  border-radius: 16px;

  &:focus {
    border: 1px solid ${({ $hasError }) => ($hasError ? "#F2BBB8" : "#1D2D44")};
  }

  ${({ theme }) => theme.typography.paragraph_3_regular};

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.paragraph_3_regular_mobile};

    opacity: 1;
    resize: none;
    min-height: 201px;
  }
  opacity: 1;
  resize: none;
  min-height: 201px;
`;

const Submit = styled.button<{ $isLoading: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  background: #1d2d44;
  border-radius: 16px;
  padding: 18px 18px 19px 18px;

  ${({ theme }) => theme.typography.paragraph_3_regular};

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.paragraph_3_regular_mobile};

    color: ${({ $isLoading }) => ($isLoading ? "transparent" : "white")};
    opacity: 1;
    border: none;
    cursor: pointer;
  }

  color: ${({ $isLoading }) => ($isLoading ? "transparent" : "white")};
  opacity: 1;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const InputWrapper = styled.div<{ $zIndex?: number }>`
  position: relative;
  z-index: ${({ $zIndex }) => $zIndex || 1};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${({ theme }) => theme.typography.paragraph_3_regular};

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.paragraph_3_regular_mobile};
    opacity: 1;
  }
  opacity: 1;
`;

const Error = styled.p`
  display: flex;
  padding: 6px;
  gap: 7px;
  background: #fae6e5;
  border-radius: 8px;
  ${({ theme }) => theme.typography.paragraph_3_regular};
  color: #df564d;
  opacity: 1;

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.paragraph_3_regular_mobile};
  }
  font-size: 12px;
  font-weight: 400;
`;

const AttachWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseIcon = styled.button`
  position: relative;
  margin-left: 5px;
  height: 20px;
  transform: rotate(45deg);
  cursor: pointer;

  &:hover {
    color: red;
  }

  &:before {
    content: "+";
    font-size: 20px;
  }
`;

const AttachFileStyled = styled(AttachFile)`
  margin-left: 11px;
`;
