import image1 from "../../../../../assets/images/cloyd-ixr/s2-1.png";
import image2 from "../../../../../assets/images/cloyd-ixr/s2-2.png";
import image3 from "../../../../../assets/images/cloyd-ixr/s2-3.png";
import image4 from "../../../../../assets/images/cloyd-ixr/s2-4.png";
import image5 from "../../../../../assets/images/cloyd-ixr/s2-5.png";

export const INFORMATION_BLOCK_DATA = [
  {
    title: "3D (three-dimensional view)",
    text: "3D (three dimensions or three-dimensional) objects are images that provide a depth perception. In our application, three-dimensional images are being created interactively so that users feel involved in the experience, this experience is called virtual and augmented reality. With 3D images, it is possible to find and see objects instantly that are not visible on a conventional 2D display or on a printed user manual.",
    imageSrc: image1,
  },
  {
    title: "Instant Search",
    text: "The default search function provides wide capabilities on product discovery. It uses a simple rigid algorithm that looks for the exact match of the words and phrases a user types in the search bar to the applications content.",
    imageSrc: image2,
  },
  {
    title: "No special equipment requirements",
    text: "Viewing and interacting with 3D images usually requires special software that runs only on a PC, or as it often happens for virtual reality, it may require additional hardware. In our case, the interaction with 3D objects can be performed directly from any mobile device.",
    text2:
      "This search function is a powerful and fully featured tool that makes the search function much more flexible and useful. It provides several key features to help customers find the product they need across all the content in the application.",
    imageSrc: image3,
  },
  {
    title: "Collaboration platform",
    text: "Cloyd is a collaboration platform, allow users to collaborate in 3D format, from remote locations. These solutions enable users to meet up in the same virtual space and communicate with immersive interactions and voice.",
    imageSrc: image4,
  },
  {
    title: "Information reception",
    text: "The depth and partial immersion effect allows users to absorb and retrieve information much faster than with conventional text. Therefore, the information is not only rapidly absorbed, but also remains in the user's memory longer in detail.",
    imageSrc: image5,
  },
];
