import styled from "styled-components";
import { device } from "../../../../../assets/themes/breakpoints";
import { LinkWithArrow } from "../../../../../ui";
import { CAREER_MOCK_DATA } from "../constants/career-mock-data";
import { CareerSection } from "./CareerSection";

export const RightSide = () => {
  const scrollToAnchor = () => {
    const element = document.getElementById("joinTeamSection");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Wrapper>
      {/* {CAREER_MOCK_DATA.map((item) => (
        <CareerSection key={item.id} {...item} />
      ))} */}
      <Text>
        Join our talent community to stay connected with us for future
        opportunities that may align with your interests and expertise. You will
        receive regular updates on our latest news, culture, and values, and
        have the chance to connect with our team members. Being part of our
        community can help you stay informed about potential opportunities, even
        if you're not actively job searching. Apply today and become part of our
        community!
      </Text>
      <LinkWrapper>
        <LinkWithArrow
          text={"Apply"}
          linkType="dark"
          onClick={scrollToAnchor}
        />
      </LinkWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 24px;
  max-width: 586px;

  @media ${device.mobileL} {
    row-gap: 16px;
  }
`;

const Text = styled.p`
  ${({ theme }) => theme.typography.paragraph_1_regular};

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.paragraph_1_regular_mobile};
  }
`;

const LinkWrapper = styled.div`
  margin-top: 44px;

  @media ${device.mobileL} {
    margin-top: 16px;
  }
`;
