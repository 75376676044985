import { useEffect, useRef } from "react";
import styled from "styled-components";
import { device } from "../../../../assets/themes/breakpoints";
import { SectionLayout } from "../../../../layouts/SectionLayout";

import gsap from "gsap";

import CallCenterIcon from "../../../../assets/images/s6-call-center.png";
import CloudIcon from "../../../../assets/images/s6-cloud.png";
import GeometryIcon from "../../../../assets/images/s6-geometry.png";
import TabletIcon from "../../../../assets/images/s6-tablet.png";
import { InfoCard } from "../../../../ui";

const CARD_DATA = [
  {
    iconSrc: CloudIcon,
    title: "IXR Hub",
    text: "Cloud hub for storing and easily accessing 3D projects",
  },
  {
    iconSrc: TabletIcon,
    title: "IXR Viewer",
    text: "A built-in interactive 3D and IXR file viewer",
  },
  {
    iconSrc: GeometryIcon,
    title: "IXR Builder",
    text: "Interactive builder and editor of IXR and 3D models",
  },
  {
    iconSrc: CallCenterIcon,
    title: "IXR Lync",
    text: "Integrated multisession audio conferencing module",
  },
];

export const SixthSection = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const title1Ref = useRef<HTMLSpanElement>(null);
  const title2Ref = useRef<HTMLHeadingElement>(null);
  const title3Ref = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (
      !sectionRef.current ||
      !title1Ref.current ||
      !title2Ref.current ||
      !title3Ref.current ||
      !textRef.current
    )
      return;
    const wrapper = sectionRef.current;
    const title1 = title1Ref.current;
    const title2 = title2Ref.current;
    const title3 = title3Ref.current;
    const text = textRef.current;

    gsap.fromTo(
      title1,
      {
        x: 150,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.5,
        ease: "none",
        scrollTrigger: {
          trigger: wrapper,
          start: "top center",
          end: "bottom top",
        },
      }
    );

    gsap.fromTo(
      title2,
      {
        x: 150,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.5,
        delay: 0.4,
        ease: "none",
        scrollTrigger: {
          trigger: wrapper,
          start: "top center",
          end: "bottom top",
        },
      }
    );

    gsap.fromTo(
      title3,
      {
        x: 150,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.5,
        delay: 0.8,
        ease: "none",
        scrollTrigger: {
          trigger: wrapper,
          start: "top center",
          end: "bottom top",
        },
      }
    );

    gsap.fromTo(
      text,
      {
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.5,
        delay: 1.2,
        ease: "none",
        scrollTrigger: {
          trigger: wrapper,
          start: "top center",
          end: "bottom top",
        },
      }
    );

    return () => {
      gsap.killTweensOf(title1);
      gsap.killTweensOf(title2);
      gsap.killTweensOf(title3);
      gsap.killTweensOf(text);
    };
  }, []);

  return (
    <SectionLayout>
      <Section ref={sectionRef}>
        <InfoWrapper>
          <Title>
            <TitleRow ref={title1Ref}>Boost information value.</TitleRow>

            <TitleRow ref={title2Ref}>Share knowledge.</TitleRow>

            <TitleRow ref={title3Ref}>Enhance efficiency.</TitleRow>
          </Title>
          <Text ref={textRef}>
            Cloyd provides you with a powerful set of instruments:
          </Text>
        </InfoWrapper>
        <CardWrapper>
          {CARD_DATA.map((card, index) => (
            <InfoCard key={index} {...card} />
          ))}
        </CardWrapper>
      </Section>
    </SectionLayout>
  );
};

const Section = styled.div`
  margin-top: 171px;

  @media ${device.mobileL} {
    margin-top: 70px;
  }
`;

const InfoWrapper = styled.div`
  max-width: 708px;
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 58px;
  line-height: 65px;
  letter-spacing: -0.04em;

  color: #1d2d44;

  @media ${device.mobileL} {
    font-size: 32px;
    line-height: 38px;
  }
`;

const Text = styled.p`
  margin-top: 29px;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  opacity: 0.7;

  color: #1d2d44;

  @media ${device.mobileL} {
    margin-top: 15px;
    font-size: 16px;
    line-height: 23px;
  }
`;

const CardWrapper = styled.div`
  margin-top: 58px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;

  @media ${device.laptopL} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }

  @media ${device.mobileL} {
    margin-top: 43px;
    grid-gap: 16px;
  }
`;

const TitleRow = styled.span`
  display: block;
`;
