import styled from "styled-components";
import { device } from "../../../../assets/themes/breakpoints";
import { SectionLayout } from "../../../../layouts/SectionLayout";
import { InfoCard } from "../../../../ui";
import { CARD_DATA } from "./card-data";

export const ThirdSection = () => {
  return (
    <SectionLayout
      topMargins={{
        desktop: 190,
        tablet: 100,
        mobile: 64,
      }}
    >
      <Section>
        {CARD_DATA.map((card, index) => (
          <InfoCard key={index} {...card} />
        ))}
      </Section>
    </SectionLayout>
  );
};

const Section = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;

  @media ${device.laptop} {
    grid-template-columns: 1fr;
  }
`;
