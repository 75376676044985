import * as React from "react";
import { SVGProps } from "react";
const SvgAttachFile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={10}
    height={11}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.242 2.778a.418.418 0 0 0-.59-.59L2.328 6.512A1.324 1.324 0 0 0 4.2 8.382l4.508-4.506A2.27 2.27 0 1 0 5.5.663L.976 5.188A3.227 3.227 0 1 0 5.54 9.75l4.327-4.316a.42.42 0 0 0-.292-.723.418.418 0 0 0-.299.133L4.95 9.17a2.394 2.394 0 1 1-3.382-3.391l4.525-4.525A1.432 1.432 0 0 1 8.117 3.28L3.61 7.79a.488.488 0 0 1-.688-.688l4.321-4.324Z"
      fill="#1D2D44"
    />
  </svg>
);
export default SvgAttachFile;
