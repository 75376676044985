import { forwardRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ArrowRight } from "../../assets/icons";
import { device } from "../../assets/themes/breakpoints";

type LinkType = "white" | "dark" | "transparent" | "transparent with border";

interface WhiteLinkProps {
  href?: string;
  to?: string;
  text: string;
  linkType?: LinkType;
  className?: string;
  hideArrow?: boolean;
  onClick?: () => void;
}

export const LinkWithArrow = forwardRef<HTMLDivElement, WhiteLinkProps>(
  (
    {
      href,
      to = "/",
      text,
      linkType = "white",
      className,
      hideArrow = false,
      onClick,
    },
    ref
  ) => {
    const isInternalLink = Boolean(to);

    return (
      <Wrapper $linkType={linkType}>
        <Content $linkType={linkType} className={className} ref={ref}>
          {text}{" "}
          {!hideArrow && (
            <ArrowIcon
              color={
                linkType === "white" ||
                linkType === "transparent" ||
                linkType === "transparent with border"
                  ? "#1D2D44"
                  : "#fff"
              }
            />
          )}
        </Content>

        {onClick ? (
          <Button onClick={onClick} />
        ) : isInternalLink ? (
          <InternalLink to={to} />
        ) : (
          <ExternalLink href={href} />
        )}
      </Wrapper>
    );
  }
);

const Wrapper = styled.div<{ $linkType: LinkType }>`
  position: relative;
  left: ${({ $linkType }) => ($linkType === "transparent" ? -19 : 0)}px;
  border-radius: ${({ $linkType }) => ($linkType === "transparent" ? 5 : 0)}px;

  &:hover {
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
    transform: scale(1.07);
  }
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
`;

const Button = styled.button`
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
`;

const InternalLink = styled(Link)`
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
`;

const ExternalLink = styled.a`
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const Content = styled.div<{ $linkType: LinkType }>`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 21px;

  background: ${({ theme, $linkType }) => {
    switch ($linkType) {
      case "white":
        return theme.colors.white;
      case "dark":
        return theme.colors.primary;
      case "transparent":
        return "transparent";
      case "transparent with border":
        return "transparent";
      default:
        return theme.colors.white;
    }
  }};
  border-radius: ${({ $linkType }) => ($linkType === "white" ? 10 : 8)}px;

  border: ${({ $linkType }) => {
    switch ($linkType) {
      case "transparent with border":
        return "1px solid black";
      default:
        return "none";
    }
  }};

  padding: ${({ $linkType }) => {
    switch ($linkType) {
      case "white":
        return "11px 19px 12px 19px";
      case "dark":
        return "18px 40px 19px 38px";
      case "transparent":
        return "11px 19px 12px 19px";
      default:
        return "11px 19px 12px 19px";
    }
  }};

  color: ${({ theme, $linkType }) => {
    switch ($linkType) {
      case "white":
        return theme.colors.primary;
      case "dark":
        return theme.colors.white;
      case "transparent" || "transparent with border":
        return theme.colors.primary;
      default:
        return theme.colors.primary;
    }
  }};
  cursor: pointer;

  @media ${device.mobileL} {
    font-size: 14px;
    line-height: 18px;

    padding: ${({ $linkType }) => {
      switch ($linkType) {
        case "white":
          return "11px 19px 12px 19px";
        case "dark":
          return "18px 32px 18px 33px";
        case "transparent":
          return "11px 19px 12px 19px";
        default:
          return "11px 19px 12px 19px";
      }
    }};
  }
`;

const ArrowIcon = styled(ArrowRight)`
  margin-left: 11px;
`;
