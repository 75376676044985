import { Navigate, Route, Routes } from "react-router-dom";
import { RoutePaths } from "../interfaces/routesPaths";
import { MainLayout } from "../layouts/MainLayout";

import {
  AboutCloyd,
  ContactUs,
  MainPage,
  Industries,
  Manuals,
  ImmersiveTraining,
  ImmersiveCollaboration,
  CloydIxr,
  IxrHubs,
  TermAndConditions,
  PrivacyPolicy,
  Faq,
  Careers,
  Download,
  ComingSoon,
} from "../pages";
import history from "../services/history";
import { CustomRouter } from "./components/CustomRouter";
import ScrollToTop from "./components/ScrollToTop";
import TitledRoute from "./components/TitledRoute";

export const AppRouter = () => {
  <MainLayout />;
  return (
    <CustomRouter history={{ ...history, index: 0 }}>
      <ScrollToTop />
      <Routes>
        <Route path={RoutePaths.BASE_PATH.path} element={<MainLayout />}>
          <Route
            path={RoutePaths.BASE_PATH.path}
            element={
              <TitledRoute title={RoutePaths.BASE_PATH.title}>
                <MainPage />
              </TitledRoute>
            }
          />
          <Route
            path={RoutePaths.ABOUT_CLOYD.path}
            element={
              <TitledRoute title={RoutePaths.ABOUT_CLOYD.title}>
                <AboutCloyd />
              </TitledRoute>
            }
          />
          <Route
            path={RoutePaths.CONTACT_US.path}
            element={
              <TitledRoute title={RoutePaths.CONTACT_US.title}>
                <ContactUs />
              </TitledRoute>
            }
          />
          <Route
            path={RoutePaths.INDUSTRIES.path}
            element={
              <TitledRoute title={RoutePaths.INDUSTRIES.title}>
                <Industries />
              </TitledRoute>
            }
          />
          <Route
            path={RoutePaths.MANUALS.path}
            element={
              <TitledRoute title={RoutePaths.MANUALS.title}>
                <Manuals />
              </TitledRoute>
            }
          />
          <Route
            path={RoutePaths.IMMERSIVE_TRAINING.path}
            element={
              <TitledRoute title={RoutePaths.IMMERSIVE_TRAINING.title}>
                <ImmersiveTraining />
              </TitledRoute>
            }
          />
          <Route
            path={RoutePaths.IMMERSIVE_COLLABORATION.path}
            element={
              <TitledRoute title={RoutePaths.IMMERSIVE_COLLABORATION.title}>
                <ImmersiveCollaboration />
              </TitledRoute>
            }
          />
          <Route
            path={RoutePaths.CLOYD_IXR.path}
            element={
              <TitledRoute title={RoutePaths.CLOYD_IXR.title}>
                <CloydIxr />
              </TitledRoute>
            }
          />
          <Route
            path={RoutePaths.IXR_HUBS.path}
            element={
              <TitledRoute title={RoutePaths.IXR_HUBS.title}>
                <IxrHubs />
              </TitledRoute>
            }
          />
          <Route
            path={RoutePaths.PRIVACY_POLICY.path}
            element={
              <TitledRoute title={RoutePaths.PRIVACY_POLICY.title}>
                <PrivacyPolicy />
              </TitledRoute>
            }
          />
          <Route
            path={RoutePaths.TERMS_AND_CONDITIONS.path}
            element={
              <TitledRoute title={RoutePaths.TERMS_AND_CONDITIONS.title}>
                <TermAndConditions />
              </TitledRoute>
            }
          />
          <Route
            path={RoutePaths.FAQ.path}
            element={
              <TitledRoute title={RoutePaths.FAQ.title}>
                <Faq />
              </TitledRoute>
            }
          />
          <Route
            path={RoutePaths.CAREERS.path}
            element={
              <TitledRoute title={RoutePaths.CAREERS.title}>
                <Careers />
              </TitledRoute>
            }
          />
          <Route
            path={RoutePaths.DOWNLOAD.path}
            element={
              <TitledRoute title={RoutePaths.DOWNLOAD.title}>
                <Download />
              </TitledRoute>
            }
          />
          <Route
            path={RoutePaths.COMING_SOON.path}
            element={
              <TitledRoute title={RoutePaths.COMING_SOON.title}>
                <ComingSoon />
              </TitledRoute>
            }
          />
          <Route
            path="*"
            element={<Navigate to={RoutePaths.BASE_PATH.path} replace={true} />}
          />
        </Route>
      </Routes>
    </CustomRouter>
  );
};
