import styled from "styled-components";

import backgroundS1L from "../../assets/images/bg-s1-l.png";
import backgroundS1R from "../../assets/images/bg-s1-r.png";
import backgroundS1T1 from "../../assets/images/bg-s1-t1.png";
import backgroundS1T2 from "../../assets/images/bg-s1-t2.png";

import backgroundS3L from "../../assets/images/bg-s3-l.png";
import backgroundS3R from "../../assets/images/bg-s3-r.png";

import backgroundS5L from "../../assets/images/bg-s5-l.png";

import backgroundS8L from "../../assets/images/bg-s3-r.png";
import backgroundS8R from "../../assets/images/bg-s3-l.png";

export const MainBackground = () => {
  return (
    <BackgroundWrapper>
      <BackgroundS1Left src={backgroundS1L} alt="background" />
      <BackgroundS1Right src={backgroundS1R} alt="background" />
      <BackgroundS1Top1 src={backgroundS1T1} alt="background" />
      <BackgroundS1Top2 src={backgroundS1T2} alt="background" />

      <BackgroundS3Left src={backgroundS3L} alt="background" />
      <BackgroundS3Right src={backgroundS3R} alt="background" />
      <BackgroundS5Left src={backgroundS5L} alt="background" />

      <BackgroundS7Left src={backgroundS8L} alt="background" />
      <BackgroundS7Right src={backgroundS8R} alt="background" />
    </BackgroundWrapper>
  );
};

const BackgroundWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const BackgroundImage = styled.img`
  position: absolute;
  overflow: visible;
`;

const BackgroundS1Left = styled(BackgroundImage)`
  top: -379px;
  left: -1130px;
`;

const BackgroundS1Right = styled(BackgroundImage)`
  top: -930px;
  left: 300px;
`;

const BackgroundS1Top1 = styled(BackgroundImage)`
  top: -87%;
  left: -54%;
`;

const BackgroundS1Top2 = styled(BackgroundImage)`
  top: -75%;
  right: -60%;
`;

const BackgroundS3Left = styled(BackgroundImage)`
  top: 1690px;
  left: -100%;
`;

const BackgroundS3Right = styled(BackgroundImage)`
  top: 2780px;
  right: -80%;
  opacity: 0.6;
`;

const BackgroundS5Left = styled(BackgroundImage)`
  top: 4090px;
  left: -57%;
`;

const BackgroundS7Left = styled(BackgroundImage)`
  transform: scale(-1);
  bottom: -100px;
  left: -1680px;
  opacity: 0.6;
`;

const BackgroundS7Right = styled(BackgroundImage)`
  transform: scale(-1);
  bottom: -110px;
  right: -2200px;
`;
