import { useEffect, useState } from "react";

export const useWindowLoading = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const onPageLoad = () => {
      setIsLoading(false);
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);

      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return isLoading;
};
