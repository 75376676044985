import styled from "styled-components";
import { device } from "../../../../../assets/themes/breakpoints";
import { InformationBlock } from "../../../../../ui/InformationBlock";
import { INFORMATION_BLOCK_DATA } from "../constants/card-data";

export const RightSide = () => {
  return (
    <Wrapper>
      {INFORMATION_BLOCK_DATA.map((data, index) => (
        <InformationBlock
          banImageRotateOnMobile
          key={index}
          {...data}
          isSecondVariant={index % 2 !== 0}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 270px;
  z-index: 2;
  padding-bottom: 400px;

  @media ${device.mobileL} {
    row-gap: 60px;
  }

  @media ${device.laptop} {
    padding-bottom: 0;
  }
`;
