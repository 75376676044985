import * as React from "react";
import { SVGProps } from "react";
const SvgLinkedInIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.088 13.797H.228V4.586h2.86v9.211ZM1.657 3.33A1.67 1.67 0 0 1 0 1.657a1.657 1.657 0 0 1 3.313 0 1.67 1.67 0 0 1-1.656 1.672Zm12.137 10.468H10.94V9.313c0-1.069-.021-2.44-1.487-2.44-1.487 0-1.715 1.162-1.715 2.363v4.561H4.88V4.586h2.744v1.256h.04c.382-.724 1.315-1.487 2.706-1.487 2.895 0 3.428 1.906 3.428 4.382v5.06h-.004Z"
      fill="#fff"
    />
  </svg>
);
export default SvgLinkedInIcon;
