import { useEffect, useRef } from "react";
import styled from "styled-components";

import gsap from "gsap";
import { device } from "../../assets/themes/breakpoints";

interface InfoCardProps {
  highlightTitle?: string;
  title: string;
  text?: string;
  iconSrc: string;
}

export const InfoCard = ({
  highlightTitle,
  title,
  text,
  iconSrc,
}: InfoCardProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const iconWrapperRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (!wrapperRef.current) return;

    const element = wrapperRef.current;

    gsap.fromTo(
      element,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.3,
        scrollTrigger: {
          trigger: element,
          start: "top 80%",
        },
      }
    );

    gsap.fromTo(
      iconWrapperRef.current,
      {
        opacity: 0,
        y: -80,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.3,
        scrollTrigger: {
          trigger: iconWrapperRef.current,
          start: "top 80%",
        },
      }
    );

    gsap.fromTo(
      titleRef.current,
      {
        opacity: 0,
        y: -80,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.3,
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top 80%",
        },
      }
    );

    return () => {
      gsap.killTweensOf(element);
    };
  }, [wrapperRef]);
  return (
    <Wrapper ref={wrapperRef}>
      <IconWrapper ref={iconWrapperRef}>
        <Image src={iconSrc} alt="card icon" />
      </IconWrapper>
      <Title ref={titleRef}>
        {highlightTitle && <HighlightTitle>{highlightTitle}</HighlightTitle>}{" "}
        {title}
      </Title>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  user-select: none;
  background: rgba(0, 0, 0, 0.04);

  border-radius: 30px;
  padding: 40px 40px 31px 40px;
  transition: all 0.3s;

  @media ${device.mobileL} {
    padding: 31px 34px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76px;
  height: 76px;

  background: rgba(0, 0, 0, 0.07);
  border-radius: 16px;

  @media ${device.mobileL} {
    width: 58.78px;
    height: 58.78px;
  }
`;

const Title = styled.h3`
  margin-top: 34px;
  ${({ theme }) => theme.typography.paragraph_2_regular};

  @media ${device.tablet} {
    margin-top: 27px;
    ${({ theme }) => theme.typography.paragraph_2_regular_tablet};
  }

  @media ${device.mobileL} {
    margin-top: 17px;
    ${({ theme }) => theme.typography.paragraph_2_regular_mobile};
  }
`;

const HighlightTitle = styled.span`
  opacity: 0.5;
`;

const Text = styled.p`
  margin-top: 27px;

  ${({ theme }) => theme.typography.paragraph_3_regular};

  @media ${device.mobileL} {
    margin-top: 17px;
    ${({ theme }) => theme.typography.paragraph_3_regular_mobile};
  }

  line-height: 26px;
`;

const Image = styled.img`
  @media ${device.mobileL} {
    width: 73px;
    height: 73px;
  }
`;
