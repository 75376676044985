import styled from "styled-components";
import mobileIcon from "../../../../../assets/images/s3-mobile.png";
import moneyIcon from "../../../../../assets/images/s3-money.png";
import peopleIcon from "../../../../../assets/images/s3-people.png";
import lockCloseIcon from "../../../../../assets/images/s3-lock-close.png";
import skyrocketIcon from "../../../../../assets/images/s3-skyrocket.png";
import storageIcon from "../../../../../assets/images/s3-storage.png";
import { device } from "../../../../../assets/themes/breakpoints";
import { useEffect, useRef } from "react";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export type iconTypes =
  | "mobile"
  | "money"
  | "people"
  | "lockClose"
  | "skyrocket"
  | "storage";

const renderIcon = (icon: iconTypes) => {
  switch (icon) {
    case "mobile":
      return mobileIcon;
    case "money":
      return moneyIcon;
    case "people":
      return peopleIcon;
    case "lockClose":
      return lockCloseIcon;
    case "skyrocket":
      return skyrocketIcon;
    case "storage":
      return storageIcon;
  }
};

interface CardProps {
  icon: iconTypes;
  title1: string;
  highlightText: string;
  title2: string;
  text: string;
  cardIndex: number;
  cardWrapperRef: React.RefObject<HTMLDivElement>;
}

export const Card = ({
  icon,
  title1,
  highlightText,
  title2,
  text,
  cardIndex,
  cardWrapperRef,
}: CardProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const iconWrapperRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (!wrapperRef.current) return;

    const element = wrapperRef.current;

    ScrollTrigger.refresh();

    gsap.fromTo(
      element,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.3,
        scrollTrigger: {
          trigger: element,
          start: "top 80%",
        },
      }
    );

    gsap.fromTo(
      iconWrapperRef.current,
      {
        opacity: 0,
        y: -80,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.3,
        scrollTrigger: {
          trigger: iconWrapperRef.current,
          start: "top 80%",
        },
      }
    );

    gsap.fromTo(
      titleRef.current,
      {
        opacity: 0,
        y: -80,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.3,
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top 80%",
        },
      }
    );

    gsap.fromTo(
      textRef.current,
      {
        opacity: 0,
        y: -80,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.3,
        scrollTrigger: {
          trigger: textRef.current,
          start: "top 80%",
        },
      }
    );

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, [wrapperRef]);

  return (
    <Wrapper ref={wrapperRef}>
      <IconWrapper ref={iconWrapperRef}>
        <Image src={renderIcon(icon)} alt="icon" />
      </IconWrapper>
      <Title ref={titleRef}>
        {title1} <Highlight>{highlightText}</Highlight> {title2}
      </Title>
      <Text ref={textRef}>{text}</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  user-select: none;
  background: rgba(0, 0, 0, 0.04);

  border-radius: 30px;
  padding: 40px 40px 31px 40px;
  transition: all 0.3s;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76px;
  height: 76px;

  background: rgba(0, 0, 0, 0.07);
  border-radius: 16px;

  @media ${device.mobileL} {
    width: 58.78px;
    height: 58.78px;
  }
`;

const Title = styled.h3`
  margin-top: 34px;
  font-weight: 700;
  font-size: 31px;
  line-height: 35px;

  letter-spacing: -0.04em;

  color: #1d2d44;

  @media ${device.mobileL} {
    margin-top: 17px;
    font-size: 24px;
    line-height: 29px;
  }
`;

const Highlight = styled.span`
  opacity: 0.5;
`;

const Text = styled.p`
  margin-top: 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #1d2d44;

  opacity: 0.7;

  @media ${device.mobileL} {
    margin-top: 12px;
    font-size: 14px;
    line-height: 21px;
  }
`;

const Image = styled.img`
  @media ${device.mobileL} {
    width: 73px;
    height: 73px;
  }
`;
