import * as React from "react";
import { SVGProps } from "react";
const SvgAppleLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={28}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.505 23.477c-.75 1.666-1.113 2.41-2.08 3.883-1.348 2.054-3.252 4.614-5.613 4.637-2.096.02-2.632-1.365-5.479-1.349-2.844.016-3.438 1.371-5.534 1.352-2.358-.023-4.164-2.335-5.512-4.389-3.775-5.747-4.17-12.493-1.842-16.078 1.656-2.547 4.268-4.04 6.723-4.04 2.502 0 4.072 1.372 6.14 1.372 2.004 0 3.225-1.371 6.115-1.371 2.184 0 4.5 1.188 6.149 3.245-5.405 2.959-4.526 10.675.933 12.738ZM18.23 5.195C19.28 3.847 20.077 1.943 19.786 0c-1.714.118-3.722 1.208-4.894 2.632-1.065 1.29-1.94 3.206-1.6 5.068 1.877.058 3.813-1.062 4.937-2.505Z"
      fill="#fff"
    />
  </svg>
);
export default SvgAppleLogo;
