import styled from "styled-components";
import { device } from "../../../../assets/themes/breakpoints";
import { SectionLayout } from "../../../../layouts/SectionLayout";
import { InfoCard } from "../../../../ui";
import { CARD_DATA } from "./constants/card-data";

export const FourthSection = () => {
  return (
    <SectionLayout
      topMargins={{
        desktop: 180,
        tablet: 120,
        mobile: 63,
      }}
    >
      <Wrapper>
        <Title>
          There are many tools and techniques that can be used to create
          immersive collaboration in your team.
        </Title>
        <Text>These techniques include:</Text>
        <CardsWrapper>
          {CARD_DATA.map((card, index) => (
            <InfoCard key={index} {...card} />
          ))}
        </CardsWrapper>
      </Wrapper>
    </SectionLayout>
  );
};

const Wrapper = styled.div``;

const Title = styled.h2`
  max-width: 1074px;
  ${({ theme }) => theme.typography.title_2_bold};

  @media ${device.tablet} {
    ${({ theme }) => theme.typography.title_2_bold_tablet};
  }

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.title_2_bold_mobile};
  }
`;

const Text = styled.p`
  margin-top: 28px;

  ${({ theme }) => theme.typography.paragraph_1_regular};

  @media ${device.mobileL} {
    margin-top: 15px;
    ${({ theme }) => theme.typography.paragraph_1_regular_mobile};
  }
`;

const CardsWrapper = styled.div`
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;

  @media ${device.laptopL} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }

  @media ${device.mobileL} {
    margin-top: 43px;
    grid-gap: 16px;
  }
`;
