import * as React from "react";
import { SVGProps } from "react";
const SvgScrollArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={12}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 13V1m0 0L1 5.857M6 1l5 4.857"
      stroke="#1D2D44"
      strokeLinecap="round"
    />
  </svg>
);
export default SvgScrollArrowUp;
