import { SetStateAction, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ErrorInputIcon } from "../../../../../assets/icons";
import { device } from "../../../../../assets/themes/breakpoints";
import { Spinner } from "../../../../../ui";

const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

export const Form = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");

  const [loading, setLoading] = useState(false);

  const relative = window.location.host;
  const query = new URL("submit-feedback/", `https://${relative}`).href;

  const validate = () => {
    let valid = true;
    if (name === "") {
      setNameError("Name is required");
      valid = false;
    } else {
      setNameError("");
    }

    if (email === "") {
      setEmailError("Email is required");
      valid = false;
    } else if (!isValidEmail.test(email)) {
      setEmailError("Email is invalid");
      valid = false;
    } else {
      setEmailError("");
    }

    if (message === "") {
      setMessageError("Message is required");
      valid = false;
    } else {
      setMessageError("");
    }

    return valid;
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    validate();

    if (!validate()) {
      return;
    }

    try {
      setLoading(true);
      const data = {
        title: "Contact Us",
        name,
        email,
        text: message,
      };

      await fetch(query, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      setName("");
      setEmail("");
      setMessage("");

      toast.success("Form sent successfully");
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };
  return (
    <Wrapper onSubmit={onSubmit}>
      <InputWrapper>
        <Input
          $hasError={Boolean(nameError)}
          type="text"
          placeholder="Your Name"
          value={name}
          onChange={(e: { target: { value: SetStateAction<string> } }) => {
            setName(e.target.value);
            Boolean(nameError) && validate();
          }}
        />
        {Boolean(nameError.length) && (
          <Error>
            <ErrorInputIcon />
            {nameError}
          </Error>
        )}
      </InputWrapper>

      <InputWrapper>
        <Input
          $hasError={Boolean(emailError)}
          type="email"
          placeholder="Your Email"
          value={email}
          rows={17}
          onChange={(e: { target: { value: SetStateAction<string> } }) => {
            setEmail(e.target.value);
            Boolean(emailError) && validate();
          }}
        />
        {Boolean(emailError.length) && (
          <Error>
            <ErrorInputIcon />
            {emailError}
          </Error>
        )}
      </InputWrapper>

      <InputWrapper>
        <TextArea
          $hasError={Boolean(messageError)}
          placeholder="Tell us how we can help"
          value={message}
          onChange={(e: { target: { value: SetStateAction<string> } }) => {
            setMessage(e.target.value);
            Boolean(messageError) && validate();
          }}
        />
        {Boolean(messageError.length) && (
          <Error>
            <ErrorInputIcon />
            {messageError}
          </Error>
        )}
      </InputWrapper>

      <Submit
        $isLoading={loading}
        type="submit"
        value="Submit"
        disabled={Boolean(nameError || emailError || messageError || loading)}
      >
        {loading && <Spinner scale={0.6} />}
        Submit
      </Submit>
    </Wrapper>
  );
};

const Wrapper = styled.form`
  margin-top: 41px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 16px;
  width: 100%;
`;

const Input = styled.input<{ $hasError: boolean }>`
  padding: 20px 28px 19px 20px;
  background: #f9fbfb;
  border: 1px solid ${({ $hasError }) => ($hasError ? "#ff0000" : "#e1e2e2")};
  border-radius: 16px;

  ${({ theme }) => theme.typography.paragraph_3_regular};

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.paragraph_3_regular_mobile};
  }
  opacity: 1;
`;

const TextArea = styled.textarea<{ $hasError: boolean }>`
  padding: 20px 28px 19px 20px;
  background: #f9fbfb;
  border: 1px solid ${({ $hasError }) => ($hasError ? "#ff0000" : "#e1e2e2")};
  border-radius: 16px;

  ${({ theme }) => theme.typography.paragraph_3_regular};

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.paragraph_3_regular_mobile};

    opacity: 1;
    resize: none;
    min-height: 201px;
  }
  opacity: 1;
  resize: none;
  min-height: 201px;
`;

const Submit = styled.button<{ $isLoading: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  background: #1d2d44;
  border-radius: 16px;
  padding: 18px 18px 19px 18px;

  ${({ theme }) => theme.typography.paragraph_3_regular};

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.paragraph_3_regular_mobile};

    color: ${({ $isLoading }) => ($isLoading ? "transparent" : "white")};
    opacity: 1;
    border: none;
    cursor: pointer;
  }

  color: ${({ $isLoading }) => ($isLoading ? "transparent" : "white")};
  opacity: 1;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Error = styled.p`
  display: flex;
  padding: 6px;
  gap: 7px;
  background: #fae6e5;
  border-radius: 8px;
  ${({ theme }) => theme.typography.paragraph_3_regular};
  color: #df564d;
  opacity: 1;

  @media ${device.mobileL} {
    ${({ theme }) => theme.typography.paragraph_3_regular_mobile};
  }
  font-size: 12px;
  font-weight: 400;
`;
